import { PayloadAction } from '@reduxjs/toolkit';
import { IDamage } from 'interfaces/damage';
import { PageResult } from 'interfaces/pageResult';
import { getAllDamages, getDamage, updateDamage } from 'redux/action/damage';
import { StateData } from '../../interfaces/stateData';
import createAsyncReducer from '../createAsyncReducer';
import { createAsyncSlice } from '../createAsyncSlice';
import { RootState } from '../rootState';

export const damageSlice = createAsyncSlice<StateData<IDamage>>({
  name: 'damage',
  initialData: {
    list: [],
    totalCount: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    createAsyncReducer(
      builder,
      getAllDamages,
      (state, { payload }: PayloadAction<PageResult<IDamage>>) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.list = payload.content;
      }
    );

    createAsyncReducer(
      builder,
      getDamage,
      (state, { payload }: PayloadAction<IDamage>) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.entry = payload;
      }
    );

    createAsyncReducer(
      builder,
      updateDamage,
      (state, { payload }: PayloadAction<IDamage>) => {
        const itemIndex = state.data.list.findIndex((item: IDamage) => item.uuid === payload.uuid);
        state.isFetching = false;
        state.isSuccess = true;
        if (itemIndex >= 0) {
          state.data.list[itemIndex].status = payload.status;
          state.data.list[itemIndex].line = payload.line;
          state.data.list[itemIndex].lineSystem = payload.lineSystem;
          state.data.list[itemIndex].notice = payload.notice;
          state.data.list[itemIndex].clientComment = payload.clientComment;
        }
      }
    );
  }
});

export const damageSelector = (state: RootState) => state.damageSlice;
export const damageDataSelector = (state: RootState) => state.damageSlice.data.entry;
export const damagesDataSelector = (state: RootState) => state.damageSlice.data.list;

export const { clearState } = damageSlice.actions;
