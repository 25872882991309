export const LOGIN_URL = 'auth/login';
export const REFRESH_TOKEN_URL = 'auth/refreshToken';
export const RESET_PASSWORD_URL = 'auth/resetPassword';
export const GET_ORDER_NOT_OPENED_URL = 'order/notOpened';
export const GET_ALL_OPERATOR_REGIONAL_OFFICE_URL = 'operatorRegionalOffice/all';
export const GET_ORDER_YEARS_URL = 'order/years';
export const GET_ALL_VOLTAGE_RANGES_URL = 'voltageRange/all';
export const GET_ALL_DAMAGE_DEFINITIONS_URL = 'damageDefinition/all';
export const GET_ALL_FLY_HISTORY_URL = 'flyHistory/all';
export const GET_ALL_MASTS_URL = 'mast/all';

export const GET_ALL_LINES_URL = 'line/all';
export const GET_ALL_LINE_SYSTEMS_URL = 'line-system/all';
export const GET_USER_URL = 'user/me';
export const GET_ALL_EXPORT_URL = 'export/all';
export const EXPORT_URL = 'export/';
