import { Container, Grid, Link, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '720px'
  },
  paragraph: {
    width: '100%'
  }
}));

const Imprint = () => {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Grid container>
        <Typography color="textPrimary" className={classes.paragraph} variant="h3">
          Impressum
        </Typography>
        <Typography color="textPrimary" className={classes.paragraph} variant="h5">
          INHALTLICH VERANTWORTLICHER GEMÄSS §5 TMG
        </Typography>
        <Typography color="textPrimary" className={classes.paragraph} variant="body1">
          <b>Inhaber:</b> SKY HELI GmbH
        </Typography>
        <Typography color="textPrimary" className={classes.paragraph} variant="body1">
          <b>Geschäftsführer:</b> Knut Wagner
        </Typography>
        <Typography color="textPrimary" className={classes.paragraph} variant="body1">
          <b>Geschäftsführer:</b> Paul Wagner
        </Typography>
        <Typography color="textPrimary" className={classes.paragraph} variant="body1">
          <b>Anschrift und Kontakt:</b>
        </Typography>
        <Typography color="textPrimary" className={classes.paragraph} variant="body1">
          Flugplatz Haus 3
        </Typography>
        <Typography color="textPrimary" className={classes.paragraph} variant="body1">
          D-14959 Trebbin OT Schönhagen
        </Typography>
        <Typography color="textPrimary" className={classes.paragraph} variant="body1">
          Telefon: +49 (33731) 70299-0
        </Typography>
        <Typography color="textPrimary" className={classes.paragraph} variant="body1">
          Telefax: +49 (33731) 70299-20
        </Typography>
        <Typography color="textPrimary" className={classes.paragraph} variant="body1">
          Internet: <Link href="https://www.skyheli.de" target="_blank" color="textPrimary">www.skyheli.de</Link>
        </Typography>
        <Typography color="textPrimary" className={classes.paragraph} variant="body1">
          E-Mail: <Link href="mailto:info@skyheli.de" target="_blank" color="textPrimary">info@skyheli.de</Link>
        </Typography>
        <Typography color="textPrimary" className={classes.paragraph} variant="body1">
          <b>UstN:</b> DE 050/118/02623
        </Typography>
        <Typography color="textPrimary" className={classes.paragraph} variant="body1">
          <b>Handelsregister Potsdam:</b> HRB 21250 P
        </Typography>
        <Typography color="textPrimary" className={classes.paragraph} variant="body1">
          <b>Luftfahrtunternehmerlizenz:</b> D-357 EG
        </Typography>
        <Typography color="textPrimary" className={classes.paragraph} variant="body1">
          <b>Ausbildungserlaubnis:</b> DE.ATO.042
          <br />
          <br />
        </Typography>
        <Typography color="textPrimary" className={classes.paragraph} variant="h3">
          Haftung
        </Typography>
        <Typography color="textPrimary" className={classes.paragraph} variant="h5">
          HAFTUNGSAUSSCHLUSS
        </Typography>
        <Typography color="textPrimary" className={classes.paragraph} variant="body1">
          SKY HELI GmbH und die meisten Partnerdomains verweisen mit Links zu anderen Seiten im
          Internet. Für diese Fälle gilt: Die Betreiber von SKY HELI GmbH erklären ausdrücklich,
          dass sie keinerlei Einfluss auf die Gestaltung und die Inhalte der gelinkten und
          eingespielten Seiten haben. Aus diesem Grunde distanzieren sich die Betreiber von
          Domainname.de und deren Partnerseiten ausdrücklich von allen Inhalten aller gelinkten
          Seiten auf der Website und machen sich diese Inhalte nicht zu Eigen. Diese Erklärung gilt
          für alle Seiten, die zu denen Links führen.
          <br />
          <br />
        </Typography>
        <Typography color="textPrimary" className={classes.paragraph} variant="h3">
          Urheberrechte
        </Typography>
        <Typography color="textPrimary" className={classes.paragraph} variant="h5">
          URHEBERRECHTSHINWEIS
        </Typography>
        <Typography color="textPrimary" className={classes.paragraph} variant="body1">
          Alle Inhalte dieser Website, sowie Gestaltung und Layout unterliegen dem
          Urheberrechtsschutz. Bitte beachten Sie das die Europäischer SKY HELI Marken- und
          Patentinhaber ist! Die Inhalte dieser Seite werden zum Abruf und Download zur Verfügung
          gestellt sofern keine abweichende Bestimmung erfolgt. Jede weitere Vervielfältigung,
          vollumfängliche oder ausschnittsweise Nutzung sowie Bearbeitung ist nicht erlaubt. Zitate
          können unter Angabe der Quelle: SKY HELI GmbH benutzt werden. Alle Rechte vorbehalten.
        </Typography>
      </Grid>
    </Container>
  );
};

export default Imprint;
