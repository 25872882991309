import { BORDER_RADIUS_PAPER } from '../constants';

const MuiPaper = {
  root: {
    padding: 50
  },
  rounded: {
    borderRadius: BORDER_RADIUS_PAPER
  }
};

export default MuiPaper;
