
import { LOGIN_URL, REFRESH_TOKEN_URL, RESET_PASSWORD_URL, GET_USER_URL } from 'constants/apiEndpoints';
import { ForgotPasswordFormData } from 'interfaces/user';
import httpClient from 'services/apiClient';

const AuthService = {
  login: async (username: string, password: string) => {
    try {
      return await httpClient.post(LOGIN_URL, JSON.stringify({ username, password }));
    } catch (error: any) {
      return error.response;
    }
  },
  getUser: async () => {
    try {
      return await httpClient.get(GET_USER_URL);
    } catch (error: any) {
      return error.response;
    }
  },
  refreshToken: async () => {
    try {
      return await httpClient.post(REFRESH_TOKEN_URL);
    } catch (error: any) {
      return error.response;
    }
  },
  resetPassword: async (payload: ForgotPasswordFormData) => {
    try {
      return await httpClient.post(RESET_PASSWORD_URL, JSON.stringify(payload));
    } catch (error: any) {
      return error.response;
    }
  },
};

export default AuthService;
