import 'leaflet';

import DateFnsUtils from '@date-io/dayjs';
import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Store } from '@reduxjs/toolkit';
import SplashScreen from 'components/SplashScreen';
import { flatten } from 'flat';
import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { isLoadingSelector } from 'redux/rootState';
import { setupInterceptor } from 'services/interceptor';

import Routes from './Routes';
import { getMessages } from './services/I18n';
import { theme } from './theme';


const locale = 'de';

interface AppProps {
  store: Store;
}

const App = ({ store }: AppProps) => {
  const [messages, setMessages] = useState<Record<string, string> | undefined>(undefined);
  const queryClient = new QueryClient();
  const history = useHistory();
  const isLoading = useSelector(isLoadingSelector);
  useEffect(() => {
    async function loadMessages() {
      setMessages(flatten(await getMessages(locale)));
    }
    loadMessages();
    setupInterceptor(store, history);
  }, [store, history]);

  if (!messages) {
    return <></>;
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <IntlProvider messages={messages} locale={locale} defaultLocale={locale}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <Router>
              <Routes />
              {isLoading && <SplashScreen />}
            </Router>
          </ThemeProvider>
        </QueryClientProvider>
      </IntlProvider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
