import { TypographyOptions } from '@material-ui/core/styles/createTypography';

export const typography: TypographyOptions = {
  h1: {
    fontSize: '2.5rem',
    fontWeight: 700
  },
  h2: {
    fontSize: '2.2rem',
    fontWeight: 500
  },
  h3: {
    fontSize: '2rem',
    fontWeight: 500
  },
  h4: {
    fontSize: '1.5rem',
    fontWeight: 400
  },
  h5: {
    fontSize: '1.2rem',
    fontWeight: 300
  },
  h6: {
    fontSize: '1rem',
    fontWeight: 400
  },
  subtitle1: {
    fontSize: '0.8rem',
    fontWeight: 'bold'
  },
  body1: {
    fontSize: '0.9rem',
    fontWeight: 'normal',
    lineHeight: 1.75
  },
  body2: {
    fontSize: '0.8rem'
  },
  fontFamily: 'Arial'
};
