import { GET_ALL_DAMAGE_DEFINITIONS_URL } from 'constants/apiEndpoints';
import httpClient from 'services/apiClient';

const DamageDefinitionService = {
  getAllDamageDefinitions: async () => {
    try {
      return await httpClient.get(GET_ALL_DAMAGE_DEFINITIONS_URL);
    } catch (error: any) {
      return error.response;
    }
  }
};

export default DamageDefinitionService;
