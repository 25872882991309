import { BORDER_RADIUS_BUTTON } from '../constants';
import { palette } from '../palette';

const MuiButton = {
  root: {
    fontSize: '0.8rem'
  },
  contained: {
    borderRadius: BORDER_RADIUS_BUTTON,
    padding: '10px 20px'
  },
  containedPrimary: {
    backgroundColor: '#4B90D4',
    border: '2px solid #4C90D4',
    '&:hover': {
      backgroundColor: '#00408C !important'
    }
  },
  containedSecondary: {
    color: palette.text?.secondary,
    border: '2px solid #4C90D4',
    '&$disabled': {
      color: 'rgba(0, 0, 0, 1) !important',
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.20)'
    }
  }
};

export default MuiButton;
