export const getMessages = (locale: string) => {
  let langBundlePromise: Promise<any> | undefined;
  switch (locale) {
    case 'en':
      langBundlePromise = import('../i18n/en.json');
      break;
    case 'de':
      langBundlePromise = import('../i18n/de.json');
      break;
    default:
      break;
  }

  return langBundlePromise;
};
