import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import { HeadCell, ListHeader } from './ListHeader';
import { ListFooter } from './ListFooter';
import { ListContent } from './ListContent';
import { ListRowProps } from './ListRow';
import { useDispatch, useSelector } from 'react-redux';
import { OrderType, tableSelector, updateOrder, updateOrderBy, updatePage } from 'redux/slice/tableSlice';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    padding: '0 10px',
    overflow: 'revert'
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 1em'
  }
}));

interface ListViewProps {
  headCells: HeadCell[];
  rows: ListRowProps[];
  totalCount: number;
  rowsPerPage?: number;
  footerStartComponent?: ReactNode;
  footerEndComponent?: ReactNode;
  loading?: boolean;
  multiple?: boolean;
  selected?: string[];
  onChangePage?: (page: number, sort: string, sortDirection: OrderType) => void;
  onSelectRow: (key: string[]) => void;
}

export const ListView = (props: ListViewProps) => {
  const classes = useStyles();
  const {
    headCells,
    rows,
    footerStartComponent,
    footerEndComponent,
    loading,
    multiple,
    rowsPerPage = 10,
    totalCount,
    onChangePage,
    selected,
    onSelectRow
  } = props;

  const dispatch = useDispatch();
  const { order, orderBy, page } = useSelector(tableSelector);

  const handleRequestSort = (_: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    dispatch(updateOrder(isAsc ? 'desc' : 'asc'));
    dispatch(updateOrderBy(property));
    if (onChangePage) {
      onChangePage(page, property, isAsc ? 'desc' : 'asc');
    }
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    dispatch(updatePage(newPage));
    if (onChangePage) {
      onChangePage(newPage, orderBy, order);
    }
  };

  if (loading) {
    return <></>;
  }

  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table}>
        <ListHeader
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={headCells}
        />
        <ListContent onSelected={onSelectRow} multiple={multiple} rows={rows} selected={selected} />
      </Table>
      <ListFooter
        page={page}
        count={totalCount}
        rowsPerPage={rowsPerPage}
        onChangePage={handleChangePage}
        startComponent={footerStartComponent}
        endComponent={footerEndComponent}
      />
    </TableContainer>
  );
};
