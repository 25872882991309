import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Menu as MenuIcon,
  NotificationsOutlined as NotificationsOutlinedIcon
} from '@material-ui/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import { Box, Button, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { logoutUser, userDataSelector } from 'redux/slice/userSlice';
import SkyLogo from '../../assets/images/logo.png';
import { PATHS } from 'constants/paths';
import { getOrdersNotOpenedForNotification } from 'redux/action/order';
import { notificationTotalCountSelector } from 'redux/slice/notificationSlice';
import OrderBox from './OrderBox';

const useStyles = makeStyles({
  topBar: {
    marginBottom: '1px',
    width: '100%',
    boxShadow: ' 0 0 10px rgb(0 0 0 / 50%)',
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    background: '#fff',
    height: '75px',
    zIndex: 10
  },
  logo: {
    maxWidth: '90%'
  },
  bellNotification: {},
  logout: {
    textTransform: 'uppercase'
  }
});

interface TopBarProps {
  toggleDrawer: () => void;
}

export const TopBar = ({ toggleDrawer }: TopBarProps) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(userDataSelector);
  const totalCount = useSelector(notificationTotalCountSelector);
  const [isOpen, setOpen] = useState(false);

  const ref = useRef(null);

  const handleToggleDrawer = useCallback(() => {
    if (toggleDrawer) {
      toggleDrawer();
    }
  }, [toggleDrawer]);

  const handleClickLogout = () => {
    dispatch(logoutUser());
    history.push(PATHS.login);
  };

  useEffect(() => {
    dispatch(getOrdersNotOpenedForNotification({ page: 0, pageSize: 10 }));
  }, [dispatch]);

  return (
    <Grid container className={classes.topBar}>
      <Grid item xs={3}>
        {user && (
          <IconButton
            disabled={location && location.pathname === 'main_window'}
            onClick={handleToggleDrawer}
          >
            <MenuIcon />
          </IconButton>
        )}
      </Grid>
      <Grid item xs={6}>
        <Link to="/" className={classes.logo}>
          <img src={SkyLogo} alt="SKY HELI" />
        </Link>
      </Grid>

      <Grid item xs={3}>
        <Box display="flex" justifyContent="flex-end" alignItems="center" mr={5}>
          {user && (
            <>
              <Box display="flex" mr={2}>
                <Typography variant="body1">
                  {user.contact.firstName} {user.contact.lastName}
                </Typography>
              </Box>
              <IconButton
                className={classes.bellNotification}
                disabled={location && location.pathname === 'main_window'}
                onClick={() => setOpen(true)}
                ref={ref}
              >
                <Badge badgeContent={totalCount} color="secondary">
                  <NotificationsOutlinedIcon />
                </Badge>
              </IconButton>
              <Button color="primary" className={classes.logout} onClick={handleClickLogout}>
                <FormattedMessage id="topNav.logout" />
              </Button>
            </>
          )}
          {isOpen && <OrderBox ref={ref} handleClose={() => setOpen(false)} />}
        </Box>
      </Grid>
    </Grid>
  );
};
