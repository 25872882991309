import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import { IOrderQuery } from 'interfaces/order';
import OrderService from 'services/orderService';

export const getOrdersNotOpened = createAsyncThunk(
  'orders/getOrdersNotOpened',
  async (
    {
      page,
      pageSize,
      sort,
      sortDirection,
      voltageRanges,
      year,
      operatorRegionalOffice,
      query,
      inspectionDate,
      damageTypes,
      damagePriorities,
      damageDefinitions
    }: IOrderQuery,
    thunkAPI
  ) => {
    try {
      const { status, data } = await OrderService.getOrdersNotOpened(
        page,
        pageSize,
        sort,
        sortDirection,
        voltageRanges,
        year,
        operatorRegionalOffice?.uuid,
        query,
        inspectionDate,
        damageTypes,
        damagePriorities,
        damageDefinitions
      );
      if (status === StatusCodes.OK) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getOrdersNotOpenedForNotification = createAsyncThunk(
  'orders/getOrdersNotOpenedForNotification',
  async (
    {
      page,
      pageSize,
    }: IOrderQuery,
    thunkAPI
  ) => {
    try {
      const { status, data } = await OrderService.getOrdersNotOpened(
        page,
        pageSize,
      );
      if (status === StatusCodes.OK) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
