import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../rootState';

interface ModalState {
  modals: number[];
  params: any;
}

interface ModalPayload {
  id: number;
  params?: any;
}

export enum Modal {
  INFO,
  CONFIRM,
  FORGOT_PASSWORD,
  DAMAGE_DETAILS,
  DAMAGE_DOWNLOAD_PHOTO,
  DAMAGE_EDIT
}

const initialState: ModalState = {
  modals: [],
  params: null
};

export const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    showModal: (state, action: PayloadAction<ModalPayload>) => ({
      modals: [...state.modals, action.payload.id],
      params: {
        ...state.params,
        [action.payload.id]: action.payload.params
      }
    }),
    hideModal: (state, action: PayloadAction<ModalPayload>) => ({
      ...state,
      modals: state.modals.filter((modal) => modal !== action.payload.id)
    })
  }
});

export const modalSelector = (state: RootState) => state.modalSlice;
export const modalParamsSelector = (rootState: RootState) => rootState.modalSlice.params;

export const { showModal, hideModal } = modalSlice.actions;
