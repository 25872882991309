import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import { NavItem } from 'interfaces/nav';
import withAuth from 'services/withAuth';
import { useIntl } from 'react-intl';
import sections from '../../layouts/DefaultLayout/navConfig';
import StartButton from './StartButton';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '720px'
  }
}));

const Start = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  return (
    <Container className={classes.root}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
        p={5}
      >
        <Grid container>
          {sections.map((item: NavItem) => (
            <Grid item xs={6} key={item.href}>
              <StartButton
                title={formatMessage({ id: item.title })}
                subtitle={item.subtitle}
                href={item.subItems ? item.subItems[0].href : item.href}
                backgroundColor={item.bgColor}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default withAuth(Start);
