import { TableCell, TableRow, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import { FormattedMessage } from 'react-intl';

import { BACKGROUND_TABLE_ROW_EVEN } from '../../theme/constants';
import { ListRow, ListRowProps } from './ListRow';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& tr': {
      '& td': {
        marginBottom: theme.spacing(5)
      }
    },
    '& tr:nth-child(odd) td': {
      backgroundColor: theme.palette.secondary.main
    },
    '& tr:nth-child(even) td': {
      backgroundColor: BACKGROUND_TABLE_ROW_EVEN
    }
  },
  noRecords: {
    '& td': {
      border: 'none',
      backgroundColor: 'transparent !important'
    },
  }
}));

interface ListContentProps {
  onSelected: (key: string[]) => void;
  selected?: string[];
  multiple?: boolean;
  rows: ListRowProps[];
}

export const ListContent = ({ selected, onSelected, rows, multiple }: ListContentProps) => {
  const classes = useStyles();

  const handleClick = (key: string) => {
    if (multiple) {
      onSelected(
        selected?.includes(key) ? selected.filter((s) => s !== key) : [...(selected || []), key]
      );
    } else {
      onSelected(selected?.includes(key) ? [] : [key]);
    }
  };

  return (
    <TableBody className={classes.root}>
      {rows.map((row) => (
        <ListRow
          key={row.key}
          id={row.key}
          cells={row.cells}
          onClick={() => handleClick(row.key)}
          selected={selected?.includes(row.key)}
        />
      ))}
      {!rows.length && (
        <TableRow className={classes.noRecords}>
          <TableCell>
            <Typography variant="body1" color="textPrimary">
              <FormattedMessage id="noRecords" />
            </Typography>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};
