import { PATHS } from 'constants/paths';
import { useEffect } from 'react';
import { UserStateData } from 'interfaces/user';
import { useHistory } from 'react-router-dom';

interface AuthGuardProps {
  userData: UserStateData;
  WrappedComponent: () => JSX.Element;
}

const AuthGuard = ({ userData, WrappedComponent }: AuthGuardProps) => {
  const history = useHistory();

  useEffect(() => {
    if (!userData.user) {
      history.push(PATHS.login);
    }
  }, [userData, history])

  if (!userData.user) {
    return <></>;
  }

  return <WrappedComponent />;
};

export default AuthGuard;
