import { forwardRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  makeStyles,
  IconButton
} from '@material-ui/core';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { notificationsDataSelector } from 'redux/slice/notificationSlice';
import { IOrder } from 'interfaces/order';
import { PATHS } from 'constants/paths';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  popover: {
    width: 508,
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 8,
    backgroundColor: '#F2F2F2',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  },
  icon: {
    backgroundColor: '#ED6246',
    color: '#FFFFFF'
  },
  iconRead: {
    backgroundColor: '#FFFFFF',
    color: '#ED6246'
  },
  listItem: {
    backgroundColor: '#FFFFFF',
    '&:first-child': {
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10
    },
    '&:last-child': {
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10
    }
  },
  linkButton: {
    textTransform: 'uppercase',
    color: '#3F51B5',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
      textTransform: 'uppercase'
    }
  },
  uploadButton: {
    backgroundColor: '#FD6B4D',
    '&:hover': {
      backgroundColor: '#ED6246'
    }
  },
  title: {
    color: '#313133',
    '& > p': {
      fontFamily: 'Raleway, sans-serif',
      color: '#818181',
      fontSize: '0.8rem',
      textTransform: 'none'
    }
  },
  arrowForwardIcon: {
    color: '#3F51B5'
  },
  mailOutlineIcon: {
    height: 64,
    width: 64
  }
}));

interface OrderBoxProps {
  handleClose: () => void;
}

const OrderBox = ({ handleClose }: OrderBoxProps, ref: any) => {
  const classes: any = useStyles();
  const { formatMessage } = useIntl();

  const history = useHistory();
  const orders = useSelector(notificationsDataSelector);
  const handleNotificationClick = (order: IOrder) => {
    history.push(`${PATHS.advancedSearch}?lineName=${order.line.name}`);
    handleClose();
  };

  const handleSeeAllBtnClick = () => {
    handleClose();
  };

  const renderOrder = () =>
    orders.map((order: IOrder) => {
      return (
        <ListItem className={classes.listItem} divider key={order.uuid}>
          <ListItemText
            className={classes.title}
            primary={`${formatMessage({ id:"orderBox.newNotification"})} ${order.line.name}`}
            primaryTypographyProps={{ variant: 'subtitle2' }}
          />
          <IconButton onClick={() => handleNotificationClick(order)}>
            <ArrowForwardIcon className={classes.arrowForwardIcon} />
          </IconButton>
        </ListItem>
      );
    });

  return (
    <>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref?.current}
        onClose={handleClose}
        open
      >
        {orders.length === 0 ? (
          <Box p={2}>
            <Typography variant="h5" color="textSecondary">
              <FormattedMessage id="orderBox.noOrders" />
            </Typography>
          </Box>
        ) : (
          <>
            <List className={classes.list} disablePadding>
              {renderOrder()}
            </List>
            <Box p={1} display="flex" justifyContent="flex-end">
              <Button
                color="primary"
                size="medium"
                variant="contained"
                className={classes.button}
                onClick={handleSeeAllBtnClick}
              >
                <FormattedMessage id="orderBox.showAll" />
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default forwardRef<HTMLButtonElement, OrderBoxProps>(OrderBox);
