import MapIcon from 'components/MapIcon';
import { DamageType } from 'enum/damageType';
import { MarkerColor } from 'enum/markerColor';
import { MarkerType } from 'enum/markerType';
import { IDamage } from 'interfaces/damage';
import { IMast } from 'interfaces/mast';

export const mapMasts = (mast: IMast) => ({
  uuid: mast.uuid,
  name: mast.name,
  icon: MapIcon(MarkerType.Circle),
  position: [mast.latitude, mast.longitude] as [number, number]
});

export const mapDamages = (damage: IDamage) => {
  const color =
    damage.damageClass?.priority === 1
      ? MarkerColor.Red
      : damage.damageClass?.priority === 4
      ? MarkerColor.Green
      : MarkerColor.Yellow;

  const type = damage.damageDefinition?.type === DamageType.Mastarea ? MarkerType.Circle : MarkerType.Triangle;
  
  return {
    uuid: damage.uuid,
    name: damage.notice,
    icon: MapIcon(type, color),
    position: [damage.latitude, damage.longitude] as [number, number]
  };
};
