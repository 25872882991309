import StartLayout from 'layouts/StartLayout';
import AdvancedSearch from 'pages/AdvancedSearch';
import DataPrivacy from 'pages/DataPrivacy';
import Editing from 'pages/Editing';
import Export from 'pages/Export';
import Imprint from 'pages/Imprint';
import MainSelection from 'pages/MainSelection';
import { ComponentType, Fragment, Suspense } from 'react';
import { RouteConfig } from 'react-router-config';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import LoadingScreen from './components/LoadingScreen';
import Modals from './components/Modals';
import { PATHS } from './constants/paths';
import DefaultLayout from './layouts/DefaultLayout';
import Login from './pages/Login';
import Start from './pages/Start';

const routesConfig: RouteConfig[] = [
  {
    key: 0,
    path: PATHS.main,
    exact: true,
    component: Login,
    layout: DefaultLayout
  },
  {
    key: 1,
    path: PATHS.login,
    component: Login,
    layout: StartLayout
  },
  {
    key: 2,
    path: PATHS.start,
    component: Start,
    layout: DefaultLayout
  },
  {
    key: 3,
    path: PATHS.imprint,
    component: Imprint,
    layout: DefaultLayout
  },
  {
    key: 4,
    path: PATHS.dataPrivacy,
    component: DataPrivacy,
    layout: DefaultLayout
  },
  {
    key: 5,
    path: PATHS.mainSelection,
    component: MainSelection,
    layout: DefaultLayout
  },
  {
    key: 6,
    path: PATHS.advancedSearch,
    component: AdvancedSearch,
    layout: DefaultLayout
  },
  {
    key: 7,
    path: PATHS.editing,
    component: Editing,
    layout: DefaultLayout
  },
  {
    key: 8,
    path: PATHS.export,
    component: Export,
    layout: DefaultLayout
  }
];

const renderRoutes = (routes: RouteConfig[]) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <BrowserRouter>
        <Switch>
          {routes.map((route: RouteConfig) => {
            const Layout = route.layout || Fragment;
            const Component = route.component as ComponentType<any>;
            return (
              <Route
                key={route.key}
                path={route.path}
                exact={route.exact}
                render={(props) => (
                  <Layout>
                    <Component {...props} />
                  </Layout>
                )}
              />
            );
          })}
        </Switch>
        <Modals />
      </BrowserRouter>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
