import { applyMiddleware, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer } from './rootState';
import thunk from 'redux-thunk';

export const configureStore = () => {
  const persistedSlices: string[] = ['userSlice'];

  const persistConfig = {
    key: 'root',
    storage,
    whitelist: persistedSlices
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = composeWithDevTools(applyMiddleware(thunk, createLogger({ collapsed: true })))(
    createStore
  )(persistedReducer);

  const persistor = persistStore(store);

  return { store, persistor };
};
