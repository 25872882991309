import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import React, { FunctionComponent, useCallback } from 'react';
import { OrderType } from 'redux/slice/tableSlice';

export interface HeadCell {
  // disablePadding?: boolean;
  key: string;
  label: string;
  numeric?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    '& th:first-child': {
      paddingLeft: theme.spacing(5)
    },
    '& th:last-child': {
      paddingRight: theme.spacing(5)
    },
    '& th': {
      border: 'none',
      textTransform: 'uppercase'
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
    color: 'white'
  },
  sortLabel: {
    '& svg': {
      color: `${theme.palette.text.primary} !important`
    }
  }
}));

interface ListHeaderProps {
  headCells: HeadCell[];
  order: OrderType;
  orderBy: string;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  /** Only required for `select all` function */
  rowCount?: number;
  numSelected?: number;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ListHeader: FunctionComponent<ListHeaderProps> = (props: ListHeaderProps) => {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } =
    props;
  const classes = useStyles();

  const createSortHandler = useCallback(
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    },
    [onRequestSort]
  );

  return (
    <TableHead>
      <TableRow className={classes.row}>
        <TableCell />
        {numSelected !== undefined && rowCount !== undefined && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
        )}

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.key}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.key ? order : false}
          >
            {!headCell.disabled && (
              <TableSortLabel
                className={classes.sortLabel}
                active={orderBy === headCell.key}
                direction={orderBy === headCell.key ? order : 'asc'}
                onClick={createSortHandler(headCell.key)}
              >
                <Typography color="inherit" variant="subtitle1">
                  {headCell.label}
                </Typography>
                {orderBy === headCell.key ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
            {headCell.disabled && (
              <Typography color="inherit" variant="subtitle1">
                {headCell.label}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
