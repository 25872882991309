import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import Autocomplete from 'components/Autocomplete';
import { DamageField } from 'enum/damageField';
import { DamageStatus } from 'enum/damageStatus';
import { IUpdateDamageBody } from 'interfaces/damage';
import { ILine } from 'interfaces/line';
import { ILineSystem } from 'interfaces/lineSystem';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { updateDamage } from 'redux/action/damage';
import { linesDataSelector } from 'redux/slice/lineSlice';
import { lineSystemsDataSelector } from 'redux/slice/lineSystemSlice';
import { Modal, modalParamsSelector } from 'redux/slice/modalSlice';
import { isClientUserSelector } from 'redux/slice/userSlice';
import * as yup from 'yup';

import BaseModal, { SimpleModalProps } from './BaseModal';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    margin: '30px auto 0 auto',
    flexDirection: 'column',
    padding: '0 50px',
    [theme.breakpoints.down('sm')]: {
      padding: '0'
    }
  },
  button: {
    marginTop: '30px',
    marginBottom: '15px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  paperClassName: {
    background: '#112c65'
  }
}));

export const DamageEditModal = ({ open, onClose }: SimpleModalProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { damage, field } = useSelector(modalParamsSelector)[Modal.DAMAGE_EDIT];
  const isClientUser = useSelector(isClientUserSelector);
  const lineSystems = useSelector(lineSystemsDataSelector);
  const lines = useSelector(linesDataSelector);
  const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = yup.object().shape({
    lineId: yup.string().required(formatMessage({ id: 'validation.required' })),
    lineSystemId: yup.string().required(formatMessage({ id: 'validation.required' })),
    notice: yup.string().optional().nullable(),
    clientComment: yup.string().optional().nullable()
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<Partial<IUpdateDamageBody>>({
    resolver: yupResolver(validationSchema)
  });


  const onSubmit: SubmitHandler<Partial<IUpdateDamageBody>> = async (data) => {
    await dispatch(updateDamage({ ...data, uuid: damage.uuid }));
    enqueueSnackbar(formatMessage({ id: 'updateDamageSuccess' }), {
      variant: 'success'
    });
    onClose();
  };

  useEffect(() => {
    reset({
      lineId: damage.line.uuid,
      lineSystemId: damage.lineSystem.uuid,
      notice: damage.notice,
      clientComment: damage.clientComment
    });
  }, [reset, damage]);

  return (
    <BaseModal open={open} onClose={onClose} paperClassName={classes.paperClassName}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className={classes.root}>
          <Grid container spacing={2}>
            {!isClientUser && (
              <>
                {field === DamageField.Line && (
                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={damage.status !== DamageStatus.Open}
                      options={lines}
                      {...register('lineId')}
                      onChange={(_, value) => {
                        setValue('lineId', (value as ILine).uuid);
                      }}
                      getOptionLabel={(line: ILine) => line.name}
                      defaultValue={damage?.line}
                      getOptionSelected={(option: ILine, value: ILine) =>
                        option?.uuid === value.uuid
                      }
                      disableClearable
                      inputColor="secondary"
                      inputLabel=""
                      error={Boolean(errors.lineId)}
                      helperText={errors.lineId?.message}
                      placeholder={formatMessage({ id: 'updateDamage.line' })}
                    />
                  </Grid>
                )}
                {field === DamageField.LineSystem && (
                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={damage.status !== DamageStatus.Open}
                      options={lineSystems}
                      {...register('lineSystemId')}
                      getOptionLabel={(lineSystem: ILineSystem) => lineSystem.name}
                      defaultValue={damage?.lineSystem}
                      getOptionSelected={(option: ILineSystem, value: ILineSystem) =>
                        option?.uuid === value?.uuid
                      }
                      onChange={(_, value) => {
                        setValue('lineSystemId', (value as ILineSystem).uuid);
                      }}
                      disableClearable
                      inputColor="secondary"
                      inputLabel=""
                      error={Boolean(errors.lineSystemId)}
                      helperText={errors.lineSystemId?.message}
                      placeholder={formatMessage({ id: 'updateDamage.lineSystem' })}
                    />
                  </Grid>
                )}
                {field === DamageField.Notice && (
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      color="secondary"
                      {...register('notice')}
                      defaultValue={damage?.notice}
                      error={Boolean(errors.notice)}
                      helperText={errors.notice?.message}
                      placeholder={formatMessage({ id: 'updateDamage.description' })}
                    />
                  </Grid>
                )}
              </>
            )}
            {field === DamageField.ClientComment && (
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  color="secondary"
                  {...register('clientComment')}
                  defaultValue={damage?.clientComment}
                  error={Boolean(errors.clientComment)}
                  helperText={errors.clientComment?.message}
                  placeholder={formatMessage({ id: 'updateDamage.clientComment' })}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <Button
                color="secondary"
                size="medium"
                type="submit"
                fullWidth
                variant="contained"
                className={classes.button}
              >
                <FormattedMessage id="button.ok" />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="secondary"
                size="medium"
                fullWidth
                type="button"
                variant="contained"
                onClick={onClose}
                className={classes.button}
              >
                <FormattedMessage id="button.abort" />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </BaseModal>
  );
};

export default DamageEditModal;
