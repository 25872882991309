import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import clsx from 'clsx';
import CustomScrollbars from 'components/CustomScrollbars';
import { PATHS } from 'constants/paths';
import { DamageField } from 'enum/damageField';
import { DamageStatus } from 'enum/damageStatus';
import { DamageType } from 'enum/damageType';
import { IDamage } from 'interfaces/damage';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAllDamages, getDamage, updateDamage } from 'redux/action/damage';
import { createExport } from 'redux/action/export';
import { getAllLines } from 'redux/action/line';
import { getAllLineSystems } from 'redux/action/lineSystem';
import { getAllMasts } from 'redux/action/mast';
import { damagesDataSelector } from 'redux/slice/damageSlice';
import { Modal, showModal } from 'redux/slice/modalSlice';
import { isClientUserSelector } from 'redux/slice/userSlice';
import withAuth from 'services/withAuth';
import { useQuery } from 'utils/useQuery';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  container: {},
  table: {},
  head: {
    '& th': {
      textTransform: 'uppercase',
      fontWeight: 600
    }
  },
  body: {
    '& td': {
      padding: '15px 0'
    },
    '& td button': {
      display: 'inline-flex'
    },
    '& td .MuiSvgIcon-root': {
      display: 'block'
    }
  },
  damageClass: {
    padding: '0',
    minWidth: 'auto',
    backgroundColor: 'yellow',
    width: '30px',
    height: '30px',
    borderRadius: '30px',
    color: theme.palette.primary.main,
    textAlign: 'center',
    lineHeight: '30px'
  },
  damageClassRedBtn: {
    backgroundColor: 'red !important'
  },
  damageClassGreenBtn: {
    backgroundColor: 'green !important'
  },
  input: {
    '& .MuiOutlinedInput-root fieldset': {
      borderRadius: 0
    },
    paddingRight: theme.spacing(2)
  },
  crossedOut: {
    textDecoration: 'line-through'
  }
}));

const Editing = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const damages = useSelector(damagesDataSelector);

  const isClientUser = useSelector(isClientUserSelector);
  const { enqueueSnackbar } = useSnackbar();

  const handleClickDownloadPhotos = async (damageUuid: string) => {
    await dispatch(getDamage(damageUuid));
    dispatch(showModal({ id: Modal.DAMAGE_DOWNLOAD_PHOTO, params: { damageUuid } }));
  };

  const handleClickRequest = async (damage: IDamage) => {
    await dispatch(getAllMasts({ lines: [damage?.line?.uuid || ''] }));
    dispatch(
      showModal({
        id: Modal.DAMAGE_DETAILS,
        params: { damage }
      })
    );
  };

  const handleClickEdit = (damage: IDamage, field: string) => {
    dispatch(showModal({ id: Modal.DAMAGE_EDIT, params: { damage, field } }));
  };

  const handleClickBack = () => {
    history.push(PATHS.advancedSearch);
  };
  const handleClickExport = () => {
    dispatch(createExport({ damageIds: damages.map((damage: IDamage) => damage.uuid) }));
    enqueueSnackbar(formatMessage({ id: 'createExportSuccess' }), {
      variant: 'success'
    });
  };

  const handleClickChangeStatus = (uuid: string, status: DamageStatus) => {
    dispatch(updateDamage({ uuid, status }));
  };

  useEffect(() => {
    const lineId = query.get('lineId');
    const exportId = query.get('exportId');
    if (lineId) {
      dispatch(
        getAllDamages({
          lineIds: [lineId]
        })
      );
    } else if (exportId) {
      dispatch(
        getAllDamages({
          exportId
        })
      );
    } else {
      dispatch(getAllDamages({}));
    }
  }, [query, dispatch]);

  useEffect(() => {
    dispatch(getAllLines());
    dispatch(getAllLineSystems());
  }, [dispatch]);

  const renderRow = () =>
    damages.map((damage: IDamage) => (
      <TableRow key={damage.uuid} className={classes.body}>
        <TableCell padding="checkbox">
          {damage?.status === DamageStatus.Done ? (
            <DoneIcon
              cursor="pointer"
              onClick={() => handleClickChangeStatus(damage.uuid, DamageStatus.Rejected)}
            />
          ) : damage?.status === DamageStatus.Rejected ? (
            <CloseIcon
              cursor="pointer"
              onClick={() => handleClickChangeStatus(damage.uuid, DamageStatus.Open)}
            />
          ) : (
            <RadioButtonUncheckedIcon
              cursor="pointer"
              onClick={() => handleClickChangeStatus(damage.uuid, DamageStatus.Done)}
            />
          )}
        </TableCell>
        <TableCell
          padding="checkbox"
          className={clsx({
            [classes.crossedOut]: damage.status !== DamageStatus.Open
          })}
        >
          <Box display="flex" alignItems="center">
            <Box>{damage?.line?.name}</Box>
            {damage.status === DamageStatus.Open && !isClientUser && (
              <Box>
                <IconButton
                  color="secondary"
                  onClick={() => handleClickEdit(damage, DamageField.Line)}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </TableCell>
        <TableCell padding="checkbox">{damage?.mast1?.name}</TableCell>
        <TableCell padding="checkbox">
          {damage?.damageDefinition?.type === DamageType.Mastarea ? <DoneIcon /> : <CloseIcon />}
        </TableCell>
        <TableCell padding="checkbox">
          {damage?.damageDefinition?.type === DamageType.Span ? <DoneIcon /> : <CloseIcon />}
        </TableCell>
        <TableCell
          padding="checkbox"
          className={clsx({
            [classes.crossedOut]: damage.status !== DamageStatus.Open
          })}
        >
          <Box display="flex" alignItems="center">
            <Box>{damage?.lineSystem?.name}</Box>
            {damage.status === DamageStatus.Open && !isClientUser && (
              <Box>
                <IconButton
                  color="secondary"
                  onClick={() => handleClickEdit(damage, DamageField.LineSystem)}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </TableCell>
        <TableCell
          padding="checkbox"
          className={clsx({
            [classes.crossedOut]: damage.status !== DamageStatus.Open
          })}
        >
          <Box display="flex" alignItems="center">
            <Box>{damage?.notice}</Box>
            {damage.status === DamageStatus.Open && !isClientUser && (
              <Box>
                <IconButton
                  color="secondary"
                  onClick={() => handleClickEdit(damage, DamageField.Notice)}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </TableCell>
        <TableCell padding="checkbox">
          <IconButton color="secondary" onClick={() => handleClickDownloadPhotos(damage.uuid)}>
            <GetAppIcon />
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
          <Box
            className={clsx({
              [classes.damageClass]: true,
              [classes.damageClassRedBtn]: damage.damageClass?.priority === 1,
              [classes.damageClassGreenBtn]: damage.damageClass?.priority === 4
            })}
          >
            {damage.damageClass?.priority}
          </Box>
        </TableCell>
        <TableCell
          padding="checkbox"
          className={clsx({
            [classes.crossedOut]: damage.status !== DamageStatus.Open
          })}
        >
          <Box display="flex" alignItems="center">
            <Box>{damage?.clientComment}</Box>
            {damage.status === DamageStatus.Open && (
              <Box>
                <IconButton
                  color="secondary"
                  onClick={() => handleClickEdit(damage, DamageField.ClientComment)}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </TableCell>
        <TableCell padding="checkbox">
          {damage.status === DamageStatus.Open && isClientUser && (
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={() => handleClickRequest(damage)}
            >
              <FormattedMessage id="button.request" />
            </Button>
          )}
        </TableCell>
      </TableRow>
    ));

  return (
    <Container disableGutters maxWidth={false} className={classes.root}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        height="100%"
        width="100%"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomScrollbars height="calc(100vh - 290px)" color="white">
              <TableContainer className={classes.container}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.head}>
                      <TableCell padding="checkbox">
                        <FormattedMessage id="editing.readyState" />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <FormattedMessage id="editing.line" />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <FormattedMessage id="editing.mastName" />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <FormattedMessage id="editing.mast" />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <FormattedMessage id="editing.span" />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <FormattedMessage id="editing.system" />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <FormattedMessage id="editing.description" />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <FormattedMessage id="editing.photos" />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <FormattedMessage id="editing.damageClass" />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <FormattedMessage id="editing.comment" />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <FormattedMessage id="editing.fix" />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.body}>{renderRow()}</TableBody>
                </Table>
              </TableContainer>
            </CustomScrollbars>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={2}>
            <Button
              className="submit-btn"
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleClickBack}
            >
              <FormattedMessage id="button.return" />
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              className="submit-btn"
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleClickExport}
            >
              <FormattedMessage id="button.export" />
            </Button>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default withAuth(Editing);
