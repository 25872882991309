const MuiTableSortLabel = {
  root: {
    '&:hover': {
      color: 'white',
      opacity: 0.5
    }
  }
};

export default MuiTableSortLabel;
