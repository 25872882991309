
const MuiInputBase = {
  input: {
    '&::placeholder': {
      opacity: 0.75
    }
  },
};

export default MuiInputBase;
