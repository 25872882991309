import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import LineSystemService from 'services/lineSystemService';

export const getAllLineSystems = createAsyncThunk(
  'lineSystems/all',
  async (_, thunkAPI) => {
    try {
      const { status, data } = await LineSystemService.getAllLineSystems();
      if (status === StatusCodes.OK) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
