import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import { FlyHistoryQuery } from 'interfaces/flyHistory';
import FlyHistoryService from 'services/flyHistoryService';

export const getAllFlyHistories = createAsyncThunk(
  'flyHistory/getAll',
  async ({ year, month }: FlyHistoryQuery, thunkAPI) => {
    try {
      const { status, data } = await FlyHistoryService.getAllFlyHistories(year, month);
      if (status === StatusCodes.OK) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
