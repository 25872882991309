import { Box, Button, CircularProgress, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import airfields from 'assets/kml/airfields.kml';
import protectedAreas from 'assets/kml/protectedAreas.kml';
import clsx from 'clsx';
import Autocomplete from 'components/Autocomplete';
import CustomScrollbars from 'components/CustomScrollbars';
import MastPictogram from 'components/MastPictogram';
import PhotoGallery from 'components/PhotoGallery';
import { PATHS } from 'constants/paths';
import dayjs from 'dayjs';
import { DamageType } from 'enum/damageType';
import { KmlLayer } from 'enum/kmlLayer';
import { MarkerColor } from 'enum/markerColor';
import { IDamage } from 'interfaces/damage';
import { IOrder } from 'interfaces/order';
import Image from 'material-ui-image';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Marker, Popup } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getDamage, requestDamage } from 'redux/action/damage';
import { createExport } from 'redux/action/export';
import { damageDataSelector, damagesDataSelector } from 'redux/slice/damageSlice';
import { mastsDataSelector } from 'redux/slice/mastSlice';
import { hideModal, Modal, modalParamsSelector, showModal } from 'redux/slice/modalSlice';
import { theme } from 'theme';
import { mapDamages, mapMasts } from 'utils/mapMarker';

import Map from '../components/Map';
import BaseModal, { SimpleModalProps } from './BaseModal';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '30px auto 0 auto',
    padding: '0 25px',
    [theme.breakpoints.down('sm')]: {
      padding: '0'
    }
  },
  confirmText: {
    fontWeight: 500,
    fontSize: '12px',
    textAlign: 'center'
  },
  paperClassName: {
    background: '#112c65'
  },
  closeButtonClassName: {
    color: 'white'
  },
  map: {
    height: '75vh',
    position: 'relative'
  },
  animationResize: {
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  circular: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 1000
  },
  bold: {
    fontWeight: 'bold'
  },
  details: {
    textTransform: 'uppercase'
  },
  damageContainer: {
    position: 'relative',
    height: '100%',
    width: '100%'
  },
  lineDamage: {
    border: '1px solid white',
    borderRadius: '25px',
    width: '20px',
    position: 'absolute',
    height: '20px',
    top: '20%'
  }
}));

export const DamageDetailsModal = ({ open, onClose }: SimpleModalProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [photoIndex, setPhotoIndex] = useState(0);
  const [openGallery, setOpenGallery] = useState(false);

  const { damage, selectedRow } = useSelector(modalParamsSelector)[Modal.DAMAGE_DETAILS] || {};
  const [selectedDamage, setSelectedDamage] = useState<IDamage | null>(null);
  const [selectedLayer, setSelectedLayer] = useState<KmlLayer | null>(null);
  const [kmlLayer, setKmlLayer] = useState<XMLDocument | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const masts = useSelector(mastsDataSelector);
  const damages = useSelector(damagesDataSelector);
  const currentDamage = useSelector(damageDataSelector);
  const { formatMessage } = useIntl();

  const handleClickRequest = () => {
    dispatch(
      showModal({
        id: Modal.CONFIRM,
        params: {
          title: formatMessage({ id: 'damageDetails.requestConfirmTitle' }),
          message: formatMessage({ id: 'damageDetails.requestConfirmMessage' }),
          buttonYes: formatMessage({ id: 'button.yes' }),
          buttonNo: formatMessage({ id: 'button.no' }),
          onConfirm: () => {
            dispatch(requestDamage(damage.uuid));
            enqueueSnackbar(formatMessage({ id: 'damageDetails.requestSent' }), {
              variant: 'success'
            });
            dispatch(hideModal({ id: Modal.CONFIRM }));
            onClose();
          }
        }
      })
    );
  };

  const handleClickCancel = () => {
    onClose();
  };
  const handleClickPhoto = (index: number) => {
    setPhotoIndex(index);
    setOpenGallery(true);
  };
  const handleClickEdit = () => {
    history.push({
      pathname: PATHS.editing,
      search: 'lineId=' + damages?.[0].line?.uuid
    });
    onClose();
  };
  const handleClickExport = () => {
    dispatch(createExport({ damageIds: [damage.uuid] }));
    enqueueSnackbar(formatMessage({ id: 'createExportSuccess' }), {
      variant: 'success'
    });
  };
  const handleClickDamage = (uuid: string) => {
    if (damage) {
      return;
    }
    if (selectedDamage?.uuid === uuid) {
      setSelectedDamage(null);
    } else {
      setSelectedDamage(damages.find((damage: IDamage) => damage.uuid === uuid) || null);
      dispatch(getDamage(uuid));
    }
  };

  useEffect(() => {
    if (damage && damages) {
      setSelectedDamage(damages.find((d: IDamage) => d.uuid === damage.uuid) || null);
      dispatch(getDamage(damage.uuid));
    }
  }, [damage, dispatch, damages]);

  useEffect(() => {
    const fetchKMLs = async () => {
      if (selectedLayer === KmlLayer.Airfields) {
        setKmlLayer(null);
        setKmlLayer(await fetchKmlFile(airfields));
      }
      if (selectedLayer === KmlLayer.ProtectedAreas) {
        setKmlLayer(null);
        setKmlLayer(await fetchKmlFile(protectedAreas));
      }
      if (!selectedLayer) {
        setKmlLayer(null);
      }
      setIsLoading(false);
    };

    fetchKMLs();
  }, [selectedLayer]);

  const fetchKmlFile = async (input: RequestInfo) => {
    setIsLoading(true);
    const result = await fetch(input);
    if (result) {
      const parser = new DOMParser();
      return parser.parseFromString(await result?.text(), 'text/xml');
    }

    return null;
  };

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const renderImages = () => (
    <>
      {currentDamage?.medias?.map((mediaUuid: string, index: number) => (
        <Image
          key={mediaUuid}
          src={`${apiBaseUrl}media/${mediaUuid}`}
          color="none"
          onClick={() => handleClickPhoto(index)}
          style={{ cursor: 'pointer', marginBottom: theme.spacing(2) }}
        />
      ))}
    </>
  );

  const isDamageAvailable = useMemo(() => {
    return selectedRow?.some(
      (selectedOrder: IOrder) => selectedOrder.countMastarea || selectedOrder.countSpan
    );
  }, [selectedRow]);


  const damagePositionX = useMemo<number>(()=> {
    if (! currentDamage?.positionX) {
      return 0
    }
    return currentDamage.positionX
  }, [currentDamage?.positionX])

  const damagePositionY = useMemo<number>(()=> {
    if (! currentDamage?.positionY) {
      return 0
    }
    return currentDamage.positionY
  }, [currentDamage?.positionY])

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      width="95vw"
      paperClassName={classes.paperClassName}
      closeButtonClassName={classes.closeButtonClassName}
    >
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={2}>
          <Autocomplete
            id="kml"
            options={Object.values(KmlLayer)}
            onChange={(_, value) => setSelectedLayer(value as KmlLayer)}
            getOptionLabel={(value: KmlLayer) =>
              formatMessage({ id: `damageDetails.layers.${value}` })
            }
            value={selectedLayer}
            getOptionSelected={(option: KmlLayer, value: KmlLayer) => option === value}
            inputLabel={formatMessage({ id: 'damageDetails.kmlLayer' })}
            inputColor="secondary"
          />
        </Grid>
        {!damage && <Grid item xs={4}></Grid>}
        {damage && <Grid item xs={6}></Grid>}
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            fullWidth
            type="button"
            onClick={handleClickCancel}
          >
            <FormattedMessage id="button.return" />
          </Button>
        </Grid>
        {damage && (
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              fullWidth
              type="button"
              onClick={handleClickRequest}
            >
              <FormattedMessage id="button.request" />
            </Button>
          </Grid>
        )}
        {!damage && (
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              fullWidth
              disabled={!isDamageAvailable}
              type="button"
              onClick={handleClickEdit}
            >
              <FormattedMessage id="button.edit" />
            </Button>
          </Grid>
        )}
        {!damage && (
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              fullWidth
              disabled={!isDamageAvailable}
              type="button"
              onClick={handleClickExport}
            >
              <FormattedMessage id="button.export" />
            </Button>
          </Grid>
        )}
        {masts.length > 0 && (
          <>
            <Grid
              item
              xs={selectedDamage ? 8 : 12}
              className={clsx(classes.map, classes.animationResize)}
            >
              <Map centerPosition={masts[0].position || [0, 0]} kml={kmlLayer}>
                <>
                  {masts.map(mapMasts).map(({ name, position, icon, uuid }) => (
                    <Marker key={uuid} position={position} icon={icon}>
                      <Popup>{name}</Popup>
                    </Marker>
                  ))}
                  {damages.map(mapDamages).map(({ uuid, name, icon, position }) => (
                    <Marker
                      key={uuid}
                      position={position}
                      icon={icon}
                      eventHandlers={{
                        click: () => handleClickDamage(uuid)
                      }}
                    >
                      <Popup>{name}</Popup>
                    </Marker>
                  ))}
                </>
              </Map>
              {isLoading && <CircularProgress className={classes.circular} />}
            </Grid>
            {selectedDamage && (
              <Grid item xs={4} className={classes.animationResize}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <CustomScrollbars height="calc(100vh - 360px)" color="white">
                      {currentDamage?.medias?.length ? (
                        renderImages()
                      ) : (
                        <Typography
                          color="textPrimary"
                          className={clsx(classes.bold, classes.details)}
                          variant="body1"
                        >
                          <FormattedMessage id="damageDetails.noPhotos" />
                        </Typography>
                      )}
                    </CustomScrollbars>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          color="textPrimary"
                          className={clsx(classes.bold, classes.details)}
                          variant="body1"
                        >
                          <FormattedMessage id="damageDetails.damageClass" />:{' '}
                          {currentDamage?.damageClass?.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          color="textPrimary"
                          className={clsx(classes.bold, classes.details)}
                          variant="body1"
                        >
                          <FormattedMessage id="damageDetails.mast" />: <br />
                        </Typography>
                        <Typography color="textPrimary" className={classes.bold} variant="body1">
                          {currentDamage?.mast1?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          color="textPrimary"
                          className={clsx(classes.bold, classes.details)}
                          variant="body1"
                        >
                          <FormattedMessage id="damageDetails.line" />: <br />
                        </Typography>
                        <Typography color="textPrimary" className={classes.bold} variant="body1">
                          {currentDamage?.line?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          color="textPrimary"
                          className={clsx(classes.bold, classes.details)}
                          variant="body1"
                        >
                          <FormattedMessage id="damageDetails.determinedOn" />: <br />
                        </Typography>
                        <Typography color="textPrimary" className={classes.bold} variant="body1">
                          {dayjs(currentDamage?.flyHistory?.timestamp).format('DD.MM.YYYY')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          color="textPrimary"
                          className={clsx(classes.bold, classes.details)}
                          variant="body1"
                        >
                          <FormattedMessage id="damageDetails.notice" />:{' '}
                        </Typography>
                        <Typography color="textPrimary" className={classes.bold} variant="body1">
                          {currentDamage?.notice}
                        </Typography>
                      </Grid>
                      {currentDamage?.mast1?.form?.pictogram && (
                        <Grid item xs={12}>
                          <Box mb={2}>
                            <Typography
                              color="textPrimary"
                              className={clsx(classes.bold, classes.details)}
                              variant="body1"
                            >
                              <FormattedMessage id="damageDetails.detailView" />:{' '}
                            </Typography>
                          </Box>
                          {currentDamage?.damageDefinition?.type === DamageType.Mastarea && (
                            <MastPictogram
                              src={currentDamage.mast1.form.pictogram}
                              damageColor={
                                currentDamage.damageClass?.priority === 1
                                  ? MarkerColor.Red
                                  : currentDamage.damageClass?.priority === 4
                                  ? MarkerColor.Green
                                  : MarkerColor.Yellow
                              }
                              damagePosition={[damagePositionX, damagePositionY]}
                            />
                          )}
                          {currentDamage?.damageDefinition?.type === DamageType.Span && (
                            <Grid container>
                              <Grid item xs={4}>
                                <MastPictogram
                                  src={currentDamage.mast1.form.pictogram}
                                  damageColor={
                                    currentDamage.damageClass?.priority === 1
                                      ? MarkerColor.Red
                                      : currentDamage.damageClass?.priority === 4
                                      ? MarkerColor.Green
                                      : MarkerColor.Yellow
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Box className={classes.damageContainer}>
                                  <Box
                                    className={classes.lineDamage}
                                    style={{
                                      left: !currentDamage.distanceMast1 ? 0 : `${
                                        currentDamage.distanceMast1 / (currentDamage.distanceMast1 +
                                        currentDamage.distanceMast2) * 100
                                      }%`,
                                      backgroundColor:
                                        currentDamage.damageClass?.priority === 1
                                          ? MarkerColor.Red
                                          : currentDamage.damageClass?.priority === 4
                                          ? MarkerColor.Green
                                          : MarkerColor.Yellow
                                    }}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <MastPictogram
                                  src={currentDamage.mast1.form.pictogram}
                                  damageColor={
                                    currentDamage.damageClass?.priority === 1
                                      ? MarkerColor.Red
                                      : currentDamage.damageClass?.priority === 4
                                      ? MarkerColor.Green
                                      : MarkerColor.Yellow
                                  }
                                />
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
      {openGallery && currentDamage?.medias?.length && (
        <PhotoGallery
          photoIndex={photoIndex}
          onClose={() => setOpenGallery(false)}
          onChangeIndex={(index: number) => setPhotoIndex(index)}
          images={currentDamage?.medias.map(
            (mediaUuid: string) => `${apiBaseUrl}media/${mediaUuid}`
          )}
        />
      )}
    </BaseModal>
  );
};

export default DamageDetailsModal;
