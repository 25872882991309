import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import Autocomplete from 'components/Autocomplete';
import CustomScrollbars from 'components/CustomScrollbars';
import ListView from 'components/ListView';
import { HeadCell } from 'components/ListView/ListHeader';
import { PATHS } from 'constants/paths';
import dayjs from 'dayjs';
import { IOperatorRegionalOffice } from 'interfaces/operatorRegionalOffice';
import { IOrder } from 'interfaces/order';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { createExport } from 'redux/action/export';
import { getAllOperatorRegionalOffice } from 'redux/action/operatorRegionalOffice';
import { getOrdersNotOpened } from 'redux/action/order';
import { getOrderYears } from 'redux/action/orderYear';
import { getAllVoltageRanges } from 'redux/action/voltageRange';
import { operatorRegionalOfficesDataSelector } from 'redux/slice/operatorRegionalOfficesSlice';
import { orderSelector } from 'redux/slice/orderSlice';
import { orderYearsDataSelector } from 'redux/slice/orderYearSlice';
import { tableSelector } from 'redux/slice/tableSlice';
import { voltageRangesDataSelector } from 'redux/slice/voltageRangeSlice';
import withAuth from 'services/withAuth';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  state: {
    borderRadius: '30px',
    textAlign: 'center',
    color: theme.palette.text.primary,
    display: 'inline-block',
    padding: '3px 10px',
    width: '100%',
    minHeight: '18px',
    textTransform: 'uppercase'
  },
  notOpened: {
    backgroundColor: theme.palette.error.main
  },
  opened: {
    backgroundColor: theme.palette.info.main
  }
}));

const MainSelection = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [query, setQuery] = useState<string | undefined>(undefined);
  const [selectedRow, setSelectedRow] = useState<string[]>([]);
  const [selectedVoltages, setSelectedVoltages] = useState<string[]>([]);
  const [selectedYear, setSelectedYear] = useState<number | undefined>(undefined);
  const [selectedOperatorRegionalOffice, setSelectedOperatorRegionalOffice] = useState<
    IOperatorRegionalOffice | undefined
  >(undefined);

  const operatorRegionalOffices = useSelector(operatorRegionalOfficesDataSelector);
  const voltageRanges = useSelector(voltageRangesDataSelector);
  const years = useSelector(orderYearsDataSelector);
  const {
    data: { list, totalCount },
    isFetching
  } = useSelector(orderSelector);

  const { order, orderBy, page } = useSelector(tableSelector);

  const renderVoltageRanges = () =>
    voltageRanges.map((voltageRange: string) => (
      <Box display="inline-block" key={voltageRange} mr={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleOnClickVoltage(voltageRange)}
          startIcon={selectedVoltages.includes(voltageRange) && <CheckIcon />}
        >
          {voltageRange}
        </Button>
      </Box>
    ));

  const handleOnClickVoltage = (voltage: string) => {
    if (selectedVoltages.includes(voltage)) {
      setSelectedVoltages(selectedVoltages.filter((v: string) => v !== voltage));
    } else {
      setSelectedVoltages([...selectedVoltages, voltage]);
    }
  };

  const handleClickAdvanceSearch = () => {
    history.push(PATHS.advancedSearch);
  };

  const handleClickSearch = () => {
    dispatch(
      getOrdersNotOpened({
        pageSize: 10,
        voltageRanges: selectedVoltages,
        year: selectedYear,
        operatorRegionalOffice: selectedOperatorRegionalOffice,
        sort: orderBy,
        sortDirection: order,
        page,
        query
      })
    );
  };

  const headCells: HeadCell[] = [
    {
      key: 'lineName',
      label: formatMessage({ id: 'mainSelection.line' })
    },
    { key: 'voltageRange', label: formatMessage({ id: 'mainSelection.voltageRange' }) },
    {
      key: 'isOpened',
      label: formatMessage({ id: 'mainSelection.state' })
    },
    {
      key: 'updatedAt',
      label: formatMessage({ id: 'mainSelection.lastUpdate' })
    }
  ];

  const handleClickEdit = () => {
    const order = list?.find((order: IOrder) => selectedRow.includes(order.uuid));

    history.push({
      pathname: PATHS.editing,
      search: '?lineId=' + order?.line.uuid
    });
  };

  const handleClickExport = () => {
    dispatch(createExport({ orderIds: selectedRow }));
    enqueueSnackbar(formatMessage({ id: 'createExportSuccess' }), {
      variant: 'success'
    });
  };

  const handleOnSelectedRows = (selected: string[]) => {
    setSelectedRow(selected);
  };

  const mapOrder = (order: IOrder) => ({
    key: order.uuid,
    cells: [
      order.line?.name,
      order.line?.voltageRange,
      <Box
        key="status"
        className={clsx({
          [classes.state]: true,
          [classes.opened]: order.isOpened,
          [classes.notOpened]: !order.isOpened
        })}
      >
        {formatMessage({ id: `mainSelection.status.${order.isOpened ? 'opened' : 'notOpened'}` })}
      </Box>,
      dayjs(order.updatedAt).format('DD.MM.YYYY') || ''
    ]
  });

  useEffect(() => {
    dispatch(getAllOperatorRegionalOffice());
    dispatch(getAllVoltageRanges());
    dispatch(getOrderYears());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getOrdersNotOpened({
        pageSize: 10,
        voltageRanges: selectedVoltages,
        year: selectedYear,
        operatorRegionalOffice: selectedOperatorRegionalOffice,
        sort: orderBy,
        sortDirection: order,
        page,
        query
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    selectedVoltages,
    selectedYear,
    selectedOperatorRegionalOffice,
    orderBy,
    order,
    page
  ]);

  return (
    <Container disableGutters maxWidth={false} className={classes.root}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        height="100%"
        width="100%"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="textPrimary" variant="subtitle1">
              <FormattedMessage id="mainSelection.description" />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              id="regionalOffice"
              options={operatorRegionalOffices}
              disabled={operatorRegionalOffices.length === 0}
              onChange={(_, value) =>
                setSelectedOperatorRegionalOffice(value as IOperatorRegionalOffice)
              }
              getOptionLabel={(office: IOperatorRegionalOffice) => office.name}
              value={selectedOperatorRegionalOffice}
              getOptionSelected={(
                option: IOperatorRegionalOffice,
                value: IOperatorRegionalOffice
              ) => option.uuid === value.uuid}
              inputLabel={formatMessage({ id: 'mainSelection.regionalOffice' })}
              inputColor="secondary"
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              id="year"
              options={years}
              disabled={years.length === 0}
              onChange={(_, value) => setSelectedYear(value as number)}
              getOptionLabel={(value: number) => value.toString()}
              value={selectedYear}
              getOptionSelected={(option: number, value: number) => option === value}
              inputLabel={formatMessage({ id: 'mainSelection.year' })}
              inputColor="secondary"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              fullWidth
              color="secondary"
              type="text"
              value={query}
              onChange={(event) => setQuery(event.target.value)}
              placeholder={formatMessage({ id: 'mainSelection.search' })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ color: 'white' }}
                      aria-label="search"
                      onClick={handleClickSearch}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              className="submit-btn"
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleClickAdvanceSearch}
            >
              <FormattedMessage id="mainSelection.advancedSearch" />
            </Button>
          </Grid>
          <Grid item xs={12}>
            {renderVoltageRanges()}
          </Grid>
          <Grid item xs={9}>
            <CustomScrollbars height="calc(100vh - 400px)">
              <ListView
                totalCount={totalCount}
                headCells={headCells}
                rows={list.map(mapOrder)}
                loading={isFetching}
                multiple
                selected={selectedRow}
                onSelectRow={handleOnSelectedRows}
              />
            </CustomScrollbars>
          </Grid>
          <Grid item xs={3}>
            <Box mb={2}>
              <Button
                className="submit-btn"
                variant="contained"
                color="secondary"
                disabled={selectedRow.length !== 1}
                fullWidth
                onClick={handleClickEdit}
              >
                <FormattedMessage id="button.edit" />
              </Button>
            </Box>
            <Box mb={2}>
              <Button
                className="submit-btn"
                variant="contained"
                color="secondary"
                disabled={selectedRow.length < 2}
                fullWidth
                onClick={handleClickExport}
              >
                <FormattedMessage id="button.export" />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default withAuth(MainSelection);
