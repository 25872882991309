import { GET_ORDER_NOT_OPENED_URL, GET_ORDER_YEARS_URL } from 'constants/apiEndpoints';
import { OrderType } from 'redux/slice/tableSlice';
import httpClient from 'services/apiClient';

const OrderService = {
  getOrdersNotOpened: async (
    page: number,
    pageSize: number,
    sort?: string,
    sortDirection?: OrderType,
    voltageRanges?: string[],
    year?: number,
    operatorRegionalOffice?: string,
    query?: string,
    inspectionDate?: string,
    damageTypes?: string[],
    damagePriorities?: number[],
    damageDefinitions?: string[],
  ) => {
    try {
      return await httpClient.get(GET_ORDER_NOT_OPENED_URL, {
        params: {
          page,
          pageSize,
          sort,
          sortDirection,
          voltageRanges,
          operatorRegionalOffice,
          year,
          query,
          inspectionDate,
          damageTypes,
          damagePriorities,
          damageDefinitions
        }
      });
    } catch (error: any) {
      return error.response;
    }
  },
  getOrderYears: async () => {
    try {
      return await httpClient.get(GET_ORDER_YEARS_URL);
    } catch (error: any) {
      return error.response;
    }
  }
};

export default OrderService;
