import { PayloadAction } from '@reduxjs/toolkit';
import { IMast } from 'interfaces/mast';
import { PageResult } from 'interfaces/pageResult';
import { getAllMasts } from 'redux/action/mast';

import { StateData } from '../../interfaces/stateData';
import createAsyncReducer from '../createAsyncReducer';
import { createAsyncSlice } from '../createAsyncSlice';
import { RootState } from '../rootState';

export const mastSlice = createAsyncSlice<StateData<IMast>>({
  name: 'mast',
  initialData: {
    list: [],
    totalCount: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    createAsyncReducer(
      builder,
      getAllMasts,
      (state, { payload }: PayloadAction<PageResult<IMast>>) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.list = payload.content;
        state.data.totalCount = payload.totalElements;
      }
    );
  }
});

export const mastSelector = (state: RootState) => state.mastSlice;
export const mastTotalCountSelector = (state: RootState) => state.mastSlice.data.totalCount;
export const mastsDataSelector = (state: RootState) => state.mastSlice.data.list;
export const mastDataSelector = (state: RootState) => state.mastSlice.data.entry;

export const { clearState } = mastSlice.actions;
