import { Container, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import CustomScrollbars from 'components/CustomScrollbars';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '960px'
  },
  paragraph: {
    width: '100%',
    marginBottom: '10px'
  }
}));

const DataPrivacy = () => {
  const classes = useStyles();
  return (
    <CustomScrollbars height="80vh">
      <Container className={classes.root}>
        <Grid container>
          <Typography color="textPrimary" className={classes.paragraph} variant="h3">
            Datenschutzerklärung
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen. Datenschutz hat einen
            besonders hohen Stellenwert für die Geschäftsleitung der SKY HELI GmbH. Eine Nutzung der
            Internetseiten der SKY HELI GMBH ist grundsätzlich ohne jede Angabe personenbezogener
            Daten möglich. Sofern eine betroffene Person besondere Services unseres Unternehmens
            über unsere Internetseite in Anspruch nehmen möchte, könnte jedoch eine Verarbeitung
            personenbezogener Daten erforderlich werden. Ist die Verarbeitung personenbezogener
            Daten erforderlich und besteht für eine solche Verarbeitung keine gesetzliche Grundlage,
            holen wir generell eine Einwilligung der betroffenen Person ein.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die Verarbeitung personenbezogener Daten, beispielsweise des Namens, der Anschrift,
            E-Mail-Adresse oder Telefonnummer einer betroffenen Person, erfolgt stets im Einklang
            mit der Datenschutz-Grundverordnung und in Übereinstimmung mit den für die SKY HELI GmbH
            geltenden landesspezifischen Datenschutzbestimmungen. Mittels dieser
            Datenschutzerklärung möchte unser Unternehmen die Öffentlichkeit über Art, Umfang und
            Zweck der von uns erhobenen, genutzten und verarbeiteten personenbezogenen Daten
            informieren. Ferner werden betroffene Personen mittels dieser Datenschutzerklärung über
            die ihnen zustehenden Rechte aufgeklärt.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die SKY HELI GMBH hat als für die Verarbeitung Verantwortlicher zahlreiche technische
            und organisatorische Maßnahmen umgesetzt, um einen möglichst lückenlosen Schutz der über
            diese Internetseite verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch
            können Internetbasierte Datenübertragungen grundsätzlich Sicherheitslücken aufweisen,
            sodass ein absoluter Schutz nicht gewährleistet werden kann. Aus diesem Grund steht es
            jeder betroffenen Person frei, personenbezogene Daten auch auf alternativen Wegen,
            beispielsweise telefonisch, an uns zu übermitteln.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            1. Begriffsbestimmungen
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die Datenschutzerklärung der SKY HELI GMBH beruht auf den Begrifflichkeiten, die durch
            den Europäischen Richtlinien- und Verordnungsgeber beim Erlass der
            Datenschutz-Grundverordnung (DS-GVO) verwendet wurden. Unsere Datenschutzerklärung soll
            sowohl für die Öffentlichkeit als auch für unsere Kunden und Geschäftspartner einfach
            lesbar und verständlich sein. Um dies zu gewährleisten, möchten wir vorab die
            verwendeten Begrifflichkeiten erläutern.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden Begriffe:
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            a) personenbezogene Daten
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder
            identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen. Als
            identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt,
            insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
            Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen,
            die Ausdruck der physischen, physiologischen, genetischen, psychischen,
            wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind,
            identifiziert werden kann.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            b) betroffene Person
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren
            personenbezogene Daten von dem für die Verarbeitung Verantwortlichen verarbeitet werden.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            c) Verarbeitung
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang
            oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das
            Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder
            Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch
            Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder
            die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            d) Einschränkung der Verarbeitung
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Einschränkung der Verarbeitung ist die Markierung gespeicherter personenbezogener Daten
            mit dem Ziel, ihre künftige Verarbeitung einzuschränken.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            e) Profiling
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die
            darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte
            persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten,
            insbesondere, um Aspekte bezüglich Arbeitsleistung, wirtschaftlicher Lage, Gesundheit,
            persönlicher Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder
            Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            f) Pseudonymisierung
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise, auf
            welche die personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht
            mehr einer spezifischen betroffenen Person zugeordnet werden können, sofern diese
            zusätzlichen Informationen gesondert aufbewahrt werden und technischen und
            organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen
            Daten nicht einer identifizierten oder identifizierbaren natürlichen Person zugewiesen
            werden.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            g) Verantwortlicher oder für die Verarbeitung Verantwortlicher
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die natürliche oder
            juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam
            mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
            entscheidet. Sind die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder
            das Recht der Mitgliedstaaten vorgegeben, so kann der Verantwortliche beziehungsweise
            können die bestimmten Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der
            Mitgliedstaaten vorgesehen werden.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            h) Auftragsverarbeiter
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung
            oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen
            verarbeitet.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            i) Empfänger
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere
            Stelle, der personenbezogene Daten offengelegt werden, unabhängig davon, ob es sich bei
            ihr um einen Dritten handelt oder nicht. Behörden, die im Rahmen eines bestimmten
            Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
            möglicherweise personenbezogene Daten erhalten, gelten jedoch nicht als Empfänger.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            j) Dritter
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere
            Stelle außer der betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und
            den Personen, die unter der unmittelbaren Verantwortung des Verantwortlichen oder des
            Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            k) Einwilligung
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in
            informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer
            Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die
            betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden
            personenbezogenen Daten einverstanden ist.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            2. Name und Anschrift des für die Verarbeitung Verantwortlichen
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den
            Mitgliedstaaten der Europäischen Union geltenden Datenschutzgesetze und anderer
            Bestimmungen mit datenschutzrechtlichem Charakter ist die:
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            SKY HELI GMBH
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Flugplatz Haus 3
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            14959 Trebbin OT Schönhagen
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Deutschland
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Tel.: +49 33731 702 990
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            E-Mail: info@skyheli.de
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Website: www.skyheli.de
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            3. Cookies
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die Internetseiten der SKY HELI GMBH verwenden Cookies. Cookies sind Textdateien, welche
            über einen Internetbrowser auf einem Computersystem abgelegt und gespeichert werden.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies enthalten eine
            sogenannte Cookie-ID. Eine Cookie-ID ist eine eindeutige Kennung des Cookies. Sie
            besteht aus einer Zeichenfolge, durch welche Internetseiten und Server dem konkreten
            Internetbrowser zugeordnet werden können, in dem das Cookie gespeichert wurde. Dies
            ermöglicht es den besuchten Internetseiten und Servern, den individuellen Browser der
            betroffenen Person von anderen Internetbrowsern, die andere Cookies enthalten, zu
            unterscheiden. Ein bestimmter Internetbrowser kann über die eindeutige Cookie-ID
            wiedererkannt und identifiziert werden.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Durch den Einsatz von Cookies kann die SKY HELI GMBH den Nutzern dieser Internetseite
            nutzerfreundlichere Services bereitstellen, die ohne die Cookie-Setzung nicht möglich
            wären.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Mittels eines Cookies können die Informationen und Angebote auf unserer Internetseite im
            Sinne des Benutzers optimiert werden. Cookies ermöglichen uns, wie bereits erwähnt, die
            Benutzer unserer Internetseite wiederzuerkennen. Zweck dieser Wiedererkennung ist es,
            den Nutzern die Verwendung unserer Internetseite zu erleichtern. Der Benutzer einer
            Internetseite, die Cookies verwendet, muss beispielsweise nicht bei jedem Besuch der
            Internetseite erneut seine Zugangsdaten eingeben, weil dies von der Internetseite und
            dem auf dem Computersystem des Benutzers abgelegten Cookie übernommen wird. Ein weiteres
            Beispiel ist das Cookie eines Warenkorbes im Online-Shop. Der Online-Shop merkt sich die
            Artikel, die ein Kunde in den virtuellen Warenkorb gelegt hat, über ein Cookie.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite jederzeit
            mittels einer entsprechenden Einstellung des genutzten Internetbrowsers verhindern und
            damit der Setzung von Cookies dauerhaft widersprechen. Ferner können bereits gesetzte
            Cookies jederzeit über einen Internetbrowser oder andere Softwareprogramme gelöscht
            werden. Dies ist in allen gängigen Internetbrowsern möglich. Deaktiviert die betroffene
            Person die Setzung von Cookies in dem genutzten Internetbrowser, sind unter Umständen
            nicht alle Funktionen unserer Internetseite vollumfänglich nutzbar.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            4. Erfassung von allgemeinen Daten und Informationen
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die Internetseite der SKY HELI GMBH erfasst mit jedem Aufruf der Internetseite durch
            eine betroffene Person oder ein automatisiertes System eine Reihe von allgemeinen Daten
            und Informationen. Diese allgemeinen Daten und Informationen werden in den Logfiles des
            Servers gespeichert. Erfasst werden können die (1) verwendeten Browsertypen und
            Versionen, (2) das vom zugreifenden System verwendete Betriebssystem, (3) die
            Internetseite, von welcher ein zugreifendes System auf unsere Internetseite gelangt
            (sogenannte Referrer), (4) die Unterwebseiten, welche über ein zugreifendes System auf
            unserer Internetseite angesteuert werden, (5) das Datum und die Uhrzeit eines Zugriffs
            auf die Internetseite, (6) eine Internet-Protokoll-Adresse (IP-Adresse), (7) der
            Internet-Service-Provider des zugreifenden Systems und (8) sonstige ähnliche Daten und
            Informationen, die der Gefahrenabwehr im Falle von Angriffen auf unsere
            informationstechnologischen Systeme dienen.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Bei der Nutzung dieser allgemeinen Daten und Informationen zieht die SKY HELI GMBH keine
            Rückschlüsse auf die betroffene Person. Diese Informationen werden vielmehr benötigt, um
            (1) die Inhalte unserer Internetseite korrekt auszuliefern, (2) die Inhalte unserer
            Internetseite sowie die Werbung für diese zu optimieren, (3) die dauerhafte
            Funktionsfähigkeit unserer informationstechnologischen Systeme und der Technik unserer
            Internetseite zu gewährleisten sowie (4) um Strafverfolgungsbehörden im Falle eines
            Cyberangriffes die zur Strafverfolgung notwendigen Informationen bereitzustellen. Diese
            anonym erhobenen Daten und Informationen werden durch die SKY HELI GMBH daher einerseits
            statistisch und ferner mit dem Ziel ausgewertet, den Datenschutz und die Datensicherheit
            in unserem Unternehmen zu erhöhen, um letztlich ein optimales Schutzniveau für die von
            uns verarbeiteten personenbezogenen Daten sicherzustellen. Die anonymen Daten der
            Server-Logfiles werden getrennt von allen durch eine betroffene Person angegebenen
            personenbezogenen Daten gespeichert.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            5. Registrierung auf unserer Internetseite
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die betroffene Person hat die Möglichkeit, sich auf der Internetseite des für die
            Verarbeitung Verantwortlichen unter Angabe von personenbezogenen Daten zu registrieren.
            Welche personenbezogenen Daten dabei an den für die Verarbeitung Verantwortlichen
            übermittelt werden, ergibt sich aus der jeweiligen Eingabemaske, die für die
            Registrierung verwendet wird. Die von der betroffenen Person eingegebenen
            personenbezogenen Daten werden ausschließlich für die interne Verwendung bei dem für die
            Verarbeitung Verantwortlichen und für eigene Zwecke erhoben und gespeichert. Der für die
            Verarbeitung Verantwortliche kann die Weitergabe an einen oder mehrere
            Auftragsverarbeiter, beispielsweise einen Paketdienstleister, veranlassen, der die
            personenbezogenen Daten ebenfalls ausschließlich für eine interne Verwendung, die dem
            für die Verarbeitung Verantwortlichen zuzurechnen ist, nutzt.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Durch eine Registrierung auf der Internetseite des für die Verarbeitung Verantwortlichen
            wird ferner die vom Internet-Service-Provider (ISP) der betroffenen Person vergebene
            IP-Adresse, das Datum sowie die Uhrzeit der Registrierung gespeichert. Die Speicherung
            dieser Daten erfolgt vor dem Hintergrund, dass nur so der Missbrauch unserer Dienste
            verhindert werden kann, und diese Daten im Bedarfsfall ermöglichen, begangene Straftaten
            aufzuklären. Insofern ist die Speicherung dieser Daten zur Absicherung des für die
            Verarbeitung Verantwortlichen erforderlich. Eine Weitergabe dieser Daten an Dritte
            erfolgt grundsätzlich nicht, sofern keine gesetzliche Pflicht zur Weitergabe besteht
            oder die Weitergabe der Strafverfolgung dient.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die Registrierung der betroffenen Person unter freiwilliger Angabe personenbezogener
            Daten dient dem für die Verarbeitung Verantwortlichen dazu, der betroffenen Person
            Inhalte oder Leistungen anzubieten, die aufgrund der Natur der Sache nur registrierten
            Benutzern angeboten werden können. Registrierten Personen steht die Möglichkeit frei,
            die bei der Registrierung angegebenen personenbezogenen Daten jederzeit abzuändern oder
            vollständig aus dem Datenbestand des für die Verarbeitung Verantwortlichen löschen zu
            lassen.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Der für die Verarbeitung Verantwortliche erteilt jeder betroffenen Person jederzeit auf
            Anfrage Auskunft darüber, welche personenbezogenen Daten über die betroffene Person
            gespeichert sind. Ferner berichtigt oder löscht der für die Verarbeitung Verantwortliche
            personenbezogene Daten auf Wunsch oder Hinweis der betroffenen Person, soweit dem keine
            gesetzlichen Aufbewahrungspflichten entgegenstehen. Die Gesamtheit der Mitarbeiter des
            für die Verarbeitung Verantwortlichen stehen der betroffenen Person in diesem
            Zusammenhang als Ansprechpartner zur Verfügung.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            6. Abonnement unseres Newsletters
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Auf der Internetseite der SKY HELI GMBH wird den Benutzern die Möglichkeit eingeräumt,
            den Newsletter unseres Unternehmens zu abonnieren. Welche personenbezogenen Daten bei
            der Bestellung des Newsletters an den für die Verarbeitung Verantwortlichen übermittelt
            werden, ergibt sich aus der hierzu verwendeten Eingabemaske.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die SKY HELI GMBH informiert ihre Kunden und Geschäftspartner in regelmäßigen Abständen
            im Wege eines Newsletters über Angebote des Unternehmens. Der Newsletter unseres
            Unternehmens kann von der betroffenen Person grundsätzlich nur dann empfangen werden,
            wenn (1) die betroffene Person über eine gültige E-Mail-Adresse verfügt und (2) die
            betroffene Person sich für den Newsletterversand registriert. An die von einer
            betroffenen Person erstmalig für den Newsletterversand eingetragene E-Mail-Adresse wird
            aus rechtlichen Gründen eine Bestätigungsmail im Double-Opt-In-Verfahren versendet.
            Diese Bestätigungsmail dient der Überprüfung, ob der Inhaber der E-Mail-Adresse als
            betroffene Person den Empfang des Newsletters autorisiert hat.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Auf der Internetseite der SKY HELI GMBH wird den Benutzern die Möglichkeit eingeräumt,
            den Newsletter unseres Unternehmens zu abonnieren. Welche personenbezogenen Daten bei
            der Bestellung des Newsletters an den für die Verarbeitung Verantwortlichen übermittelt
            werden, ergibt sich aus der hierzu verwendeten Eingabemaske.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die im Rahmen einer Anmeldung zum Newsletter erhobenen personenbezogenen Daten werden
            ausschließlich zum Versand unseres Newsletters verwendet. Ferner könnten Abonnenten des
            Newsletters per E-Mail informiert werden, sofern dies für den Betrieb des
            Newsletter-Dienstes oder eine diesbezügliche Registrierung erforderlich ist, wie dies im
            Falle von Änderungen am Newsletterangebot oder bei der Veränderung der technischen
            Gegebenheiten der Fall sein könnte. Es erfolgt keine Weitergabe der im Rahmen des
            Newsletter-Dienstes erhobenen personenbezogenen Daten an Dritte. Das Abonnement unseres
            Newsletters kann durch die betroffene Person jederzeit gekündigt werden. Die
            Einwilligung in die Speicherung personenbezogener Daten, die die betroffene Person uns
            für den Newsletterversand erteilt hat, kann jederzeit widerrufen werden. Zum Zwecke des
            Widerrufs der Einwilligung findet sich in jedem Newsletter ein entsprechender Link.
            Ferner besteht die Möglichkeit, sich jederzeit auch direkt auf der Internetseite des für
            die Verarbeitung Verantwortlichen vom Newsletterversand abzumelden oder dies dem für die
            Verarbeitung Verantwortlichen auf andere Weise mitzuteilen.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            7. Newsletter-Tracking
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die Newsletter der SKY HELI GMBH enthalten sogenannte Zählpixel. Ein Zählpixel ist eine
            Miniaturgrafik, die in solche E-Mails eingebettet wird, welche im HTML-Format versendet
            werden, um eine Logdatei-Aufzeichnung und eine Logdatei-Analyse zu ermöglichen. Dadurch
            kann eine statistische Auswertung des Erfolges oder Misserfolges von
            Online-Marketing-Kampagnen durchgeführt werden. Anhand des eingebetteten Zählpixels kann
            die SKY HELI GMBH erkennen, ob und wann eine E-Mail von einer betroffenen Person
            geöffnet wurde und welche in der E-Mail befindlichen Links von der betroffenen Person
            aufgerufen wurden.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Solche über die in den Newslettern enthaltenen Zählpixel erhobenen personenbezogenen
            Daten, werden von dem für die Verarbeitung Verantwortlichen gespeichert und ausgewertet,
            um den Newsletterversand zu optimieren und den Inhalt zukünftiger Newsletter noch besser
            den Interessen der betroffenen Person anzupassen. Diese personenbezogenen Daten werden
            nicht an Dritte weitergegeben. Betroffene Personen sind jederzeit berechtigt, die
            diesbezügliche gesonderte, über das Double-Opt-In-Verfahren abgegebene
            Einwilligungserklärung zu widerrufen. Nach einem Widerruf werden diese personenbezogenen
            Daten von dem für die Verarbeitung Verantwortlichen gelöscht. Eine Abmeldung vom Erhalt
            des Newsletters deutet die SKY HELI GMBH automatisch als Widerruf.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            8. Kontaktmöglichkeit über die Internetseite
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die Internetseite der SKY HELI GMBH enthält aufgrund von gesetzlichen Vorschriften
            Angaben, die eine schnelle elektronische Kontaktaufnahme zu unserem Unternehmen sowie
            eine unmittelbare Kommunikation mit uns ermöglichen, was ebenfalls eine allgemeine
            Adresse der sogenannten elektronischen Post (E-Mail-Adresse) umfasst. Sofern eine
            betroffene Person per E-Mail oder über ein Kontaktformular den Kontakt mit dem für die
            Verarbeitung Verantwortlichen aufnimmt, werden die von der betroffenen Person
            übermittelten personenbezogenen Daten automatisch gespeichert. Solche auf freiwilliger
            Basis von einer betroffenen Person an den für die Verarbeitung Verantwortlichen
            übermittelten personenbezogenen Daten werden für Zwecke der Bearbeitung oder der
            Kontaktaufnahme zur betroffenen Person gespeichert. Es erfolgt keine Weitergabe dieser
            personenbezogenen Daten an Dritte.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            9. Routinemäßige Löschung und Sperrung von personenbezogenen Daten
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Der für die Verarbeitung Verantwortliche verarbeitet und speichert personenbezogene
            Daten der betroffenen Person nur für den Zeitraum, der zur Erreichung des
            Speicherungszwecks erforderlich ist oder sofern dies durch den Europäischen Richtlinien-
            und Verordnungsgeber oder einen anderen Gesetzgeber in Gesetzen oder Vorschriften,
            welchen der für die Verarbeitung Verantwortliche unterliegt, vorgesehen wurde.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Entfällt der Speicherungszweck oder läuft eine vom Europäischen Richtlinien- und
            Verordnungsgeber oder einem anderen zuständigen Gesetzgeber vorgeschriebene
            Speicherfrist ab, werden die personenbezogenen Daten routinemäßig und entsprechend den
            gesetzlichen Vorschriften gesperrt oder gelöscht.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            10. Rechte der betroffenen Person
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            a) Recht auf Bestätigung
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Jede betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber
            eingeräumte Recht, von dem für die Verarbeitung Verantwortlichen eine Bestätigung
            darüber zu verlangen, ob sie betreffende personenbezogene Daten verarbeitet werden.
            Möchte eine betroffene Person dieses Bestätigungsrecht in Anspruch nehmen, kann sie sich
            hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            b) Recht auf Auskunft
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
            Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, jederzeit von dem für die
            Verarbeitung Verantwortlichen unentgeltliche Auskunft über die zu seiner Person
            gespeicherten personenbezogenen Daten und eine Kopie dieser Auskunft zu erhalten. Ferner
            hat der Europäische Richtlinien- und Verordnungsgeber der betroffenen Person Auskunft
            über folgende Informationen zugestanden:
          </Typography>

          <ul>
            <li>
              <Typography color="textPrimary" className={classes.paragraph} variant="body1">
                die Verarbeitungszwecke
              </Typography>
            </li>

            <li>
              <Typography color="textPrimary" className={classes.paragraph} variant="body1">
                die Kategorien personenbezogener Daten, die verarbeitet werden
              </Typography>
            </li>

            <li>
              <Typography color="textPrimary" className={classes.paragraph} variant="body1">
                die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen
                Daten offengelegt worden sind oder noch offengelegt werden, insbesondere bei
                Empfängern in Drittländern oder bei internationalen Organisationen
              </Typography>
            </li>

            <li>
              <Typography color="textPrimary" className={classes.paragraph} variant="body1">
                falls möglich die geplante Dauer, für die die personenbezogenen Daten gespeichert
                werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser
                Dauer
              </Typography>
            </li>

            <li>
              <Typography color="textPrimary" className={classes.paragraph} variant="body1">
                das Bestehen eines Rechts auf Berichtigung oder Löschung der sie betreffenden
                personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den
                Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung
              </Typography>
            </li>

            <li>
              <Typography color="textPrimary" className={classes.paragraph} variant="body1">
                das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde
              </Typography>
            </li>

            <li>
              <Typography color="textPrimary" className={classes.paragraph} variant="body1">
                wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden:
                Alle verfügbaren Informationen über die Herkunft der Daten
              </Typography>
            </li>

            <li>
              <Typography color="textPrimary" className={classes.paragraph} variant="body1">
                das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling
                gemäß Artikel 22 Abs.1 und 4 DS-GVO und — zumindest in diesen Fällen —
                aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die
                angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person
              </Typography>
            </li>
          </ul>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu, ob personenbezogene
            Daten an ein Drittland oder an eine internationale Organisation übermittelt wurden.
            Sofern dies der Fall ist, so steht der betroffenen Person im Übrigen das Recht zu,
            Auskunft über die geeigneten Garantien im Zusammenhang mit der Übermittlung zu erhalten.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Möchte eine betroffene Person dieses Auskunftsrecht in Anspruch nehmen, kann sie sich
            hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            c) Recht auf Berichtigung
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
            Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, die unverzügliche
            Berichtigung sie betreffender unrichtiger personenbezogener Daten zu verlangen. Ferner
            steht der betroffenen Person das Recht zu, unter Berücksichtigung der Zwecke der
            Verarbeitung, die Vervollständigung unvollständiger personenbezogener Daten — auch
            mittels einer ergänzenden Erklärung — zu verlangen.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Möchte eine betroffene Person dieses Berichtigungsrecht in Anspruch nehmen, kann sie
            sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen
            wenden.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            d) Recht auf Löschung (Recht auf Vergessen werden)
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
            Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, von dem Verantwortlichen
            zu verlangen, dass die sie betreffenden personenbezogenen Daten unverzüglich gelöscht
            werden, sofern einer der folgenden Gründe zutrifft und soweit die Verarbeitung nicht
            erforderlich ist:
          </Typography>

          <ul>
            <li>
              <Typography color="textPrimary" className={classes.paragraph} variant="body1">
                Die personenbezogenen Daten wurden für solche Zwecke erhoben oder auf sonstige Weise
                verarbeitet, für welche sie nicht mehr notwendig sind.
              </Typography>
            </li>

            <li>
              <Typography color="textPrimary" className={classes.paragraph} variant="body1">
                Die betroffene Person widerruft ihre Einwilligung, auf die sich die Verarbeitung
                gemäß Art. 6 Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs.2 Buchstabe a DS-GVO stützte,
                und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
              </Typography>
            </li>

            <li>
              <Typography color="textPrimary" className={classes.paragraph} variant="body1">
                Die betroffene Person legt gemäß Art. 21 Abs. 1 DS-GVO Widerspruch gegen die
                Verarbeitung ein, und es liegen keine vorrangigen berechtigten Gründe für die
                Verarbeitung vor, oder die betroffene Person legt gemäß Art. 21 Abs. 2 DS-GVO
                Widerspruch gegen die Verarbeitung ein.
              </Typography>
            </li>

            <li>
              <Typography color="textPrimary" className={classes.paragraph} variant="body1">
                Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.
              </Typography>
            </li>

            <li>
              <Typography color="textPrimary" className={classes.paragraph} variant="body1">
                Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen
                Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich,
                dem der Verantwortliche unterliegt.
              </Typography>
            </li>

            <li>
              <Typography color="textPrimary" className={classes.paragraph} variant="body1">
                Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
                Informationsgesellschaft gemäß Art. 8 Abs. 1 DS-GVO erhoben.
              </Typography>
            </li>
          </ul>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Sofern einer der oben genannten Gründe zutrifft und eine betroffene Person die Löschung
            von personenbezogenen Daten, die bei der SKY HELI GMBH gespeichert sind, veranlassen
            möchte, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
            Verantwortlichen wenden. Der Mitarbeiter der SKY HELI GMBH wird veranlassen, dass dem
            Löschverlangen unverzüglich nachgekommen wird.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Wurden die personenbezogenen Daten von der SKY HELI GMBH öffentlich gemacht und ist
            unser Unternehmen als Verantwortlicher gemäß Art. 17 Abs. 1 DS-GVO zur Löschung der
            personenbezogenen Daten verpflichtet, so trifft die SKY HELI GMBH unter Berücksichtigung
            der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch
            technischer Art, um andere für die Datenverarbeitung Verantwortliche, welche die
            veröffentlichten personenbezogenen Daten verarbeiten, darüber in Kenntnis zu setzen,
            dass die betroffene Person von diesen anderen für die Datenverarbeitung Verantwortlichen
            die Löschung sämtlicher Links zu diesen personenbezogenen Daten oder von Kopien oder
            Replikationen dieser personenbezogenen Daten verlangt hat, soweit die Verarbeitung nicht
            erforderlich ist. Der Mitarbeiter der SKY HELI GMBH wird im Einzelfall das Notwendige
            veranlassen.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            e) Recht auf Einschränkung der Verarbeitung
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
            Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, von dem Verantwortlichen
            die Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen
            gegeben ist:
          </Typography>

          <ul>
            <li>
              <Typography color="textPrimary" className={classes.paragraph} variant="body1">
                Die Richtigkeit der personenbezogenen Daten wird von der betroffenen Person
                bestritten, und zwar für eine Dauer, die es dem Verantwortlichen ermöglicht, die
                Richtigkeit der personenbezogenen Daten zu überprüfen.
              </Typography>
            </li>

            <li>
              <Typography color="textPrimary" className={classes.paragraph} variant="body1">
                Die Verarbeitung ist unrechtmäßig, die betroffene Person lehnt die Löschung der
                personenbezogenen Daten ab und verlangt stattdessen die Einschränkung der Nutzung
                der personenbezogenen Daten.
              </Typography>
            </li>

            <li>
              <Typography color="textPrimary" className={classes.paragraph} variant="body1">
                Der Verantwortliche benötigt die personenbezogenen Daten für die Zwecke der
                Verarbeitung nicht länger, die betroffene Person benötigt sie jedoch zur
                Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
              </Typography>
            </li>

            <li>
              <Typography color="textPrimary" className={classes.paragraph} variant="body1">
                Die betroffene Person hat Widerspruch gegen die Verarbeitung gem. Art. 21 Abs. 1
                DS-GVO eingelegt und es steht noch nicht fest, ob die berechtigten Gründe des
                Verantwortlichen gegenüber denen der betroffenen Person überwiegen.
              </Typography>
            </li>
          </ul>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Sofern eine der oben genannten Voraussetzungen gegeben ist und eine betroffene Person
            die Einschränkung von personenbezogenen Daten, die bei der SKY HELI GMBH gespeichert
            sind, verlangen möchte, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die
            Verarbeitung Verantwortlichen wenden. Der Mitarbeiter der SKY HELI GMBH wird die
            Einschränkung der Verarbeitung veranlassen.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            f) Recht auf Datenübertragbarkeit
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
            Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, die sie betreffenden
            personenbezogenen Daten, welche durch die betroffene Person einem Verantwortlichen
            bereitgestellt wurden, in einem strukturierten, gängigen und maschinenlesbaren Format zu
            erhalten. Sie hat außerdem das Recht, diese Daten einem anderen Verantwortlichen ohne
            Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt
            wurden, zu übermitteln, sofern die Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1
            Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO oder auf einem Vertrag gemäß
            Art. 6 Abs. 1 Buchstabe b DS-GVO beruht und die Verarbeitung mithilfe automatisierter
            Verfahren erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe
            erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher
            Gewalt erfolgt, welche dem Verantwortlichen übertragen wurde.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Ferner hat die betroffene Person bei der Ausübung ihres Rechts auf Datenübertragbarkeit
            gemäß Art. 20 Abs. 1 DS-GVO das Recht, zu erwirken, dass die personenbezogenen Daten
            direkt von einem Verantwortlichen an einen anderen Verantwortlichen übermittelt werden,
            soweit dies technisch machbar ist und sofern hiervon nicht die Rechte und Freiheiten
            anderer Personen beeinträchtigt werden.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Zur Geltendmachung des Rechts auf Datenübertragbarkeit kann sich die betroffene Person
            jederzeit an einen Mitarbeiter der SKY HELI GMBH wenden.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            g) Recht auf Widerspruch
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
            Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, aus Gründen, die sich aus
            ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung sie betreffender
            personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 Buchstaben e oder f DS-GVO
            erfolgt, Widerspruch einzulegen. Dies gilt auch für ein auf diese Bestimmungen
            gestütztes Profiling.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die SKY HELI GMBH verarbeitet die personenbezogenen Daten im Falle des Widerspruchs
            nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung
            nachweisen, die den Interessen, Rechten und Freiheiten der betroffenen Person
            überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung
            von Rechtsansprüchen.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Verarbeitet die SKY HELI GMBH personenbezogene Daten, um Direktwerbung zu betreiben, so
            hat die betroffene Person das Recht, jederzeit Widerspruch gegen die Verarbeitung der
            personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen. Dies gilt auch für das
            Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Widerspricht die
            betroffene Person gegenüber der SKY HELI GMBH der Verarbeitung für Zwecke der
            Direktwerbung, so wird die SKY HELI GMBH die personenbezogenen Daten nicht mehr für
            diese Zwecke verarbeiten.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Zudem hat die betroffene Person das Recht, aus Gründen, die sich aus ihrer besonderen
            Situation ergeben, gegen die sie betreffende Verarbeitung personenbezogener Daten, die
            bei der SKY HELI GMBH zu wissenschaftlichen oder historischen Forschungszwecken oder zu
            statistischen Zwecken gemäß Art. 89 Abs. 1 DS-GVO erfolgen, Widerspruch einzulegen, es
            sei denn, eine solche Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse
            liegenden Aufgabe erforderlich.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Zur Ausübung des Rechts auf Widerspruch kann sich die betroffene Person direkt jeden
            Mitarbeiter der SKY HELI GMBH oder einen anderen Mitarbeiter wenden. Der betroffenen
            Person steht es ferner frei, im Zusammenhang mit der Nutzung von Diensten der
            Informationsgesellschaft, ungeachtet der Richtlinie 2002/58/EG, ihr Widerspruchsrecht
            mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen
            verwendet werden.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            h) Automatisierte Entscheidungen im Einzelfall einschließlich Profiling
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
            Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, nicht einer
            ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling —
            beruhenden Entscheidung unterworfen zu werden, die ihr gegenüber rechtliche Wirkung
            entfaltet oder sie in ähnlicher Weise erheblich beeinträchtigt, sofern die Entscheidung
            (1) nicht für den Abschluss oder die Erfüllung eines Vertrags zwischen der betroffenen
            Person und dem Verantwortlichen erforderlich ist, oder (2) aufgrund von
            Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche
            unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung
            der Rechte und Freiheiten sowie der berechtigten Interessen der betroffenen Person
            enthalten oder (3) mit ausdrücklicher Einwilligung der betroffenen Person erfolgt.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Ist die Entscheidung (1) für den Abschluss oder die Erfüllung eines Vertrags zwischen
            der betroffenen Person und dem Verantwortlichen erforderlich oder (2) erfolgt sie mit
            ausdrücklicher Einwilligung der betroffenen Person, trifft die SKY HELI GMBH angemessene
            Maßnahmen, um die Rechte und Freiheiten sowie die berechtigten Interessen der
            betroffenen Person zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens
            einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf
            Anfechtung der Entscheidung gehört.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Möchte die betroffene Person Rechte mit Bezug auf automatisierte Entscheidungen geltend
            machen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
            Verantwortlichen wenden.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            i) Recht auf Widerruf einer datenschutzrechtlichen Einwilligung
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
            Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, eine Einwilligung zur
            Verarbeitung personenbezogener Daten jederzeit zu widerrufen.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Möchte die betroffene Person ihr Recht auf Widerruf einer Einwilligung geltend machen,
            kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
            Verantwortlichen wenden.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            11. Datenschutzbestimmungen zu Einsatz und Verwendung von AddThis
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite Komponenten des
            Unternehmens AddThis integriert. AddThis ist ein sogenannter Bookmarking-Provider. Der
            Dienst ermöglicht ein vereinfachtes Bookmarken von Internetseiten über Buttons. Durch
            ein Überfahren der AddThis-Komponente mit der Maus oder durch Anklicken mit dieser wird
            eine Liste mit Bookmarking- und Sharingservices angezeigt. AddThis ist auf über 15
            Millionen Internetseiten im Einsatz, und die Buttons werden nach den Angaben der
            Betreibergesellschaft über 20 Milliarden Mal jährlich angezeigt.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Betreibergesellschaft von AddThis ist die Firma AddThis, Inc. 1595 Spring Hill Road,
            Suite 300, Vienna, VA 22182, USA.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den für die
            Verarbeitung Verantwortlichen betrieben wird und auf welcher eine AddThis-Komponente
            integriert wurde, wird der Internetbrowser auf dem informationstechnologischen System
            der betroffenen Person automatisch durch die jeweilige AddThis-Komponente veranlasst,
            Daten von der Internetseite www.addthis.com herunterzuladen. Im Rahmen dieses
            technischen Verfahrens erhält AddThis Kenntnis über den Besuch und welche konkrete
            Einzelseite dieser Internetseite durch das von der betroffenen Person verwendete
            informationstechnologische System genutzt wird. Ferner erhält AddThis Kenntnis über die
            vom Internet-Service-Provider (ISP) vergebene IP-Adresse des von der betroffenen Person
            verwendeten Computersystems, den Browsertyp, die Browsersprache, die vor unserer
            Internetseite aufgerufene Internetseite, das Datum sowie die Uhrzeit des Besuchs unserer
            Internetseite. AddThis nutzt diese Daten, um anonymisierte Nutzerprofile zu erstellen.
            Die auf diesem Wege an AddThis übertragenen Daten und Informationen ermöglichen dem
            Unternehmen AddThis selbst sowie den mit AddThis verbundenen Unternehmen oder dessen
            Partner-Unternehmen, die Besucher der Internetseiten des für die Verarbeitung
            Verantwortlichen gezielt mit personalisierter und interessenbezogener Werbung
            anzusprechen.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            AddThis blendet personalisierte und interessenbezogene Werbung auf Basis eines durch das
            Unternehmen gesetzten Cookies ein. Dieses Cookie analysiert das individuelle
            Surfverhalten des von der betroffenen Person genutzten Computersystems. Das Cookie
            speichert die von dem Computersystem ausgehenden Besuche von Internetseiten.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite, wie oben
            bereits dargestellt, jederzeit mittels einer entsprechenden Einstellung des genutzten
            Internetbrowsers verhindern und damit der Setzung von Cookies dauerhaft widersprechen.
            Eine solche Einstellung des genutzten Internetbrowsers würde auch verhindern, dass
            AddThis ein Cookie auf dem informationstechnologischen System der betroffenen Person
            setzt. Zudem können von AddThis bereits gesetzte Cookies jederzeit über einen
            Internetbrowser oder andere Softwareprogramme gelöscht werden.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die betroffene Person hat zudem die Möglichkeit, der Verarbeitung von personenbezogenen
            Daten durch AddThis dauerhaft zu widersprechen. Hierzu muss die betroffene Person den
            Opt-Out-Button unter dem Link http://www.addthis.com/privacy/opt-out drücken, der einen
            Opt-Out-Cookie setzt. Der mit dem Widerspruch gesetzte Opt-Out-Cookie, wird auf dem von
            der betroffenen Person genutzten informationstechnologischen System abgelegt. Werden die
            Cookies auf dem System der betroffenen Person nach einem Widerspruch gelöscht, muss die
            betroffene Person den Link erneut aufrufen und einen neuen Opt-Out-Cookie setzen.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Mit der Setzung des Opt-Out-Cookies besteht jedoch die Möglichkeit, dass die
            Internetseiten des für die Verarbeitung Verantwortlichen für die betroffene Person nicht
            mehr vollumfänglich nutzbar sind.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die geltenden Datenschutzbestimmungen von AddThis können unter
            http://www.addthis.com/privacy/privacy-policy abgerufen werden.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            12. Datenschutzbestimmungen zu Einsatz und Verwendung von Facebook
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite Komponenten des
            Unternehmens Facebook integriert. Facebook ist ein soziales Netzwerk.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Ein soziales Netzwerk ist ein im Internet betriebener sozialer Treffpunkt, eine
            Online-Gemeinschaft, die es den Nutzern in der Regel ermöglicht, untereinander zu
            kommunizieren und im virtuellen Raum zu interagieren. Ein soziales Netzwerk kann als
            Plattform zum Austausch von Meinungen und Erfahrungen dienen oder ermöglicht es der
            Internetgemeinschaft, persönliche oder unternehmensbezogene Informationen
            bereitzustellen. Facebook ermöglicht den Nutzern des sozialen Netzwerkes unter anderem
            die Erstellung von privaten Profilen, den Upload von Fotos und eine Vernetzung über
            Freundschaftsanfragen.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Betreibergesellschaft von Facebook ist die Facebook, Inc., 1 Hacker Way, Menlo Park, CA
            94025, USA. Für die Verarbeitung personenbezogener Daten Verantwortlicher ist, wenn eine
            betroffene Person außerhalb der USA oder Kanada lebt, die Facebook Ireland Ltd., 4 Grand
            Canal Square, Grand Canal Harbour, Dublin 2, Ireland.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den für die
            Verarbeitung Verantwortlichen betrieben wird und auf welcher eine Facebook-Komponente
            (Facebook-Plug-In) integriert wurde, wird der Internetbrowser auf dem
            informationstechnologischen System der betroffenen Person automatisch durch die
            jeweilige Facebook-Komponente veranlasst, eine Darstellung der entsprechenden
            Facebook-Komponente von Facebook herunterzuladen. Eine Gesamtübersicht über alle
            Facebook-Plug-Ins kann unter{' '}
            <Link
              href="https://developers.facebook.com/docs/plugins/?locale=de_DE"
              target="_blank"
              color="textPrimary"
            >
              https://developers.facebook.com/docs/plugins/?locale=de_DE
            </Link>{' '}
            abgerufen werden. Im Rahmen dieses technischen Verfahrens erhält Facebook Kenntnis
            darüber, welche konkrete Unterseite unserer Internetseite durch die betroffene Person
            besucht wird.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Sofern die betroffene Person gleichzeitig bei Facebook eingeloggt ist, erkennt Facebook
            mit jedem Aufruf unserer Internetseite durch die betroffene Person und während der
            gesamten Dauer des jeweiligen Aufenthaltes auf unserer Internetseite, welche konkrete
            Unterseite unserer Internetseite die betroffene Person besucht. Diese Informationen
            werden durch die Facebook-Komponente gesammelt und durch Facebook dem jeweiligen
            Facebook-Account der betroffenen Person zugeordnet. Betätigt die betroffene Person einen
            der auf unserer Internetseite integrierten Facebook-Buttons, beispielsweise den „Gefällt
            mir“-Button, oder gibt die betroffene Person einen Kommentar ab, ordnet Facebook diese
            Information dem persönlichen Facebook-Benutzerkonto der betroffenen Person zu und
            speichert diese personenbezogenen Daten.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Facebook erhält über die Facebook-Komponente immer dann eine Information darüber, dass
            die betroffene Person unsere Internetseite besucht hat, wenn die betroffene Person zum
            Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei Facebook eingeloggt ist;
            dies findet unabhängig davon statt, ob die betroffene Person die Facebook-Komponente
            anklickt oder nicht. Ist eine derartige Übermittlung dieser Informationen an Facebook
            von der betroffenen Person nicht gewollt, kann diese die Übermittlung dadurch
            verhindern, dass sie sich vor einem Aufruf unserer Internetseite aus ihrem
            Facebook-Account ausloggt.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die von Facebook veröffentlichte Datenrichtlinie, die unter{' '}
            <Link
              href="https://de-de.facebook.com/about/privacy/"
              target="_blank"
              color="textPrimary"
            >
              https://de-de.facebook.com/about/privacy/
            </Link>{' '}
            abrufbar ist, gibt Aufschluss über die Erhebung, Verarbeitung und Nutzung
            personenbezogener Daten durch Facebook. Ferner wird dort erläutert, welche
            Einstellungsmöglichkeiten Facebook zum Schutz der Privatsphäre der betroffenen Person
            bietet. Zudem sind unterschiedliche Applikationen erhältlich, die es ermöglichen, eine
            Datenübermittlung an Facebook zu unterdrücken. Solche Applikationen können durch die
            betroffene Person genutzt werden, um eine Datenübermittlung an Facebook zu unterdrücken.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            13. Datenschutzbestimmungen zu Einsatz und Verwendung von Google AdSense
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite Google AdSense
            integriert. Google AdSense ist ein Online-Dienst, über welchen eine Vermittlung von
            Werbung auf Drittseiten ermöglicht wird. Google AdSense beruht auf einem Algorithmus,
            welcher die auf Drittseiten angezeigten Werbeanzeigen passend zu den Inhalten der
            jeweiligen Drittseite auswählt. Google AdSense gestattet ein interessenbezogenes
            Targeting des Internetnutzers, welches mittels Generierung von individuellen
            Benutzerprofilen umgesetzt wird.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Betreibergesellschaft der Google-AdSense-Komponente ist die Alphabet Inc., 1600
            Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Der Zweck der Google-AdSense-Komponente ist die Einbindung von Werbeanzeigen auf unserer
            Internetseite. Google-AdSense setzt ein Cookie auf dem informationstechnologischen
            System der betroffenen Person. Was Cookies sind, wurde oben bereits erläutert. Mit der
            Setzung des Cookies wird der Alphabet Inc. eine Analyse der Benutzung unserer
            Internetseite ermöglicht. Durch jeden Aufruf einer der Einzelseiten dieser
            Internetseite, die durch den für die Verarbeitung Verantwortlichen betrieben wird und
            auf welcher eine Google-AdSense-Komponente integriert wurde, wird der Internetbrowser
            auf dem informationstechnologischen System der betroffenen Person automatisch durch die
            jeweilige Google-AdSense-Komponente veranlasst, Daten zum Zwecke der Online-Werbung und
            der Abrechnung von Provisionen an die Alphabet Inc. zu übermitteln. Im Rahmen dieses
            technischen Verfahrens erhält die Alphabet Inc. Kenntnis über personenbezogene Daten,
            wie der IP-Adresse der betroffenen Person, die der Alphabet Inc. unter anderem dazu
            dienen, die Herkunft der Besucher und Klicks nachzuvollziehen und in der Folge
            Provisionsabrechnungen zu ermöglichen.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite, wie oben
            bereits dargestellt, jederzeit mittels einer entsprechenden Einstellung des genutzten
            Internetbrowsers verhindern und damit der Setzung von Cookies dauerhaft widersprechen.
            Eine solche Einstellung des genutzten Internetbrowsers würde auch verhindern, dass die
            Alphabet Inc. ein Cookie auf dem informationstechnologischen System der betroffenen
            Person setzt. Zudem kann ein von der Alphabet Inc. bereits gesetzter Cookie jederzeit
            über den Internetbrowser oder andere Softwareprogramme gelöscht werden.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Google AdSense verwendet zudem sogenannte Zählpixel. Ein Zählpixel ist eine
            Miniaturgrafik, die in Internetseiten eingebettet wird, um eine Logdatei-Aufzeichnung
            und eine Logdatei-Analyse zu ermöglichen, wodurch eine statistische Auswertung
            durchgeführt werden kann. Anhand des eingebetteten Zählpixels kann die Alphabet Inc.
            erkennen, ob und wann eine Internetseite von einer betroffenen Person geöffnet wurde und
            welche Links von der betroffenen Person angeklickt wurden. Zählpixel dienen unter
            anderem dazu, den Besucherfluss einer Internetseite auszuwerten.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Über Google AdSense werden personenbezogene Daten und Informationen, was auch die
            IP-Adresse umfasst und zur Erfassung und Abrechnung der angezeigten Werbeanzeigen
            notwendig ist, an die Alphabet Inc. in die Vereinigten Staaten von Amerika übertragen.
            Diese personenbezogenen Daten werden in den Vereinigten Staaten von Amerika gespeichert
            und verarbeitet. Die Alphabet Inc. gibt diese über das technische Verfahren erhobenen
            personenbezogenen Daten unter Umständen an Dritte weiter.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Google-AdSense wird unter diesem Link{' '}
            <Link
              href="https://www.google.de/intl/de/adsense/start/"
              target="_blank"
              color="textPrimary"
            >
              https://www.google.de/intl/de/adsense/start/
            </Link>{' '}
            genauer erläutert.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            14. Datenschutzbestimmungen zu Einsatz und Verwendung von Google Analytics (mit
            Anonymisierungsfunktion)
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite die Komponente
            Google Analytics (mit Anonymisierungsfunktion) integriert. Google Analytics ist ein
            Web-Analyse-Dienst. Web-Analyse ist die Erhebung, Sammlung und Auswertung von Daten über
            das Verhalten von Besuchern von Internetseiten. Ein Web-Analyse-Dienst erfasst unter
            anderem Daten darüber, von welcher Internetseite eine betroffene Person auf eine
            Internetseite gekommen ist (sogenannte Referrer), auf welche Unterseiten der
            Internetseite zugegriffen oder wie oft und für welche Verweildauer eine Unterseite
            betrachtet wurde. Eine Web-Analyse wird überwiegend zur Optimierung einer Internetseite
            und zur Kosten-Nutzen-Analyse von Internetwerbung eingesetzt.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Betreibergesellschaft der Google-Analytics-Komponente ist die Google Inc., 1600
            Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Der für die Verarbeitung Verantwortliche verwendet für die Web-Analyse über Google
            Analytics den Zusatz "_gat._anonymizeIp". Mittels dieses Zusatzes wird die IP-Adresse
            des Internetanschlusses der betroffenen Person von Google gekürzt und anonymisiert, wenn
            der Zugriff auf unsere Internetseiten aus einem Mitgliedstaat der Europäischen Union
            oder aus einem anderen Vertragsstaat des Abkommens über den Europäischen Wirtschaftsraum
            erfolgt.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Der Zweck der Google-Analytics-Komponente ist die Analyse der Besucherströme auf unserer
            Internetseite. Google nutzt die gewonnenen Daten und Informationen unter anderem dazu,
            die Nutzung unserer Internetseite auszuwerten, um für uns Online-Reports, welche die
            Aktivitäten auf unseren Internetseiten aufzeigen, zusammenzustellen, und um weitere mit
            der Nutzung unserer Internetseite in Verbindung stehende Dienstleistungen zu erbringen.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Google Analytics setzt ein Cookie auf dem informationstechnologischen System der
            betroffenen Person. Was Cookies sind, wurde oben bereits erläutert. Mit Setzung des
            Cookies wird Google eine Analyse der Benutzung unserer Internetseite ermöglicht. Durch
            jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den für die
            Verarbeitung Verantwortlichen betrieben wird und auf welcher eine
            Google-Analytics-Komponente integriert wurde, wird der Internetbrowser auf dem
            informationstechnologischen System der betroffenen Person automatisch durch die
            jeweilige Google-Analytics-Komponente veranlasst, Daten zum Zwecke der Online-Analyse an
            Google zu übermitteln. Im Rahmen dieses technischen Verfahrens erhält Google Kenntnis
            über personenbezogene Daten, wie der IP-Adresse der betroffenen Person, die Google unter
            anderem dazu dienen, die Herkunft der Besucher und Klicks nachzuvollziehen und in der
            Folge Provisionsabrechnungen zu ermöglichen.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Mittels des Cookies werden personenbezogene Informationen, beispielsweise die
            Zugriffszeit, der Ort, von welchem ein Zugriff ausging und die Häufigkeit der Besuche
            unserer Internetseite durch die betroffene Person, gespeichert. Bei jedem Besuch unserer
            Internetseiten werden diese personenbezogenen Daten, einschließlich der IP-Adresse des
            von der betroffenen Person genutzten Internetanschlusses, an Google in den Vereinigten
            Staaten von Amerika übertragen. Diese personenbezogenen Daten werden durch Google in den
            Vereinigten Staaten von Amerika gespeichert. Google gibt diese über das technische
            Verfahren erhobenen personenbezogenen Daten unter Umständen an Dritte weiter.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite, wie oben
            bereits dargestellt, jederzeit mittels einer entsprechenden Einstellung des genutzten
            Internetbrowsers verhindern und damit der Setzung von Cookies dauerhaft widersprechen.
            Eine solche Einstellung des genutzten Internetbrowsers würde auch verhindern, dass
            Google ein Cookie auf dem informationstechnologischen System der betroffenen Person
            setzt. Zudem kann ein von Google Analytics bereits gesetzter Cookie jederzeit über den
            Internetbrowser oder andere Softwareprogramme gelöscht werden.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Ferner besteht für die betroffene Person die Möglichkeit, einer Erfassung der durch
            Google Analytics erzeugten, auf eine Nutzung dieser Internetseite bezogenen Daten sowie
            der Verarbeitung dieser Daten durch Google zu widersprechen und eine solche zu
            verhindern. Hierzu muss die betroffene Person ein Browser-Add-On unter dem Link{' '}
            <Link
              href="https://tools.google.com/dlpage/gaoptout"
              target="_blank"
              color="textPrimary"
            >
              https://tools.google.com/dlpage/gaoptout
            </Link>{' '}
            herunterladen und installieren. Dieses Browser-Add-On teilt Google Analytics über
            JavaScript mit, dass keine Daten und Informationen zu den Besuchen von Internetseiten an
            Google Analytics übermittelt werden dürfen. Die Installation des Browser-Add-Ons wird
            von Google als Widerspruch gewertet. Wird das informationstechnologische System der
            betroffenen Person zu einem späteren Zeitpunkt gelöscht, formatiert oder neu
            installiert, muss durch die betroffene Person eine erneute Installation des
            Browser-Add-Ons erfolgen, um Google Analytics zu deaktivieren. Sofern das Browser-Add-On
            durch die betroffene Person oder einer anderen Person, die ihrem Machtbereich
            zuzurechnen ist, deinstalliert oder deaktiviert wird, besteht die Möglichkeit der
            Neuinstallation oder der erneuten Aktivierung des Browser-Add-Ons.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Weitere Informationen und die geltenden Datenschutzbestimmungen von Google können unter{' '}
            <Link
              href="https://www.google.de/intl/de/policies/privacy/"
              target="_blank"
              color="textPrimary"
            >
              https://www.google.de/intl/de/policies/privacy/
            </Link>{' '}
            und unter{' '}
            <Link
              href="http://www.google.com/analytics/terms/de.html"
              target="_blank"
              color="textPrimary"
            >
              http://www.google.com/analytics/terms/de.html
            </Link>{' '}
            abgerufen werden. Google Analytics wird unter diesem Link{' '}
            <Link
              href="https://www.google.com/intl/de_de/analytics/"
              target="_blank"
              color="textPrimary"
            >
              https://www.google.com/intl/de_de/analytics/
            </Link>{' '}
            genauer erläutert.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            15. Datenschutzbestimmungen zu Einsatz und Verwendung von Google+
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite als Komponente die
            Google+ Schaltfläche integriert. Google+ ist ein sogenanntes soziales Netzwerk. Ein
            soziales Netzwerk ist ein im Internet betriebener sozialer Treffpunkt, eine
            Online-Gemeinschaft, die es den Nutzern in der Regel ermöglicht, untereinander zu
            kommunizieren und im virtuellen Raum zu interagieren. Ein soziales Netzwerk kann als
            Plattform zum Austausch von Meinungen und Erfahrungen dienen oder ermöglicht es der
            Internetgemeinschaft, persönliche oder unternehmensbezogene Informationen
            bereitzustellen. Google+ ermöglicht den Nutzern des sozialen Netzwerkes unter anderem
            die Erstellung von privaten Profilen, den Upload von Fotos und eine Vernetzung über
            Freundschaftsanfragen.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Betreibergesellschaft von Google+ ist die Google Inc., 1600 Amphitheatre Pkwy, Mountain
            View, CA 94043-1351, USA.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den für die
            Verarbeitung Verantwortlichen betrieben wird und auf welcher eine Google+ Schaltfläche
            integriert wurde, wird der Internetbrowser auf dem informationstechnologischen System
            der betroffenen Person automatisch durch die jeweilige Google+ Schaltfläche veranlasst,
            eine Darstellung der entsprechenden Google+ Schaltfläche von Google herunterzuladen. Im
            Rahmen dieses technischen Verfahrens erhält Google Kenntnis darüber, welche konkrete
            Unterseite unserer Internetseite durch die betroffene Person besucht wird. Genauere
            Informationen zu Google+ sind unter{' '}
            <Link href="https://developers.google.com/+/" target="_blank" color="textPrimary">
              https://developers.google.com/+/
            </Link>{' '}
            abrufbar.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Sofern die betroffene Person gleichzeitig bei Google+ eingeloggt ist, erkennt Google mit
            jedem Aufruf unserer Internetseite durch die betroffene Person und während der gesamten
            Dauer des jeweiligen Aufenthaltes auf unserer Internetseite, welche konkrete Unterseite
            unserer Internetseite die betroffene Person besucht. Diese Informationen werden durch
            die Google+ Schaltfläche gesammelt und durch Google dem jeweiligen Google+-Account der
            betroffenen Person zugeordnet.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Betätigt die betroffene Person einen der auf unserer Internetseite integrierten
            Google+-Buttons und gibt damit eine Google+1 Empfehlung ab, ordnet Google diese
            Information dem persönlichen Google+-Benutzerkonto der betroffenen Person zu und
            speichert diese personenbezogenen Daten. Google speichert die Google+1-Empfehlung der
            betroffenen Person und macht diese in Übereinstimmung mit den von der betroffenen Person
            diesbezüglich akzeptierten Bedingungen öffentlich zugänglich. Eine von der betroffenen
            Person auf dieser Internetseite abgegebene Google+1-Empfehlung wird in der Folge
            zusammen mit anderen personenbezogenen Daten, wie dem Namen des von der betroffenen
            Person genutzten Google+1-Accounts und dem in diesem hinterlegten Foto in anderen
            Google-Diensten, beispielsweise den Suchmaschinenergebnissen der Google-Suchmaschine,
            dem Google-Konto der betroffenen Person oder an sonstigen Stellen, beispielsweise auf
            Internetseiten oder im Zusammenhang mit Werbeanzeigen, gespeichert und verarbeitet.
            Ferner ist Google in der Lage, den Besuch auf dieser Internetseite mit anderen bei
            Google gespeicherten personenbezogenen Daten zu verknüpfen. Google zeichnet diese
            personenbezogenen Informationen ferner mit dem Zweck auf, die unterschiedlichen Dienste
            von Google zu verbessern oder zu optimieren.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Google erhält über die Google+-Schaltfläche immer dann eine Information darüber, dass
            die betroffene Person unsere Internetseite besucht hat, wenn die betroffene Person zum
            Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei Google+ eingeloggt ist;
            dies findet unabhängig davon statt, ob die betroffene Person die Google+-Schaltfläche
            anklickt oder nicht.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Ist eine Übermittlung personenbezogener Daten an Google von der betroffenen Person nicht
            gewollt, kann diese eine solche Übermittlung dadurch verhindern, dass sie sich vor einem
            Aufruf unserer Internetseite aus ihrem Google+-Account ausloggt.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Weitere Informationen und die geltenden Datenschutzbestimmungen von Google können unter{' '}
            <Link
              href="https://www.google.de/intl/de/policies/privacy/"
              target="_blank"
              color="textPrimary"
            >
              https://www.google.de/intl/de/policies/privacy/
            </Link>{' '}
            abgerufen werden. Weitere Hinweise von Google zur Google+1-Schaltfläche können unter{' '}
            <Link
              href="https://developers.google.com/+/web/buttons-policy"
              target="_blank"
              color="textPrimary"
            >
              https://developers.google.com/+/web/buttons-policy
            </Link>{' '}
            abgerufen werden.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            16. Datenschutzbestimmungen zu Einsatz und Verwendung von Google-AdWords
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite Google AdWords
            integriert. Google AdWords ist ein Dienst zur Internetwerbung, der es Werbetreibenden
            gestattet, sowohl Anzeigen in den Suchmaschinenergebnissen von Google als auch im
            Google-Werbenetzwerk zu schalten. Google AdWords ermöglicht es einem Werbetreibenden,
            vorab bestimmte Schlüsselwörter festzulegen, mittels derer eine Anzeige in den
            Suchmaschinenergebnissen von Google ausschließlich dann angezeigt wird, wenn der Nutzer
            mit der Suchmaschine ein schlüsselwortrelevantes Suchergebnis abruft. Im
            Google-Werbenetzwerk werden die Anzeigen mittels eines automatischen Algorithmus und
            unter Beachtung der zuvor festgelegten Schlüsselwörter auf themenrelevanten
            Internetseiten verteilt.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Betreibergesellschaft der Dienste von Google AdWords ist die Google Inc., 1600
            Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Der Zweck von Google AdWords ist die Bewerbung unserer Internetseite durch die
            Einblendung von interessenrelevanter Werbung auf den Internetseiten von Drittunternehmen
            und in den Suchmaschinenergebnissen der Suchmaschine Google und eine Einblendung von
            Fremdwerbung auf unserer Internetseite.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Gelangt eine betroffene Person über eine Google-Anzeige auf unsere Internetseite, wird
            auf dem informationstechnologischen System der betroffenen Person durch Google ein
            sogenannter Conversion-Cookie abgelegt. Was Cookies sind, wurde oben bereits erläutert.
            Ein Conversion-Cookie verliert nach dreißig Tagen seine Gültigkeit und dient nicht zur
            Identifikation der betroffenen Person. Über den Conversion-Cookie wird, sofern das
            Cookie noch nicht abgelaufen ist, nachvollzogen, ob bestimmte Unterseiten,
            beispielsweise der Warenkorb von einem Online-Shop-System, auf unserer Internetseite
            aufgerufen wurden. Durch den Conversion-Cookie können sowohl wir als auch Google
            nachvollziehen, ob eine betroffene Person, die über eine AdWords-Anzeige auf unsere
            Internetseite gelangt ist, einen Umsatz generierte, also einen Warenkauf vollzogen oder
            abgebrochen hat.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die durch die Nutzung des Conversion-Cookies erhobenen Daten und Informationen werden
            von Google verwendet, um Besuchsstatistiken für unsere Internetseite zu erstellen. Diese
            Besuchsstatistiken werden durch uns wiederum genutzt, um die Gesamtanzahl der Nutzer zu
            ermitteln, welche über AdWords-Anzeigen an uns vermittelt wurden, also um den Erfolg
            oder Misserfolg der jeweiligen AdWords-Anzeige zu ermitteln und um unsere
            AdWords-Anzeigen für die Zukunft zu optimieren. Weder unser Unternehmen noch andere
            Werbekunden von Google-AdWords erhalten Informationen von Google, mittels derer die
            betroffene Person identifiziert werden könnte.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Mittels des Conversion-Cookies werden personenbezogene Informationen, beispielsweise die
            durch die betroffene Person besuchten Internetseiten, gespeichert. Bei jedem Besuch
            unserer Internetseiten werden demnach personenbezogene Daten, einschließlich der
            IP-Adresse des von der betroffenen Person genutzten Internetanschlusses, an Google in
            den Vereinigten Staaten von Amerika übertragen. Diese personenbezogenen Daten werden
            durch Google in den Vereinigten Staaten von Amerika gespeichert. Google gibt diese über
            das technische Verfahren erhobenen personenbezogenen Daten unter Umständen an Dritte
            weiter.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite, wie oben
            bereits dargestellt, jederzeit mittels einer entsprechenden Einstellung des genutzten
            Internetbrowsers verhindern und damit der Setzung von Cookies dauerhaft widersprechen.
            Eine solche Einstellung des genutzten Internetbrowsers würde auch verhindern, dass
            Google einen Conversion-Cookie auf dem informationstechnologischen System der
            betroffenen Person setzt. Zudem kann ein von Google AdWords bereits gesetzter Cookie
            jederzeit über den Internetbrowser oder andere Softwareprogramme gelöscht werden.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Ferner besteht für die betroffene Person die Möglichkeit, der interessenbezogenen
            Werbung durch Google zu widersprechen. Hierzu muss die betroffene Person von jedem der
            von ihr genutzten Internetbrowser aus den Link www.google.de/settings/ads aufrufen und
            dort die gewünschten Einstellungen vornehmen.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Weitere Informationen und die geltenden Datenschutzbestimmungen von Google können unter{' '}
            <Link
              href="https://www.google.de/intl/de/policies/privacy/"
              target="_blank"
              color="textPrimary"
            >
              https://www.google.de/intl/de/policies/privacy/
            </Link>{' '}
            abgerufen werden.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            17. Datenschutzbestimmungen zu Einsatz und Verwendung von Instagram
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite Komponenten des
            Dienstes Instagram integriert. Instagram ist ein Dienst, der als audiovisuelle Plattform
            zu qualifizieren ist und den Nutzern das Teilen von Fotos und Videos und zudem eine
            Weiterverbreitung solcher Daten in anderen sozialen Netzwerken ermöglicht.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Betreibergesellschaft der Dienste von Instagram ist die Instagram LLC, 1 Hacker Way,
            Building 14 First Floor, Menlo Park, CA, USA.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den für die
            Verarbeitung Verantwortlichen betrieben wird und auf welcher eine Instagram-Komponente
            (Insta-Button) integriert wurde, wird der Internetbrowser auf dem
            informationstechnologischen System der betroffenen Person automatisch durch die
            jeweilige Instagram-Komponente veranlasst, eine Darstellung der entsprechenden
            Komponente von Instagram herunterzuladen. Im Rahmen dieses technischen Verfahrens erhält
            Instagram Kenntnis darüber, welche konkrete Unterseite unserer Internetseite durch die
            betroffene Person besucht wird.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Sofern die betroffene Person gleichzeitig bei Instagram eingeloggt ist, erkennt
            Instagram mit jedem Aufruf unserer Internetseite durch die betroffene Person und während
            der gesamten Dauer des jeweiligen Aufenthaltes auf unserer Internetseite, welche
            konkrete Unterseite die betroffene Person besucht. Diese Informationen werden durch die
            Instagram-Komponente gesammelt und durch Instagram dem jeweiligen Instagram-Account der
            betroffenen Person zugeordnet. Betätigt die betroffene Person einen der auf unserer
            Internetseite integrierten Instagram-Buttons, werden die damit übertragenen Daten und
            Informationen dem persönlichen Instagram-Benutzerkonto der betroffenen Person zugeordnet
            und von Instagram gespeichert und verarbeitet.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Instagram erhält über die Instagram-Komponente immer dann eine Information darüber, dass
            die betroffene Person unsere Internetseite besucht hat, wenn die betroffene Person zum
            Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei Instagram eingeloggt ist;
            dies findet unabhängig davon statt, ob die betroffene Person die Instagram-Komponente
            anklickt oder nicht. Ist eine derartige Übermittlung dieser Informationen an Instagram
            von der betroffenen Person nicht gewollt, kann diese die Übermittlung dadurch
            verhindern, dass sie sich vor einem Aufruf unserer Internetseite aus ihrem
            Instagram-Account ausloggt.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Weitere Informationen und die geltenden Datenschutzbestimmungen von Instagram können
            unter{' '}
            <Link
              href="https://help.instagram.com/155833707900388"
              target="_blank"
              color="textPrimary"
            >
              https://help.instagram.com/155833707900388
            </Link>{' '}
            und
            <Link
              href="https://www.instagram.com/about/legal/privacy/"
              target="_blank"
              color="textPrimary"
            >
              https://www.instagram.com/about/legal/privacy/
            </Link>{' '}
            abgerufen werden.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            18. Datenschutzbestimmungen zu Einsatz und Verwendung von SlideShare
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite
            SlideShare-Komponenten integriert. LinkedIn SlideShare ermöglicht als Filehosting-Dienst
            das austauschen und archivieren von Präsentationen und anderen Dokumenten wie
            PDF-Dateien, Videos und Webinaren. Der Filehosting-Dienst gestattet den Benutzern den
            Upload von Medieninhalten in allen gängigen Formaten, wobei die Dokumente entweder
            öffentlich zugänglich gemacht oder durch eine Privatmarkierung versehen werden können.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Betreibergesellschaft von SlideShare ist die LinkedIn Corporation, 2029 Stierlin Court
            Mountain View, CA 94043, USA. Für Datenschutzangelegenheiten außerhalb der USA ist die
            LinkedIn Ireland, Privacy Policy Issues, Wilton Plaza, Wilton Place, Dublin 2, Irland,
            zuständig.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            LinkedIn SlideShare stellt sogenannte Embed-Codes für die dort abgelegten Medieninhalte
            (Präsentationen, PDF-Dateien, Videos, Fotos, etc.) zur Verfügung. Embed-Codes sind
            Programmcodes, die mit dem Ziel in Internetseiten eingebettet werden, externe Inhalte
            auf der eigenen Internetseite anzuzeigen. Embed-Codes ermöglichen es, Inhalte auf einer
            eigenen Internetseite wiederzugeben, ohne solche auf dem eigenen Server abzulegen und
            hierbei möglicherweise das Vervielfältigungsrecht des jeweiligen Urhebers des Inhalts zu
            verletzen. Weiterer Vorteil der Verwendung eines Embed-Codes ist, dass der jeweilige
            Betreiber einer Internetseite keinen eigenen Speicherplatz nutzt und der eigene Server
            hierdurch entlastet wird. Ein Embed-Code kann an jeder Stelle einer anderen
            Internetseite eingebunden werden, sodass ein externer Inhalt auch innerhalb des eigenen
            Textes eingefügt werden kann. Zweck der Nutzung von LinkedIn SlideShare ist die
            Entlastung unseres Servers sowie eine Vermeidung von Urheberrechtsverstößen bei
            gleichzeitiger Nutzung fremder Inhalte.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Bei jedem Abruf unserer Internetseite, die mit einer SlideShare-Komponente (Embed-Codes)
            ausgestattet ist, veranlasst diese Komponente, dass der von ihnen verwendete Browser
            entsprechend eingebettete Daten von SlideShare herunterlädt. Im Rahmen dieses
            technischen Verfahrens erhält LinkedIn Kenntnis darüber, welche konkrete Unterseite
            unserer Internetseite durch die betroffene Person besucht wird.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Sofern die betroffene Person gleichzeitig bei SlideShare eingeloggt ist, erkennt
            SlideShare mit jedem Aufruf unserer Internetseite durch die betroffene Person und
            während der gesamten Dauer des jeweiligen Aufenthaltes auf unserer Internetseite, welche
            konkrete Unterseite die betroffene Person besucht. Diese Informationen werden durch
            SlideShare gesammelt und durch LinkedIn dem jeweiligen SlideShare-Account der
            betroffenen Person zugeordnet.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            LinkedIn erhält über die SlideShare-Komponente immer dann eine Information darüber, dass
            die betroffene Person unsere Internetseite besucht hat, wenn die betroffene Person zum
            Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei SlideShare eingeloggt ist;
            dies findet unabhängig davon statt, ob die betroffene Person die eingebundenen
            Mediendaten anklickt oder nicht. Ist eine derartige Übermittlung dieser Informationen an
            LinkedIn von der betroffenen Person nicht gewollt, kann diese die Übermittlung dadurch
            verhindern, dass sie sich vor einem Aufruf unserer Internetseite aus ihrem
            SlideShare-Account ausloggt.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            LinkedIn nutzt ferner Partner wie Quantcast, Google Analytics, BlueKai, DoubleClick,
            Nielsen, Comscore, Eloqua und Lotame, die Cookies setzen können. Solche Cookies können
            unter{' '}
            <Link
              href="https://www.linkedin.com/legal/cookie-policy"
              target="_blank"
              color="textPrimary"
            >
              https://www.linkedin.com/legal/cookie-policy
            </Link>{' '}
            abgelehnt werden. Die geltenden Datenschutzbestimmungen von LinkedIn sind unter{' '}
            <Link
              href="https://www.linkedin.com/legal/privacy-policy"
              target="_blank"
              color="textPrimary"
            >
              https://www.linkedin.com/legal/privacy-policy
            </Link>{' '}
            abrufbar.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            19. Datenschutzbestimmungen zu Einsatz und Verwendung von Twitter
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite Komponenten von
            Twitter integriert. Twitter ist ein multilingualer öffentlich zugänglicher
            Mikroblogging-Dienst, auf welchem die Nutzer sogenannte Tweets, also Kurznachrichten,
            die auf 280 Zeichen begrenzt sind, veröffentlichen und verbreiten können. Diese
            Kurznachrichten sind für jedermann, also auch für nicht bei Twitter angemeldete Personen
            abrufbar. Die Tweets werden aber auch den sogenannten Followern des jeweiligen Nutzers
            angezeigt. Follower sind andere Twitter-Nutzer, die den Tweets eines Nutzers folgen.
            Ferner ermöglicht Twitter über Hashtags, Verlinkungen oder Retweets die Ansprache eines
            breiten Publikums.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Betreibergesellschaft von Twitter ist die Twitter, Inc., 1355 Market Street, Suite 900,
            San Francisco, CA 94103, USA.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den für die
            Verarbeitung Verantwortlichen betrieben wird und auf welcher eine Twitter-Komponente
            (Twitter-Button) integriert wurde, wird der Internetbrowser auf dem
            informationstechnologischen System der betroffenen Person automatisch durch die
            jeweilige Twitter-Komponente veranlasst, eine Darstellung der entsprechenden
            Twitter-Komponente von Twitter herunterzuladen. Weitere Informationen zu den
            Twitter-Buttons sind unter{' '}
            <Link
              href="https://about.twitter.com/de/resources/buttons"
              target="_blank"
              color="textPrimary"
            >
              https://about.twitter.com/de/resources/buttons
            </Link>{' '}
            abrufbar. Im Rahmen dieses technischen Verfahrens erhält Twitter Kenntnis darüber,
            welche konkrete Unterseite unserer Internetseite durch die betroffene Person besucht
            wird. Zweck der Integration der Twitter-Komponente ist es, unseren Nutzern eine
            Weiterverbreitung der Inhalte diese Internetseite zu ermöglichen, diese Internetseite in
            der digitalen Welt bekannt zu machen und unsere Besucherzahlen zu erhöhen.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Sofern die betroffene Person gleichzeitig bei Twitter eingeloggt ist, erkennt Twitter
            mit jedem Aufruf unserer Internetseite durch die betroffene Person und während der
            gesamten Dauer des jeweiligen Aufenthaltes auf unserer Internetseite, welche konkrete
            Unterseite unserer Internetseite die betroffene Person besucht. Diese Informationen
            werden durch die Twitter-Komponente gesammelt und durch Twitter dem jeweiligen
            Twitter-Account der betroffenen Person zugeordnet. Betätigt die betroffene Person einen
            der auf unserer Internetseite integrierten Twitter-Buttons, werden die damit
            übertragenen Daten und Informationen dem persönlichen Twitter-Benutzerkonto der
            betroffenen Person zugeordnet und von Twitter gespeichert und verarbeitet.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Twitter erhält über die Twitter-Komponente immer dann eine Information darüber, dass die
            betroffene Person unsere Internetseite besucht hat, wenn die betroffene Person zum
            Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei Twitter eingeloggt ist;
            dies findet unabhängig davon statt, ob die betroffene Person die Twitter-Komponente
            anklickt oder nicht. Ist eine derartige Übermittlung dieser Informationen an Twitter von
            der betroffenen Person nicht gewollt, kann diese die Übermittlung dadurch verhindern,
            dass sie sich vor einem Aufruf unserer Internetseite aus ihrem Twitter-Account ausloggt.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die geltenden Datenschutzbestimmungen von Twitter sind unter{' '}
            <Link href="https://twitter.com/privacy?lang=de" target="_blank" color="textPrimary">
              https://twitter.com/privacy?lang=de
            </Link>{' '}
            abrufbar.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            20. Datenschutzbestimmungen zu Einsatz und Verwendung von Xing
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite Komponenten von
            Xing integriert. Xing ist ein Internetbasiertes soziales Netzwerk, das die Konnektierung
            der Nutzer mit bestehenden Geschäftskontakten sowie das Knüpfen von neuen
            Businesskontakten ermöglicht. Die einzelnen Nutzer können bei Xing ein persönliches
            Profil von sich anlegen. Unternehmen können beispielsweise Unternehmensprofile erstellen
            oder Stellenangebote auf Xing veröffentlichen.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Betreibergesellschaft von Xing ist die XING SE, Dammtorstraße 30, 20354 Hamburg,
            Deutschland.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den für die
            Verarbeitung Verantwortlichen betrieben wird und auf welcher eine Xing-Komponente
            (Xing-Plug-In) integriert wurde, wird der Internetbrowser auf dem
            informationstechnologischen System der betroffenen Person automatisch durch die
            jeweilige Xing-Komponente veranlasst, eine Darstellung der entsprechenden
            Xing-Komponente von Xing herunterzuladen. Weitere Informationen zum den Xing-Plug-Ins
            können unter{' '}
            <Link href="https://dev.xing.com/plugins" target="_blank" color="textPrimary">
              https://dev.xing.com/plugins
            </Link>{' '}
            abgerufen werden. Im Rahmen dieses technischen Verfahrens erhält Xing Kenntnis darüber,
            welche konkrete Unterseite unserer Internetseite durch die betroffene Person besucht
            wird.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Sofern die betroffene Person gleichzeitig bei Xing eingeloggt ist, erkennt Xing mit
            jedem Aufruf unserer Internetseite durch die betroffene Person und während der gesamten
            Dauer des jeweiligen Aufenthaltes auf unserer Internetseite, welche konkrete Unterseite
            unserer Internetseite die betroffene Person besucht. Diese Informationen werden durch
            die Xing-Komponente gesammelt und durch Xing dem jeweiligen Xing-Account der betroffenen
            Person zugeordnet. Betätigt die betroffene Person einen der auf unserer Internetseite
            integrierten Xing-Buttons, beispielsweise den „Share“-Button, ordnet Xing diese
            Information dem persönlichen Xing-Benutzerkonto der betroffenen Person zu und speichert
            diese personenbezogenen Daten.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Xing erhält über die Xing-Komponente immer dann eine Information darüber, dass die
            betroffene Person unsere Internetseite besucht hat, wenn die betroffene Person zum
            Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei Xing eingeloggt ist; dies
            findet unabhängig davon statt, ob die betroffene Person die Xing-Komponente anklickt
            oder nicht. Ist eine derartige Übermittlung dieser Informationen an Xing von der
            betroffenen Person nicht gewollt, kann diese die Übermittlung dadurch verhindern, dass
            sie sich vor einem Aufruf unserer Internetseite aus ihrem Xing-Account ausloggt.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die von Xing veröffentlichten Datenschutzbestimmungen, die unter{' '}
            <Link href="https://www.xing.com/privacy" target="_blank" color="textPrimary">
              https://www.xing.com/privacy
            </Link>{' '}
            abrufbar sind, geben Aufschluss über die Erhebung, Verarbeitung und Nutzung
            personenbezogener Daten durch Xing. Ferner hat Xing unter{' '}
            <Link
              href="https://www.xing.com/app/share?op=data_protection"
              target="_blank"
              color="textPrimary"
            >
              https://www.xing.com/app/share?op=data_protection
            </Link>{' '}
            Datenschutzhinweise für den XING-Share-Button veröffentlicht.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            21. Datenschutzbestimmungen zu Einsatz und Verwendung von YouTube
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite Komponenten von
            YouTube integriert. YouTube ist ein Internet-Videoportal, dass Video-Publishern das
            kostenlose Einstellen von Videoclips und anderen Nutzern die ebenfalls kostenfreie
            Betrachtung, Bewertung und Kommentierung dieser ermöglicht. YouTube gestattet die
            Publikation aller Arten von Videos, weshalb sowohl komplette Film- und Fernsehsendungen,
            aber auch Musikvideos, Trailer oder von Nutzern selbst angefertigte Videos über das
            Internetportal abrufbar sind.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Betreibergesellschaft von YouTube ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA
            94066, USA. Die YouTube, LLC ist einer Tochtergesellschaft der Google Inc., 1600
            Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den für die
            Verarbeitung Verantwortlichen betrieben wird und auf welcher eine YouTube-Komponente
            (YouTube-Video) integriert wurde, wird der Internetbrowser auf dem
            informationstechnologischen System der betroffenen Person automatisch durch die
            jeweilige YouTube-Komponente veranlasst, eine Darstellung der entsprechenden
            YouTube-Komponente von YouTube herunterzuladen. Weitere Informationen zu YouTube können
            unter{' '}
            <Link href="https://www.youtube.com/yt/about/de/" target="_blank" color="textPrimary">
              https://www.youtube.com/yt/about/de/
            </Link>{' '}
            abgerufen werden. Im Rahmen dieses technischen Verfahrens erhalten YouTube und Google
            Kenntnis darüber, welche konkrete Unterseite unserer Internetseite durch die betroffene
            Person besucht wird.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Sofern die betroffene Person gleichzeitig bei YouTube eingeloggt ist, erkennt YouTube
            mit dem Aufruf einer Unterseite, die ein YouTube-Video enthält, welche konkrete
            Unterseite unserer Internetseite die betroffene Person besucht. Diese Informationen
            werden durch YouTube und Google gesammelt und dem jeweiligen YouTube-Account der
            betroffenen Person zugeordnet.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            YouTube und Google erhalten über die YouTube-Komponente immer dann eine Information
            darüber, dass die betroffene Person unsere Internetseite besucht hat, wenn die
            betroffene Person zum Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei
            YouTube eingeloggt ist; dies findet unabhängig davon statt, ob die betroffene Person ein
            YouTube-Video anklickt oder nicht. Ist eine derartige Übermittlung dieser Informationen
            an YouTube und Google von der betroffenen Person nicht gewollt, kann diese die
            Übermittlung dadurch verhindern, dass sie sich vor einem Aufruf unserer Internetseite
            aus ihrem YouTube-Account ausloggt.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die von YouTube veröffentlichten Datenschutzbestimmungen, die unter{' '}
            <Link
              href="https://www.google.de/intl/de/policies/privacy/"
              target="_blank"
              color="textPrimary"
            >
              https://www.google.de/intl/de/policies/privacy/
            </Link>{' '}
            abrufbar sind, geben Aufschluss über die Erhebung, Verarbeitung und Nutzung
            personenbezogener Daten durch YouTube und Google.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            22. Zahlungsart: Datenschutzbestimmungen zu Klarna als Zahlungsart
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite Komponenten von
            Klarna integriert. Klarna ist ein Online-Zahlungsdienstleister, der den Kauf auf
            Rechnung oder eine flexible Ratenzahlung ermöglicht. Ferner werden von Klarna weitere
            Services, wie beispielsweise ein Käuferschutz oder eine Identitäts- und Bonitätsprüfung,
            angeboten.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Betreibergesellschaft von Klarna ist die Klarna AB, Sveavägen 46, 111 34 Stockholm,
            Schweden.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Wählt die betroffene Person während des Bestellvorgangs in unserem Online-Shop als
            Zahlungsmöglichkeit entweder den „Kauf auf Rechnung“ oder „Ratenkauf“ aus, werden
            automatisiert Daten der betroffenen Person an Klarna übermittelt. Mit der Auswahl einer
            dieser Zahlungsoptionen willigt die betroffene Person in diese, zur Abwicklung des
            Rechnungs- oder Ratenkaufes oder zur Identitäts- und Bonitätsprüfung erforderliche,
            Übermittlung personenbezogener Daten ein.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Bei den an Klarna übermittelten personenbezogenen Daten handelt es sich in der Regel um
            Vorname, Nachname, Adresse, Geburtsdatum, Geschlecht, Email-Adresse, IP-Adresse,
            Telefonnummer, Mobiltelefonnummer sowie um andere Daten, die zur Abwicklung eines
            Rechnungs- oder Ratenkaufs notwendig sind. Zur Abwicklung des Kaufvertrages notwendig
            sind auch solche personenbezogenen Daten, die im Zusammenhang mit der jeweiligen
            Bestellung stehen. Insbesondere kann es zum wechselseitigen Austausch von
            Zahlungsinformationen, wie Bankverbindung, Kartennummer, Gültigkeitsdatum und CVC-Code,
            Artikelanzahl, Artikelnummer, Daten zu Waren und Dienstleistungen, Preise und
            steuerliche Abgaben, Angaben zum früheren Kaufverhalten oder sonstige Angaben zur
            finanziellen Situation der betroffenen Person, kommen.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die Übermittlung der Daten bezweckt insbesondere die Identitätsüberprüfung, die
            Zahlungsadministration und die Betrugsprävention. Der für die Verarbeitung
            Verantwortliche wird Klarna personenbezogene Daten insbesondere dann übermitteln, wenn
            ein berechtigtes Interesse für die Übermittlung gegeben ist. Die zwischen Klarna und dem
            für die Verarbeitung Verantwortlichen ausgetauschten personenbezogenen Daten werden von
            Klarna an Wirtschaftsauskunfteien übermittelt. Diese Übermittlung bezweckt die
            Identitäts- und Bonitätsprüfung.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Klarna gibt die personenbezogenen Daten auch an verbundene Unternehmen (Klarna Gruppe)
            und Leistungserbringer oder Subunternehmer weiter, soweit dies zur Erfüllung der
            vertraglichen Verpflichtungen erforderlich ist oder die Daten im Auftrag verarbeitet
            werden sollen.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Zur Entscheidung über die Begründung, Durchführung oder Beendigung einer
            Vertragsbeziehung erhebt und nutzt Klarna Daten und Informationen über das bisherige
            Zahlungsverhalten der betroffenen Person sowie Wahrscheinlichkeitswerte für deren
            Verhalten in der Zukunft (sogenanntes Scoring). Die Berechnung des Scorings wird auf der
            Basis wissenschaftlich anerkannter mathematisch-statistischer Verfahren durchgeführt.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die betroffene Person hat die Möglichkeit, die Einwilligung zum Umgang mit
            personenbezogenen Daten jederzeit gegenüber Klarna zu widerrufen. Ein Widerruf wirkt
            sich nicht auf personenbezogene Daten aus, die zwingend zur (vertragsgemäßen)
            Zahlungsabwicklung verarbeitet, genutzt oder übermittelt werden müssen.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die geltenden Datenschutzbestimmungen von Klarna können unter{' '}
            <Link
              href="https://cdn.klarna.com/1.0/shared/content/policy/data/de_de/data_protection.pdf"
              target="_blank"
              color="textPrimary"
            >
              https://cdn.klarna.com/1.0/shared/content/policy/data/de_de/data_protection.pdf
            </Link>{' '}
            abgerufen werden.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            23. Zahlungsart: Datenschutzbestimmungen zu PayPal als Zahlungsart
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite Komponenten von
            PayPal integriert. PayPal ist ein Online-Zahlungsdienstleister. Zahlungen werden über
            sogenannte PayPal-Konten abgewickelt, die virtuelle Privat- oder Geschäftskonten
            darstellen. Zudem besteht bei PayPal die Möglichkeit, virtuelle Zahlungen über
            Kreditkarten abzuwickeln, wenn ein Nutzer kein PayPal-Konto unterhält. Ein PayPal-Konto
            wird über eine E-Mail-Adresse geführt, weshalb es keine klassische Kontonummer gibt.
            PayPal ermöglicht es, Online-Zahlungen an Dritte auszulösen oder auch Zahlungen zu
            empfangen. PayPal übernimmt ferner Treuhänderfunktionen und bietet Käuferschutzdienste
            an.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die Europäische Betreibergesellschaft von PayPal ist die PayPal (Europe) S.à.r.l. &amp;
            Cie. S.C.A., 22-24 Boulevard Royal, 2449 Luxembourg, Luxemburg.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Wählt die betroffene Person während des Bestellvorgangs in unserem Online-Shop als
            Zahlungsmöglichkeit „PayPal“ aus, werden automatisiert Daten der betroffenen Person an
            PayPal übermittelt. Mit der Auswahl dieser Zahlungsoption willigt die betroffene Person
            in die zur Zahlungsabwicklung erforderliche Übermittlung personenbezogener Daten ein.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Bei den an PayPal übermittelten personenbezogenen Daten handelt es sich in der Regel um
            Vorname, Nachname, Adresse, Email-Adresse, IP-Adresse, Telefonnummer, Mobiltelefonnummer
            oder andere Daten, die zur Zahlungsabwicklung notwendig sind. Zur Abwicklung des
            Kaufvertrages notwendig sind auch solche personenbezogenen Daten, die im Zusammenhang
            mit der jeweiligen Bestellung stehen.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die Übermittlung der Daten bezweckt die Zahlungsabwicklung und die Betrugsprävention.
            Der für die Verarbeitung Verantwortliche wird PayPal personenbezogene Daten insbesondere
            dann übermitteln, wenn ein berechtigtes Interesse für die Übermittlung gegeben ist. Die
            zwischen PayPal und dem für die Verarbeitung Verantwortlichen ausgetauschten
            personenbezogenen Daten werden von PayPal unter Umständen an Wirtschaftsauskunfteien
            übermittelt. Diese Übermittlung bezweckt die Identitäts- und Bonitätsprüfung.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            PayPal gibt die personenbezogenen Daten gegebenenfalls an verbundene Unternehmen und
            Leistungserbringer oder Subunternehmer weiter, soweit dies zur Erfüllung der
            vertraglichen Verpflichtungen erforderlich ist oder die Daten im Auftrag verarbeitet
            werden sollen.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die betroffene Person hat die Möglichkeit, die Einwilligung zum Umgang mit
            personenbezogenen Daten jederzeit gegenüber PayPal zu widerrufen. Ein Widerruf wirkt
            sich nicht auf personenbezogene Daten aus, die zwingend zur (vertragsgemäßen)
            Zahlungsabwicklung verarbeitet, genutzt oder übermittelt werden müssen.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die geltenden Datenschutzbestimmungen von PayPal können unter{' '}
            <Link
              href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full"
              target="_blank"
              color="textPrimary"
            >
              https://www.paypal.com/de/webapps/mpp/ua/privacy-full
            </Link>{' '}
            abgerufen werden.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            24. Zahlungsart: Datenschutzbestimmungen zu Sofortüberweisung als Zahlungsart
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite Komponenten von
            Sofortüberweisung integriert. Sofortüberweisung ist ein Zahlungsdienst, der eine
            bargeldlose Zahlung von Produkten und Dienstleistungen im Internet ermöglicht.
            Sofortüberweisung bildet ein technisches Verfahren ab, durch welches der Online-Händler
            unverzüglich eine Zahlungsbestätigung erhält. So wird ein Händler in die Lage versetzt,
            Waren, Dienstleistungen oder Downloads sofort nach der Bestellung an den Kunden
            auszuliefern.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Betreibergesellschaft von Sofortüberweisung ist die SOFORT GmbH, Fußbergstraße 1, 82131
            Gauting, Deutschland.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Wählt die betroffene Person während des Bestellvorgangs in unserem Online-Shop als
            Zahlungsmöglichkeit „Sofortüberweisung“ aus, werden automatisiert Daten der betroffenen
            Person an Sofortüberweisung übermittelt. Mit einer Auswahl dieser Zahlungsoption willigt
            die betroffene Person in eine zur Zahlungsabwicklung erforderliche Übermittlung
            personenbezogener Daten ein.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Bei der Kaufabwicklung über Sofortüberweisung übermittelt der Käufer die PIN und die TAN
            an die Sofort GmbH. Sofortüberweisung führt sodann nach technischer Überprüfung des
            Kontostandes und Abruf weiterer Daten zur Prüfung der Kontodeckung eine Überweisung an
            den Online-Händler aus. Die Durchführung der Finanztransaktion wird dem Online-Händler
            sodann automatisiert mitgeteilt.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Bei den mit Sofortüberweisung ausgetauschten personenbezogenen Daten handelt es sich um
            Vorname, Nachname, Adresse, Email-Adresse, IP-Adresse, Telefonnummer, Mobiltelefonnummer
            oder andere Daten, die zur Zahlungsabwicklung notwendig sind. Die Übermittlung der Daten
            bezweckt die Zahlungsabwicklung und die Betrugsprävention. Der für die Verarbeitung
            Verantwortliche wird Sofortüberweisung andere personenbezogene Daten auch dann
            übermitteln, wenn ein berechtigtes Interesse für die Übermittlung gegeben ist. Die
            zwischen Sofortüberweisung und dem für die Verarbeitung Verantwortlichen ausgetauschten
            personenbezogenen Daten werden von Sofortüberweisung unter Umständen an
            Wirtschaftsauskunfteien übermittelt. Diese Übermittlung bezweckt die Identitäts- und
            Bonitätsprüfung.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Sofortüberweisung gibt die personenbezogenen Daten gegebenenfalls an verbundene
            Unternehmen und Leistungserbringer oder Subunternehmer weiter, soweit dies zur Erfüllung
            der vertraglichen Verpflichtungen erforderlich ist oder die Daten im Auftrag verarbeitet
            werden sollen.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die betroffene Person hat die Möglichkeit, die Einwilligung zum Umgang mit
            personenbezogenen Daten jederzeit gegenüber Sofortüberweisung zu widerrufen. Ein
            Widerruf wirkt sich nicht auf personenbezogene Daten aus, die zwingend zur
            (vertragsgemäßen) Zahlungsabwicklung verarbeitet, genutzt oder übermittelt werden
            müssen.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Die geltenden Datenschutzbestimmungen von Sofortüberweisung können unter{' '}
            <Link
              href="https://www.sofort.com/ger-DE/datenschutzerklaerung-sofort-gmbh/"
              target="_blank"
              color="textPrimary"
            >
              https://www.sofort.com/ger-DE/datenschutzerklaerung-sofort-gmbh/
            </Link>{' '}
            abgerufen werden.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            25. Rechtsgrundlage der Verarbeitung
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Art. 6 I lit. a DS-GVO dient unserem Unternehmen als Rechtsgrundlage für
            Verarbeitungsvorgänge, bei denen wir eine Einwilligung für einen bestimmten
            Verarbeitungszweck einholen. Ist die Verarbeitung personenbezogener Daten zur Erfüllung
            eines Vertrags, dessen Vertragspartei die betroffene Person ist, erforderlich, wie dies
            beispielsweise bei Verarbeitungsvorgängen der Fall ist, die für eine Lieferung von Waren
            oder die Erbringung einer sonstigen Leistung oder Gegenleistung notwendig sind, so
            beruht die Verarbeitung auf Art. 6 I lit. b DS-GVO. Gleiches gilt für solche
            Verarbeitungsvorgänge die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind,
            etwa in Fällen von Anfragen zur unseren Produkten oder Leistungen. Unterliegt unser
            Unternehmen einer rechtlichen Verpflichtung durch welche eine Verarbeitung von
            personenbezogenen Daten erforderlich wird, wie beispielsweise zur Erfüllung steuerlicher
            Pflichten, so basiert die Verarbeitung auf Art. 6 I lit. c DS-GVO. In seltenen Fällen
            könnte die Verarbeitung von personenbezogenen Daten erforderlich werden, um
            lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person
            zu schützen. Dies wäre beispielsweise der Fall, wenn ein Besucher in unserem Betrieb
            verletzt werden würde und daraufhin sein Name, sein Alter, seine Krankenkassendaten oder
            sonstige lebenswichtige Informationen an einen Arzt, ein Krankenhaus oder sonstige
            Dritte weitergegeben werden müssten. Dann würde die Verarbeitung auf Art. 6 I lit. d
            DS-GVO beruhen. Letztlich könnten Verarbeitungsvorgänge auf Art. 6 I lit. f DS-GVO
            beruhen. Auf dieser Rechtsgrundlage basieren Verarbeitungsvorgänge, die von keiner der
            vorgenannten Rechtsgrundlagen erfasst werden, wenn die Verarbeitung zur Wahrung eines
            berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich ist, sofern
            die Interessen, Grundrechte und Grundfreiheiten des Betroffenen nicht überwiegen. Solche
            Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet, weil sie durch den
            Europäischen Gesetzgeber besonders erwähnt wurden. Er vertrat insoweit die Auffassung,
            dass ein berechtigtes Interesse anzunehmen sein könnte, wenn die betroffene Person ein
            Kunde des Verantwortlichen ist (Erwägungsgrund 47 Satz 2 DS-GVO).
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            26. Berechtigte Interessen an der Verarbeitung, die von dem Verantwortlichen oder einem
            Dritten verfolgt werden
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I lit. f DS-GVO ist unser
            berechtigtes Interesse die Durchführung unserer Geschäftstätigkeit zugunsten des
            Wohlergehens all unserer Mitarbeiter und unserer Anteilseigner.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            27. Dauer, für die die personenbezogenen Daten gespeichert werden
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Das Kriterium für die Dauer der Speicherung von personenbezogenen Daten ist die
            jeweilige gesetzliche Aufbewahrungsfrist. Nach Ablauf der Frist werden die
            entsprechenden Daten routinemäßig gelöscht, sofern sie nicht mehr zur Vertragserfüllung
            oder Vertragsanbahnung erforderlich sind.
          </Typography>

          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            28. Gesetzliche oder vertragliche Vorschriften zur Bereitstellung der personenbezogenen
            Daten; Erforderlichkeit für den Vertragsabschluss; Verpflichtung der betroffenen Person,
            die personenbezogenen Daten bereitzustellen; mögliche Folgen der Nichtbereitstellung
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Wir klären Sie darüber auf, dass die Bereitstellung personenbezogener Daten zum Teil
            gesetzlich vorgeschrieben ist (z.B. Steuervorschriften) oder sich auch aus vertraglichen
            Regelungen (z.B. Angaben zum Vertragspartner) ergeben kann. Mitunter kann es zu einem
            Vertragsschluss erforderlich sein, dass eine betroffene Person uns personenbezogene
            Daten zur Verfügung stellt, die in der Folge durch uns verarbeitet werden müssen. Die
            betroffene Person ist beispielsweise verpflichtet uns personenbezogene Daten
            bereitzustellen, wenn unser Unternehmen mit ihr einen Vertrag abschließt. Eine
            Nichtbereitstellung der personenbezogenen Daten hätte zur Folge, dass der Vertrag mit
            dem Betroffenen nicht geschlossen werden könnte. Vor einer Bereitstellung
            personenbezogener Daten durch den Betroffenen muss sich der Betroffene an einen unserer
            Mitarbeiter wenden. Unser Mitarbeiter klärt den Betroffenen einzelfallbezogen darüber
            auf, ob die Bereitstellung der personenbezogenen Daten gesetzlich oder vertraglich
            vorgeschrieben oder für den Vertragsabschluss erforderlich ist, ob eine Verpflichtung
            besteht, die personenbezogenen Daten bereitzustellen, und welche Folgen die
            Nichtbereitstellung der personenbezogenen Daten hätte.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="h5">
            29. Bestehen einer automatisierten Entscheidungsfindung
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Als verantwortungsbewusstes Unternehmen verzichten wir auf eine automatische
            Entscheidungsfindung oder ein Profiling.
          </Typography>
          <Typography color="textPrimary" className={classes.paragraph} variant="body1">
            Diese Datenschutzerklärung wurde durch den Datenschutzerklärungs-Generator der DGD
            Deutsche Gesellschaft für Datenschutz GmbH, die als{' '}
            <Link
              href="https://dg-datenschutz.de/datenschutz-dienstleistungen/externer-datenschutzbeauftragter/"
              target="_blank"
              color="textPrimary"
            >
              Externer Datenschutzbeauftragter Schwaben
            </Link>{' '}
            tätig ist, in Kooperation mit den{' '}
            <Link
              href="https://www.wbs-law.de/it-recht/datenschutzrecht/"
              target="_blank"
              color="textPrimary"
            >
              Datenschutz Anwälten der Kanzlei WILDE BEUGER SOLMECKE | Rechtsanwälte
            </Link>{' '}
            erstellt.
          </Typography>
        </Grid>
      </Container>
    </CustomScrollbars>
  );
};

export default DataPrivacy;
