import { MapContainer, TileLayer } from 'react-leaflet';
import { makeStyles } from '@material-ui/core';
import ReactLeafletKml from 'react-leaflet-kml';

const useStyle = makeStyles(() => ({
  mapContainer: {
    height: '100%',
    borderRadius: '30px'
  },
  secondaryRow: {
    flexGrow: 1
  }
}));

interface MapProps {
  centerPosition: [number, number];
  kml?: XMLDocument | null;
  children: JSX.Element[] | JSX.Element;
}

const Map = ({ centerPosition, kml, children }: MapProps) => {
  const classes = useStyle();
  return (
    <MapContainer
      center={centerPosition}
      zoom={12}
      scrollWheelZoom={false}
      className={classes.mapContainer}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {children}
      {kml && <ReactLeafletKml kml={kml} />}
    </MapContainer>
  );
};

export default Map;
