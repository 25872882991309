import { Box, Container, Hidden, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Footer from 'components/Footer';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'redux/action/user';
import { clear as clearTableState } from 'redux/slice/tableSlice';
import { userDataSelector } from 'redux/slice/userSlice';

import start_bg from '../../assets/images/bg/start_bg.jpg';
import SkyLogo from '../../assets/images/logo.png';
import NavBar from './NavBar';
import { TopBar } from './TopBar';

const drawerWidth = 200;

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: 75 + theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    display: 'flex',
    justifyContent: 'center'
  },
  mainContainerShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    paddingLeft: drawerWidth + theme.spacing(3)
  },
  content: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    width: '100%'
  },

  backgroundImage: {
    backgroundImage: `url(${start_bg})`,
    backgroundSize: 'cover',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
  },
  smallScreenContainer: {
    backgroundColor: 'white',
    borderRadius: '20px'
  }
}));

const DefaultLayout = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openNavBar, setOpenNavBar] = useState<boolean>(false);
  const user = useSelector(userDataSelector);

  const handleToggleNavbar = useCallback(() => {
    setOpenNavBar(!openNavBar);
  }, [setOpenNavBar, openNavBar]);

  useEffect(() => {
    setOpenNavBar(!!user);
  }, [user]);

  useEffect(() => {
    dispatch(clearTableState());
    dispatch(getUser());
  }, [dispatch]);

  return (
    <Container maxWidth={false} disableGutters className={classes.backgroundImage}>
      <Hidden lgUp>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          p={5}
          m={10}
          className={classes.smallScreenContainer}
        >
          <Box mb={4}>
            <img src={SkyLogo} alt="SKY HELI" />
          </Box>
          <Typography color="textSecondary" variant="h6">
            <FormattedMessage id="smallScreen" />
          </Typography>
        </Box>
      </Hidden>
      <Hidden mdDown>
        <TopBar toggleDrawer={handleToggleNavbar} />
        <NavBar open={openNavBar} drawerWidth={drawerWidth} />
        <Container
          maxWidth={false}
          className={clsx(classes.mainContainer, {
            [classes.mainContainerShift]: openNavBar
          })}
        >
          <div className={classes.content}>{children}</div>
        </Container>
        <Footer />
      </Hidden>
    </Container>
  );
};

export default DefaultLayout;
