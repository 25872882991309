import { Checkbox, TableCell, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';
import { MouseEvent, useCallback } from 'react';

import { BORDER_RADIUS_BUTTON } from '../../theme/constants';

export type TableCellType = string | number | JSX.Element;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& td': {
      border: 'none',
      color: '#63799B'
    },
    '& td:first-child': {
      borderTopLeftRadius: BORDER_RADIUS_BUTTON,
      borderBottomLeftRadius: BORDER_RADIUS_BUTTON,
      paddingLeft: theme.spacing(5)
    },
    '& td:last-child': {
      borderTopRightRadius: BORDER_RADIUS_BUTTON,
      borderBottomRightRadius: BORDER_RADIUS_BUTTON,
      paddingRight: theme.spacing(5)
    },
    '&:hover': {
      '& td': {
        backgroundColor: '#558EBC !important',
        color: 'white'
      }
    }
  },
  event: {
    backgroundColor: '#00408C'
  }
}));

export interface ListRowProps {
  selected?: boolean;
  onClick?: (event: MouseEvent<HTMLTableRowElement>, key: string) => void;
  key: string;
  id?: string;
  cells: TableCellType[];
}

export const ListRow = (props: ListRowProps) => {
  const { selected, onClick, cells, id } = props;
  const classes = useStyles();
  const handleOnClick = useCallback(
    (event: MouseEvent<HTMLTableRowElement>) => {
      if (onClick && id) {
        onClick(event, id);
      }
    },
    [onClick, id]
  );

  return (
    <TableRow
      className={clsx({ [classes.root]: true })}
      hover
      onClick={handleOnClick}
      role="checkbox"
      aria-checked={selected}
      tabIndex={-1}
      key={id}
      selected={selected}
    >
      <TableCell key={0}>
        <Checkbox
          checked={selected}
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </TableCell>
      {cells.map((cell, index) => (
        <TableCell key={index}>
          {typeof cell === 'string' && <Typography color="inherit">{cell}</Typography>}
          {typeof cell !== 'string' && cell}
        </TableCell>
      ))}
    </TableRow>
  );
};
