import { Box, Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Modal, modalParamsSelector } from 'redux/slice/modalSlice';

import BaseModal, { SimpleModalProps } from './BaseModal';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    margin: '30px auto 0 auto',
    flexDirection: 'column',
    padding: '0 50px',
    [theme.breakpoints.down('sm')]: {
      padding: '0'
    }
  },
  confirmText: {
    fontWeight: 500,
    fontSize: '12px',
    textAlign: 'center'
  },
  button: {
    marginTop: '30px',
    marginBottom: '15px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
}));

export const ConfirmModal = ({ open, onClose }: SimpleModalProps) => {
  const classes = useStyles();
  const params = useSelector(modalParamsSelector)[Modal.CONFIRM];

  const wrappedOnClose = () => {
    if (onClose) {
      onClose();
    }

    if (params.onClose) {
      params.onClose();
    }
  };

  return (
    <BaseModal open={open} onClose={onClose} title={params.title} description={params?.description}>
      <Box className={classes.root}>
        <Typography className={classes.confirmText}>{params.message}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              color="primary"
              size="medium"
              type="submit"
              fullWidth
              variant="contained"
              onClick={params.onConfirm}
              className={classes.button}
            >
              {params.buttonYes}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="primary"
              size="medium"
              fullWidth
              type="button"
              variant="contained"
              onClick={wrappedOnClose}
              className={classes.button}
            >
              {params.buttonNo}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </BaseModal>
  );
};

export default ConfirmModal;
