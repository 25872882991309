import { PayloadAction } from '@reduxjs/toolkit';
import { IOrder } from 'interfaces/order';
import { PageResult } from 'interfaces/pageResult';
import { getOrdersNotOpenedForNotification } from 'redux/action/order';

import { StateData } from '../../interfaces/stateData';
import createAsyncReducer from '../createAsyncReducer';
import { createAsyncSlice } from '../createAsyncSlice';
import { RootState } from '../rootState';

export const notificationSlice = createAsyncSlice<StateData<IOrder>>({
  name: 'notification',
  initialData: {
    list: [],
    totalCount: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    createAsyncReducer(
      builder,
      getOrdersNotOpenedForNotification,
      (state, { payload }: PayloadAction<PageResult<IOrder>>) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.list = payload.content;
        state.data.totalCount = payload.totalElements;
      }
    );
  }
});

export const notificationSelector = (state: RootState) => state.notificationSlice;
export const notificationTotalCountSelector = (state: RootState) => state.notificationSlice.data.totalCount;
export const notificationsDataSelector = (state: RootState) => state.notificationSlice.data.list;
export const notificationDataSelector = (state: RootState) => state.notificationSlice.data.entry;

export const { clearState } = notificationSlice.actions;
