import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import { IDamageQuery, IUpdateDamageBody } from 'interfaces/damage';
import DamageService from 'services/damageService';

export const getAllDamages = createAsyncThunk(
  'damage/getAll',
  async (query: IDamageQuery, thunkAPI) => {
    try {
      const { status, data } = await DamageService.getAllDamages(query);
      if (status === StatusCodes.OK) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);


export const getDamage = createAsyncThunk(
  'damage/get',
  async (uuid: string, thunkAPI) => {
    try {
      const { status, data } = await DamageService.getDamage(uuid);
      if (status === StatusCodes.OK) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateDamage = createAsyncThunk(
  'damage/update',
  async ({ uuid, ...body }: IUpdateDamageBody, thunkAPI) => {
    try {
      const { status, data } = await DamageService.updateDamage(uuid, body);
      if (status === StatusCodes.OK) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const requestDamage = createAsyncThunk(
  'damage/request',
  async (uuid: string, thunkAPI) => {
    try {
      const { status, data } = await DamageService.requestDamage(uuid);
      if (status === StatusCodes.OK) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);