import Typography from '@material-ui/core/Typography';
import { Box, Container, Link, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  media: {
    height: 36,
    width: 36,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  mediaLink: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  }
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="md">
        <Box mt={2} display="flex" flexDirection="row">
          <Link className={classes.mediaLink} href="/imprint" target="_blank">
            <Typography variant="subtitle2" color="textPrimary">
              <FormattedMessage id="footer.imprint" />
            </Typography>
          </Link>
          <Box mr={1} ml={1}>
            <Typography variant="subtitle2" color="textPrimary">
              |
            </Typography>
          </Box>
          <Link className={classes.mediaLink} href="/data-privacy" target="_blank">
            <Typography variant="subtitle2" color="textPrimary">
              <FormattedMessage id="footer.dataPrivacy" />
            </Typography>
          </Link>
        </Box>
      </Container>
    </div>
  );
};

export default Footer;
