import { PayloadAction } from '@reduxjs/toolkit';
import { IOrder } from 'interfaces/order';
import { PageResult } from 'interfaces/pageResult';
import { getOrdersNotOpened } from 'redux/action/order';

import { StateData } from '../../interfaces/stateData';
import createAsyncReducer from '../createAsyncReducer';
import { createAsyncSlice } from '../createAsyncSlice';
import { RootState } from '../rootState';

export const orderSlice = createAsyncSlice<StateData<IOrder>>({
  name: 'order',
  initialData: {
    list: [],
    totalCount: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    createAsyncReducer(
      builder,
      getOrdersNotOpened,
      (state, { payload }: PayloadAction<PageResult<IOrder>>) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.list = payload.content;
        state.data.totalCount = payload.totalElements;
      }
    );
  }
});

export const orderSelector = (state: RootState) => state.orderSlice;
export const orderTotalCountSelector = (state: RootState) => state.orderSlice.data.totalCount;
export const ordersDataSelector = (state: RootState) => state.orderSlice.data.list;
export const orderDataSelector = (state: RootState) => state.orderSlice.data.entry;

export const { clearState } = orderSlice.actions;
