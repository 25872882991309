import { PayloadAction } from '@reduxjs/toolkit';
import { ILine } from 'interfaces/line';
import { PageResult } from 'interfaces/pageResult';
import { getAllLines } from 'redux/action/line';
import { StateData } from '../../interfaces/stateData';
import createAsyncReducer from '../createAsyncReducer';
import { createAsyncSlice } from '../createAsyncSlice';
import { RootState } from '../rootState';

export const lineSlice = createAsyncSlice<StateData<ILine>>({
  name: 'line',
  initialData: {
    list: [],
    totalCount: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    createAsyncReducer(
      builder,
      getAllLines,
      (state, { payload }: PayloadAction<PageResult<ILine>>) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.list = payload.content;
        state.data.totalCount = payload.count;
      }
    );
  }
});

export const lineSelector = (state: RootState) => state.lineSlice;
export const lineTotalCountSelector = (state: RootState) => state.lineSlice.data.totalCount;
export const linesDataSelector = (state: RootState) => state.lineSlice.data.list;
export const lineDataSelector = (state: RootState) => state.lineSlice.data.entry;

export const { clearState } = lineSlice.actions;
