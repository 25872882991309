import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

interface PhotoGalleryProps {
  images: string[];
  photoIndex: number;
  onClose: () => void;
  onChangeIndex: (index: number) => void;
}

const PhotoGallery = ({ images, photoIndex, onClose, onChangeIndex }: PhotoGalleryProps) => {
  return (
    <Lightbox
      mainSrc={images[photoIndex]}
      nextSrc={images[(photoIndex + 1) % images.length]}
      prevSrc={images[(photoIndex + images.length - 1) % images.length]}
      onCloseRequest={onClose}
      onMovePrevRequest={() => onChangeIndex((photoIndex + images.length - 1) % images.length)}
      onMoveNextRequest={() => onChangeIndex((photoIndex + 1) % images.length)}
      reactModalStyle={{ overlay: { zIndex: 2000 } }}
    />
  );
};

export default PhotoGallery;
