import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import { StatusCodes } from 'http-status-codes';
import { ForgotPasswordFormData } from 'interfaces/user';
import { useSnackbar } from 'notistack';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from 'redux/action/user';
import { Modal, modalParamsSelector } from 'redux/slice/modalSlice';
import * as yup from 'yup';

import BaseModal, { SimpleModalProps } from './BaseModal';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    margin: '30px auto 0 auto',
    flexDirection: 'column',
    padding: '0 50px',
    [theme.breakpoints.down('sm')]: {
      padding: '0'
    }
  },
  confirmText: {
    fontWeight: 500,
    fontSize: '12px',
    textAlign: 'center'
  },
  button: {
    marginTop: '30px',
    marginBottom: '15px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
}));

export const ForgotPasswordModal = ({ open, onClose }: SimpleModalProps) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const params = useSelector(modalParamsSelector)[Modal.FORGOT_PASSWORD];;
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = yup.object().shape({
    firstName: yup.string().required(formatMessage({ id: 'validation.required' })),
    lastName: yup.string().required(formatMessage({ id: 'validation.required' })),
    email: yup
      .string()
      .email(formatMessage({ id: 'validation.email' }))
      .required(formatMessage({ id: 'validation.required' }))
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ForgotPasswordFormData>({
    resolver: yupResolver(validationSchema)
  });

  const wrappedOnClose = () => {
    if (onClose) {
      onClose();
    }

    if (params.onClose) {
      params.onClose();
    }
  };

  const onSubmit: SubmitHandler<ForgotPasswordFormData> = async (data) => {
    const result: any = await dispatch(resetPassword(data));
    if (result?.error && result?.payload?.statusCode === StatusCodes.NOT_FOUND) {
      return enqueueSnackbar(formatMessage({ id: 'forgotPasswordModal.userNotFound' }), {
        variant: 'error'
      });
    }
    if (result?.error) {
      return enqueueSnackbar(formatMessage({ id: 'forgotPasswordModal.error' }), {
        variant: 'error'
      });
    }

    if (result?.payload) {
      onClose();
      enqueueSnackbar(formatMessage({ id: 'forgotPasswordModal.success' }), {
        variant: 'success'
      });
    }
  };

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      width={600}
      title={formatMessage({ id: 'forgotPasswordModal.title' })}
      description={formatMessage({ id: 'forgotPasswordModal.description' })}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                color="primary"
                {...register('firstName')}
                name="firstName"
                error={Boolean(errors.firstName)}
                helperText={errors.firstName?.message}
                placeholder={formatMessage({ id: 'forgotPasswordModal.firstName' })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                color="primary"
                {...register('lastName')}
                name="lastName"
                error={Boolean(errors.lastName)}
                helperText={errors.lastName?.message}
                placeholder={formatMessage({ id: 'forgotPasswordModal.lastName' })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                color="primary"
                {...register('email')}
                name="email"
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
                placeholder={formatMessage({ id: 'forgotPasswordModal.email' })}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                color="primary"
                size="medium"
                type="submit"
                fullWidth
                variant="contained"
                className={classes.button}
              >
                <FormattedMessage id="forgotPasswordModal.reset" />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="primary"
                size="medium"
                fullWidth
                type="button"
                variant="contained"
                onClick={wrappedOnClose}
                className={classes.button}
              >
                <FormattedMessage id="button.cancel" />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </BaseModal>
  );
};

export default ForgotPasswordModal;
