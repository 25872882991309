import { Box, Button, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
    border: 'none',
    backgroundColor: (props: any) => props.backgroundColor,
    zIndex: 1,
    borderRadius: 0,
    '&::after': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      opacity: 0.5,
      zIndex: -1
    },
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      '&::after': {
        backgroundColor: 'transparent'
      }
    }
  }
}));

interface StartButtonProps {
  title: string;
  subtitle: string;
  href: string;
  backgroundColor: string;
}

const StartButton = ({ title, subtitle, href, backgroundColor }: StartButtonProps) => {
  const classes: any = useStyles({ backgroundColor });

  return (
    <Button
      className={classes.root}
      color="secondary"
      component={Link}
      to={href}
      variant="contained"
    >
      <Box
        width="100%"
        height="100%"
        mt="60%"
        ml={2}
        mr={2}
        mb={3}
        display="flex"
        flexDirection="column"
      >
        <Typography variant="h5" color="textPrimary" align="left">
          {title}
        </Typography>
        <Typography variant="h2" color="textPrimary" align="left">
          {subtitle}
        </Typography>
      </Box>
    </Button>
  );
};

export default StartButton;
