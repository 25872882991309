import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../rootState';

export type OrderType = 'asc' | 'desc';

interface TableState {
  order: OrderType;
  orderBy: string;
  page: number;
}

const initialState: TableState = {
  order: 'asc',
  orderBy: 'uuid',
  page: 0
};

export const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    updateOrder: (state, { payload }: PayloadAction<OrderType>) => ({
      ...state,
      order: payload
    }),
    updateOrderBy: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      orderBy: payload
    }),
    updatePage: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      page: payload
    }),
    clear: () => ({
      ...initialState
    })
  }
});

export const tableSelector = (state: RootState) => state.tableSlice;

export const { updateOrder, updateOrderBy, updatePage, clear } = tableSlice.actions;
