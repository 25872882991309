import { PATHS } from 'constants/paths';

const config = [
  {
    title: 'start.mainSelection',
    subtitle: '01',
    href: PATHS.mainSelection,
    bgColor: 'rgba(29, 67, 136, 0.5)'
  },
  {
    title: 'start.advancedSearch',
    subtitle: '02',
    href: PATHS.advancedSearch,
    bgColor: 'rgba(46, 96, 142, 0.5)'
  },
  {
    title: 'start.editing',
    subtitle: '03',
    href: PATHS.editing,
    bgColor: 'rgba(12, 31, 64, 0.5)'
  },
  {
    title: 'start.export',
    subtitle: '04',
    href: PATHS.export,
    bgColor: 'rgba(21, 53, 138, 0.5)',
  }
];

export default config;
