import { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Drawer } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { ArrowForwardIos as ArrowForwardIosIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { NavItem, SubNavItem } from 'interfaces/nav';
import start_bg from '../../assets/images/bg/start_bg.jpg';
import sections from './navConfig';

const useStyles = makeStyles(() => ({
  drawer: ({ drawerWidth }: Pick<NavBarProps, 'drawerWidth'>) => ({
    width: drawerWidth,
    flexShrink: 0
  }),
  desktopDrawer: ({ drawerWidth }: Pick<NavBarProps, 'drawerWidth'>) => ({
    width: drawerWidth,
    height: 'calc(100% - 75px)',
    marginTop: 'auto',
    left: 0,
    bottom: 0,
    padding: 0,
    backgroundColor: 'transparent'
  }),
  paperAnchorLeft: {
    marginTop: '75px'
  },
  bellNotification: {
    marginLeft: 'auto'
  },
  list: {
    width: 235,
    height: '100%',
    backgroundImage: `url(${start_bg})`,
    backgroundSize: 'cover'
  },
  fullList: {
    width: 235
  },
  button: {
    flexGrow: 1,
    borderRadius: 0,
    padding: '15px 15px 15px 21px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  anchor: {
    textDecoration: 'none'
  },
  categoryTitle: {
    fontSize: '1.2rem',
    wordWrap: 'break-word',
    color: '#fff',
    margin: 0,
    textTransform: 'uppercase'
  },
  subItemParagraph: {
    margin: 0,
    lineHeight: '1.4rem'
  },
  categoryNum: {
    fontSize: '3.5rem',
    color: '#fff',
    margin: 0,
    fontWeight: 'bold'
  },
  active: {
    background: '#fff !important',
    color: '#000666'
  },
  subItem: {
    color: '#000',
    textDecorationLine: 'none',
    textTransform: 'uppercase',
    display: 'flex'
  },
  activeSubItem: {
    color: '#558EBC'
  }
}));

interface SubItemsProps {
  drawerWidth: number;
  subItems?: SubNavItem[];
}

const SubItems = ({ drawerWidth, subItems }: SubItemsProps) => {
  const location = useLocation();
  const classes = useStyles({ drawerWidth });

  return (
    <div>
      {subItems &&
        subItems.map((subitem: any) => (
          <Link
            to={subitem.href}
            key={subitem.href}
            className={clsx(
              classes.subItem,
              location.pathname === subitem.href ? classes.activeSubItem : ''
            )}
          >
            <ArrowForwardIosIcon />
            <p key={subitem.href} className={classes.subItemParagraph}>
              <FormattedMessage id={subitem.title} />
            </p>
          </Link>
        ))}
    </div>
  );
};

interface NavBarProps {
  onMobileClose?: () => void;
  openMobile?: boolean;
  open: boolean;
  drawerWidth: number;
}

const NavBar = ({ openMobile, onMobileClose, open, drawerWidth }: NavBarProps) => {
  const classes = useStyles({ drawerWidth });
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [openMobile, onMobileClose, location.pathname]);

  return (
    <Drawer
      className={classes.drawer}
      anchor="left"
      classes={{
        paper: classes.desktopDrawer
      }}
      open={open}
      variant="persistent"
    >
      {sections.map((item: NavItem) => (
        <Box
          key={item.href}
          style={{ background: item.bgColor }}
          className={clsx(
            classes.button,
            location.pathname.includes(item.href) ? classes.active : ''
          )}
        >
          <Link to={item.subItems ? item.subItems[0].href : item.href} className={classes.anchor}>
            <p
              className={clsx(
                classes.categoryTitle,
                location.pathname.includes(item.href) ? classes.active : ''
              )}
            >
              <FormattedMessage id={item.title} />
            </p>
            <p
              className={clsx(
                classes.categoryNum,
                location.pathname.includes(item.href) ? classes.active : ''
              )}
            >
              {item.subtitle}
            </p>
          </Link>
          {'subItems' in item && location.pathname.includes(item.href) ? (
            <SubItems drawerWidth={drawerWidth} subItems={item.subItems} />
          ) : (
            ''
          )}
        </Box>
      ))}
    </Drawer>
  );
};

export default NavBar;
