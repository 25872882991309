import { createTheme, ThemeOptions } from '@material-ui/core';
import { palette } from './palette';
import overrides from './overrides';
import { typography } from './typography';

const options: ThemeOptions = {
  overrides,
  palette,
  typography
};

export const theme = createTheme(options);
