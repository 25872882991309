import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  OutlinedInput,
  TextFieldProps,
  Theme,
  Typography
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import CustomScrollbars from 'components/CustomScrollbars';
import ListView from 'components/ListView';
import { HeadCell } from 'components/ListView/ListHeader';
import dayjs from 'dayjs';
import { ExportStatus } from 'enum/exportStatus';
import { ExportTemplates } from 'enum/exportTemplates';
import { IDamage } from 'interfaces/damage';
import { IExport } from 'interfaces/export';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { changeStatusExport, deleteExport, getAllExports } from 'redux/action/export';
import { exportSelector } from 'redux/slice/exportSlice';
import { hideModal, Modal, showModal } from 'redux/slice/modalSlice';
import { tableSelector } from 'redux/slice/tableSlice';
import { isClientUserSelector } from 'redux/slice/userSlice';
import ExportService from 'services/exportService';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  datePickerPaper: {
    padding: 0
  },
  lineOptions: {},
  exportTemplates: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3)
  },
  exportTemplatesButton: {
    justifyContent: 'flex-start',
    paddingLeft: '30px',
    paddingRight: '30px',
    marginBottom: theme.spacing(2)
  }
}));

const Export = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState<string[]>([]);
  const isClientUser = useSelector(isClientUserSelector);
  const { enqueueSnackbar } = useSnackbar();

  const { formatMessage } = useIntl();
  const [filterCreatedAt, setFilterCreatedAt] = useState<MaterialUiPickersDate>(null);
  const {
    data: { list, totalCount },
    isFetching
  } = useSelector(exportSelector);

  const handleClickSendRequest = async (exportObject: IExport) => {
    await dispatch(
      changeStatusExport({ uuid: exportObject.uuid, exportStatus: ExportStatus.Requested })
    );
    enqueueSnackbar(formatMessage({ id: 'export.sendRequestSuccess' }), {
      variant: 'success'
    });
    dispatchGetAllExports();
  };

  const handleClickShowDamages = async (exportObject: IExport) => {
    dispatch(
      showModal({
        id: Modal.INFO,
        params: {
          message: (
            <Box display="flex">
              <span>
                <FormattedMessage id="export.showDamagesMessage" />
              </span>
              <IconButton
                color="primary"
                onClick={() =>
                  navigator.clipboard.writeText(process.env.REACT_APP_NETWORK_STORAGE_URL + (exportObject.exportedFolder || ''))
                }
              >
                <FileCopyIcon />
              </IconButton>
            </Box>
          )
        }
      })
    );
  };

  const handleClickApproveRequest = async (exportObject: IExport) => {
    await dispatch(
      changeStatusExport({ uuid: exportObject.uuid, exportStatus: ExportStatus.InProgress })
    );
    enqueueSnackbar(formatMessage({ id: 'export.approveRequestSuccess' }), {
      variant: 'success'
    });
    dispatchGetAllExports();
  };

  const handleClickRemove = (exportObject: IExport) => {
    dispatch(
      showModal({
        id: Modal.CONFIRM,
        params: {
          title: formatMessage({ id: 'export.deleteConfirmTitle' }),
          message: formatMessage({ id: 'export.deleteConfirmMessage' }),
          buttonYes: formatMessage({ id: 'button.yes' }),
          buttonNo: formatMessage({ id: 'button.no' }),
          onConfirm: async () => {
            await dispatch(deleteExport(exportObject.uuid));
            enqueueSnackbar(formatMessage({ id: 'export.deleteConfirmSuccess' }), {
              variant: 'success'
            });
            dispatch(hideModal({ id: Modal.CONFIRM }));
            dispatchGetAllExports();
            setSelectedRow([]);
          }
        }
      })
    );
  };

  const { order, orderBy, page } = useSelector(tableSelector);

  const dispatchGetAllExports = useCallback(
    () =>
      dispatch(
        getAllExports({
          pageSize: 10,
          createdAt: filterCreatedAt?.toISOString(),
          sort: orderBy,
          sortDirection: order,
          page
        })
      ),
    [dispatch, orderBy, order, page, filterCreatedAt]
  );

  useEffect(() => {
    dispatchGetAllExports();
  }, [dispatchGetAllExports, orderBy, order, page, filterCreatedAt]);

  const headCellsClient: HeadCell[] = [
    { key: 'line', label: formatMessage({ id: 'export.line' }) },
    {
      key: 'requester',
      label: formatMessage({ id: 'export.requester' })
    },
    {
      key: 'createdAt',
      label: formatMessage({ id: 'export.date' })
    },
    {
      key: 'exportStatus',
      label: formatMessage({ id: 'export.exportDate' })
    },
    {
      key: 'section',
      label: formatMessage({ id: 'export.advanceSearch' }),
      disabled: true
    },
    {
      key: 'actions',
      label: '',
      disabled: true
    }
  ];

  const headCellsOther: HeadCell[] = [
    {
      key: 'operator',
      label: formatMessage({ id: 'export.operator' })
    },
    { key: 'line', label: formatMessage({ id: 'export.line' }) },
    {
      key: 'requester',
      label: formatMessage({ id: 'export.requester' })
    },
    {
      key: 'createdAt',
      label: formatMessage({ id: 'export.date' })
    },
    {
      key: 'exportStatus',
      label: formatMessage({ id: 'export.exportDate' })
    },
    {
      key: 'location',
      label: formatMessage({ id: 'export.location' }),
      disabled: true
    }
  ];

  const mapOrderOther = (exportObject: IExport) => ({
    key: exportObject.uuid,
    cells: [
      exportObject.requester?.operator?.name || '',
      exportObject?.damages?.map((damage: IDamage) => damage.line?.name).join(', ') || '',
      `${exportObject.requester.contact.firstName} ${exportObject.requester.contact.lastName}`,
      dayjs(exportObject.createdAt).format('DD.MM.YYYY') || '',
      exportObject.exportStatus === ExportStatus.Requested ? (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleClickApproveRequest(exportObject)}
        >
          <FormattedMessage id="button.approve" />
        </Button>
      ) : (
        exportObject.exportStatus === ExportStatus.Created ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleClickSendRequest(exportObject)}
          >
            <FormattedMessage id="button.sendRequest" />
          </Button>
        ) : (
          formatMessage({ id: `export.status.${exportObject.exportStatus.toLowerCase()}` })
        )
      ),
      <Button variant="contained" color="secondary" onClick={()=> handleClickShowDamages(exportObject)}>
        <FormattedMessage id="button.open" />
      </Button>
    ]
  });

  const mapOrderClient = (exportObject: IExport) => ({
    key: exportObject.uuid,
    cells: [
      exportObject?.damages?.map((damage: IDamage) => damage.line?.name).join(', ') || '',
      `${exportObject.requester.contact.firstName} ${exportObject.requester.contact.lastName}`,
      dayjs(exportObject.createdAt).format('DD.MM.YYYY') || '',
      exportObject.exportStatus === ExportStatus.Created ? (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleClickSendRequest(exportObject)}
        >
          <FormattedMessage id="button.sendRequest" />
        </Button>
      ) : (
        formatMessage({ id: `export.status.${exportObject.exportStatus.toLowerCase()}` })
      ),
      <Button variant="contained" color="secondary" onClick={()=> handleClickShowDamages(exportObject)}>
        <FormattedMessage id="button.open" />
      </Button>,
      exportObject.exportStatus === ExportStatus.Created ? (
        <IconButton color="primary" onClick={() => handleClickRemove(exportObject)}>
          <DeleteIcon />
        </IconButton>
      ) : (
        ''
      )
    ]
  });

  const handleOnSelectedRows = (selected: string[]) => {
    setSelectedRow(selected);
  };

  const handleClickExport = async (exportUuid: string) => {
    const response = await ExportService.get(exportUuid);
    if (response.status === 200) {
      const url = window.URL.createObjectURL(response.data);
      window.open(url, '_blank');
      window.URL.revokeObjectURL(url);
    }
  };

  const renderExportTemplatesButton = () =>
    Object.values(ExportTemplates).map((value) => (
      <Button
        key={value}
        variant="contained"
        color="secondary"
        fullWidth
        onClick={() => handleClickExport(selectedRow?.[0])}
        disabled={selectedRow.length === 0}
        className={classes.exportTemplatesButton}
        startIcon={<ArrowDownwardIcon />}
      >
        <FormattedMessage id={`export.template.${value.toLowerCase()}`} />
      </Button>
    ));

  return (
    <Container disableGutters maxWidth={false} className={classes.root}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        height="100%"
        width="100%"
      >
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Typography color="textPrimary" variant="subtitle1">
              <FormattedMessage id="export.description1" />
            </Typography>
            <br />
            <Typography color="textPrimary" variant="subtitle1">
              <FormattedMessage id="export.description2" />
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              color="secondary"
              fullWidth
              autoOk
              label={formatMessage({ id: 'export.date' })}
              value={filterCreatedAt}
              format="DD.MM.YYYY"
              PopoverProps={{
                PaperProps: {
                  className: classes.datePickerPaper
                }
              }}
              TextFieldComponent={(props: TextFieldProps) => (
                <OutlinedInput
                  type="text"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setFilterCreatedAt(null);
                        }}
                      >
                        {props.value && <CloseIcon />}
                        {!props.value && <></>}
                      </IconButton>
                    </InputAdornment>
                  }
                  inputRef={props.inputRef}
                  placeholder={formatMessage({ id: 'export.date' })}
                  inputProps={props.inputProps}
                  onClick={props.onClick}
                  color={props.color}
                  value={props.value}
                  onChange={props.onChange}
                />
              )}
              onChange={setFilterCreatedAt}
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={9}>
            <CustomScrollbars height="calc(100vh - 360px)">
              <ListView
                totalCount={totalCount}
                headCells={isClientUser ? headCellsClient : headCellsOther}
                rows={list.map(isClientUser ? mapOrderClient : mapOrderOther)}
                loading={isFetching}
                selected={selectedRow}
                onSelectRow={handleOnSelectedRows}
              />
            </CustomScrollbars>
          </Grid>
          <Grid item xs={3}>
            <Typography color="textPrimary" variant="body1" className={classes.exportTemplates}>
              <FormattedMessage id="export.exportTemplates" />
            </Typography>
            {renderExportTemplatesButton()}
            <br />
            <br />
            {!isClientUser && (
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                component="a"
                href="/SkyHeli Webportal - create template guide.pdf"
                target="_blank"
                className={classes.exportTemplatesButton}
                startIcon={<ArrowDownwardIcon />}
              >
                <FormattedMessage id={'export.template.guide'} />
              </Button>
            )}
            {!isClientUser && (
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                component="a"
                href="/template.xlsx"
                target="_blank"
                className={classes.exportTemplatesButton}
                startIcon={<ArrowDownwardIcon />}
              >
                <FormattedMessage id={'export.template.demo'} />
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Export;
