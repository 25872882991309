import { GET_ALL_FLY_HISTORY_URL } from 'constants/apiEndpoints';
import httpClient from 'services/apiClient';

const FlyHistoryService = {
  getAllFlyHistories: async (year: number, month: number) => {
    try {
      return await httpClient.get(GET_ALL_FLY_HISTORY_URL, { params: { year, month } });
    } catch (error: any) {
      return error.response;
    }
  }
};

export default FlyHistoryService;
