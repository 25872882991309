import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import VoltageRangeService from 'services/voltageRangeService';

export const getAllVoltageRanges = createAsyncThunk(
  'voltageRanges/getAll',
  async (_, thunkAPI) => {
    try {
      const { status, data } = await VoltageRangeService.getAllVoltageRanges();
      if (status === StatusCodes.OK) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
