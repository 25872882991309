/* eslint-disable react/jsx-pascal-case */
import { TextField } from '@material-ui/core';
import { AutocompleteProps as BaseAutocompleteProps } from '@material-ui/lab';
import * as BaseAutocomplete from '@material-ui/lab/Autocomplete';
import { useIntl } from 'react-intl';

interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Pick<
    BaseAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    | 'options'
    | 'onChange'
    | 'value'
    | 'getOptionSelected'
    | 'getOptionLabel'
    | 'multiple'
    | 'disableClearable'
    | 'freeSolo'
    | 'id'
    | 'disabled'
    | 'defaultValue'
    | 'className'
    | 'placeholder'
  > {
  inputLabel: string;
  inputColor?: 'primary' | 'secondary';
  error?: boolean;
  helperText?: React.ReactNode;
}

const Autocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
) => {
  const { formatMessage } = useIntl();
  const { inputLabel, inputColor, helperText, error, ...propsWithoutInputLabel } = props;

  return (
    <BaseAutocomplete.default
      {...propsWithoutInputLabel}
      fullWidth
      noOptionsText={formatMessage({ id: 'autocomplete.noOptions' })}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          label={inputLabel}
          color={inputColor}
          variant="outlined"
        />
      )}
    />
  );
};

export default Autocomplete;
