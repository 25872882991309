import { PayloadAction } from '@reduxjs/toolkit';
import { IDamageDefinition } from 'interfaces/damageDefinition';
import { PageResult } from 'interfaces/pageResult';
import { getAllDamageDefinitions } from 'redux/action/damageDefinition';
import { StateData } from '../../interfaces/stateData';
import createAsyncReducer from '../createAsyncReducer';
import { createAsyncSlice } from '../createAsyncSlice';
import { RootState } from '../rootState';

export const damageDefinitionSlice = createAsyncSlice<StateData<IDamageDefinition>>({
  name: 'damageDefinition',
  initialData: {
    list: [],
    totalCount: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    createAsyncReducer(
      builder,
      getAllDamageDefinitions,
      (state, { payload }: PayloadAction<PageResult<IDamageDefinition>>) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.list = payload.content;
      }
    );
  }
});

export const damageDefinitionSelector = (state: RootState) => state.damageDefinitionSlice;
export const damageDefinitionsDataSelector = (state: RootState) => state.damageDefinitionSlice.data.list;

export const { clearState } = damageDefinitionSlice.actions;
