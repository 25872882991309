import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';
import httpClient from 'services/apiClient';
import { Store } from 'redux';
import { RootState } from 'redux/rootState';
import { REFRESH_TOKEN_URL } from 'constants/apiEndpoints';
import AuthService from './authService';
import { logoutUser, userSlice } from 'redux/slice/userSlice';
import { PATHS } from 'constants/paths';
import { History } from 'history';

export const setupInterceptor = (store: Store<RootState>, history: History<unknown>) => { 
  httpClient.interceptors.request.use((requestConfig: AxiosRequestConfig) => {
    const state = store.getState();
    const { url } = requestConfig;

    if (requestConfig.headers) {
      requestConfig.headers['Content-Type'] = 'application/json';
      if (state.userSlice.data.accessToken) {
        requestConfig.headers['Authorization'] = `Bearer ${state.userSlice.data.accessToken.token}`;
      }
      if (state.userSlice.data.refreshToken && url === REFRESH_TOKEN_URL) {
        requestConfig.headers['Authorization'] = `Bearer ${state.userSlice.data.refreshToken.token}`;
      }
    }
    
    return requestConfig;
  });

  httpClient.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error) => {
      const originalRequest = error.config;
      if (
        error.response.status === StatusCodes.UNAUTHORIZED &&
        !originalRequest._retry &&
        originalRequest.url !== REFRESH_TOKEN_URL &&
        originalRequest.headers['Authorization']
      ) {
        originalRequest._retry = true;
        try {
          const tokens = await AuthService.refreshToken();
          store.dispatch(userSlice.actions.updateTokens(tokens));
          originalRequest.headers['Authorization'] = `Bearer ${tokens.accessToken.token}`;
          return httpClient(originalRequest);
        } catch (err) {
          store.dispatch(logoutUser());
          history.push(PATHS.login);
          return Promise.reject(error);
        }
      }
      return Promise.reject(error);
    }
  );
};
