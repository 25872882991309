import { CircularProgress } from '@material-ui/core';
import { ReactSVG } from 'react-svg';

interface MastPictogramProps {
  src: string;
  className?: string;
  damagePosition?: [number, number];
  damageColor?: string;
}

const MastPictogram = ({ src, className, damagePosition, damageColor }: MastPictogramProps) => {
  const clearEmptySpace = (svg: SVGSVGElement) => {
    const bbox = svg.getBBox();
    const viewBox = [bbox.x, bbox.y, bbox.width, bbox.height].join(' ');
    svg.setAttribute('viewBox', viewBox);
  };

  const convertDamagePosition = (damagePosition: [number, number]): {cy: string, cx: string} => {
    const cx =  damagePosition[0] * 100
    const cy = 216 + ((360 - 216) * damagePosition[1])
    return {
      cy: '' + cy,
      cx: '' + cx
    }
  }

  const createDamagePoint = (svg: SVGSVGElement) => {

    if (damagePosition && damageColor) {
      const convertedDamagePos = convertDamagePosition(damagePosition)
      const point = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
      point.setAttribute('fill', damageColor);
      point.setAttribute('cx', convertedDamagePos.cx);
      point.setAttribute('cy', convertedDamagePos.cy);
      point.setAttribute('stroke', 'black');
      point.setAttribute('stroke-width', '1');
      point.setAttribute('r', '4');

      svg.appendChild(point);
    }
  }

  return (
    <ReactSVG
      className={className}
      afterInjection={(_, svg) => {
        if (svg) {
          createDamagePoint(svg);
          clearEmptySpace(svg);
        }
      }}
      evalScripts="always"
      loading={() => <CircularProgress />}
      src={src}
    />
  );
};

export default MastPictogram;
