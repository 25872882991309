import { ReactNode } from 'react';
import { IconButton, makeStyles, Modal, Typography, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

export interface SimpleModalProps {
  open: boolean;
  onClose: (result?: any) => void;
}

export interface BaseModalProps {
  open: boolean;
  title?: string;
  description?: string;
  onClose?: () => void;
  footer?: ReactNode;
  children?: ReactNode;
  width?: string | number;
  modalClassName?: string;
  paperClassName?: string;
  closeButtonClassName?: string;
  lineColor?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'overlay',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start'
    }
  },
  paper: (props: BaseModalProps) => ({
    width: props.width ? props.width : 480,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '10px',
    boxShadow: '0px 1px 1px #00000029',
    padding: '35px 30px',
    outline: 'none',
    position: 'relative'
  }),
  header: (props: BaseModalProps) => ({
    borderBottom: `1px solid ${props.lineColor}`
  }),
  headerTitle: {
    textAlign: 'center',
    fontWeight: 600,
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '26px'
    }
  },
  headerDescription: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: '25px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  },
  footer: (props: BaseModalProps) => ({
    borderTop: `1px solid ${props.lineColor}`
  }),
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  }
}));

const BaseModal = ({
  open,
  onClose,
  title,
  description,
  children,
  footer,
  width,
  modalClassName,
  paperClassName,
  closeButtonClassName,
  lineColor = '#EEEEEE'
}: BaseModalProps) => {
  const classes = useStyles({
    open,
    onClose,
    title,
    description,
    children,
    footer,
    width,
    lineColor
  });
  const renderFooter = () => {
    if (footer) {
      return <div className={classes.footer}>{footer}</div>;
    }
    return <></>;
  };

  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      className={clsx(classes.modal, modalClassName)}
      open={open}
      onClose={onClose}
    >
      <div className={clsx(classes.paper, paperClassName)}>
        <IconButton aria-label="close" className={clsx(classes.closeButton, closeButtonClassName)} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        {(title || description) && (
          <div className={classes.header}>
            <Typography variant="h4" color="textSecondary" className={classes.headerTitle}>
              {title}
            </Typography>
            <Typography color="primary" className={classes.headerDescription}>
              {description}
            </Typography>
          </div>
        )}
        {children}
        {renderFooter()}
      </div>
    </Modal>
  );
};

export default BaseModal;
