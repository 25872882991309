import { PayloadAction } from '@reduxjs/toolkit';
import { PageResult } from 'interfaces/pageResult';
import { getOrderYears } from 'redux/action/orderYear';

import { StateData } from '../../interfaces/stateData';
import createAsyncReducer from '../createAsyncReducer';
import { createAsyncSlice } from '../createAsyncSlice';
import { RootState } from '../rootState';

export const orderYearSlice = createAsyncSlice<StateData<number>>({
  name: 'orderYear',
  initialData: {
    list: [],
    totalCount: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    createAsyncReducer(
      builder,
      getOrderYears,
      (state, { payload }: PayloadAction<PageResult<number>>) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.list = payload.content;
        state.data.totalCount = payload.totalElements;
      }
    );
  }
});

export const orderYearSelector = (state: RootState) => state.orderYearSlice;
export const orderYearTotalCountSelector = (state: RootState) => state.orderYearSlice.data.totalCount;
export const orderYearsDataSelector = (state: RootState) => state.orderYearSlice.data.list;
export const orderYearDataSelector = (state: RootState) => state.orderYearSlice.data.entry;

export const { clearState } = orderYearSlice.actions;
