import DamageEditModal from 'modal/DamageEditModal';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, modalSlice } from 'redux/slice/modalSlice';

import {
  ConfirmModal,
  DamageDetailsModal,
  DamageDownloadPhotoModal,
  ForgotPasswordModal,
  InfoModal
} from '../modal';
import { SimpleModalProps } from '../modal/BaseModal';
import { RootState } from '../redux/rootState';

interface ModalType {
  [key: number]: (props: SimpleModalProps) => JSX.Element;
}

const modalComponents: ModalType = {
  [Modal.INFO]: InfoModal,
  [Modal.CONFIRM]: ConfirmModal,
  [Modal.FORGOT_PASSWORD]: ForgotPasswordModal,
  [Modal.DAMAGE_DETAILS]: DamageDetailsModal,
  [Modal.DAMAGE_DOWNLOAD_PHOTO]: DamageDownloadPhotoModal,
  [Modal.DAMAGE_EDIT]: DamageEditModal
};

const Modals = () => {
  const dispatch = useDispatch();
  const modals = useSelector((rootState: RootState) => rootState.modalSlice.modals);

  const isModalActivated = (modal: number) => modals.indexOf(modal) >= 0;

  return (
    <>
      {Object.values(Modal).map((modalValue) => {
        const ModalComponent = modalComponents[modalValue as number];
        return (
          ModalComponent &&
          isModalActivated(modalValue as number) && (
            <ModalComponent
              open
              key={modalValue}
              onClose={() => dispatch(modalSlice.actions.hideModal({ id: modalValue as number }))}
            />
          )
        );
      })}
    </>
  );
};

export default Modals;
