import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginPayload, ForgotPasswordFormData } from 'interfaces/user';
import { StatusCodes } from 'http-status-codes';
import AuthService from 'services/authService';

export const loginUser = createAsyncThunk(
  'users/login',
  async ({ username, password }: LoginPayload, thunkAPI) => {
    try {
      const { status, data } = await AuthService.login(username, password);
      if (status === StatusCodes.OK) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getUser = createAsyncThunk(
  'users/get',
  async (_, thunkAPI) => {
    try {
      const { status, data } = await AuthService.getUser();
      if (status === StatusCodes.OK) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'users/resetPassword',
  async (payload: ForgotPasswordFormData, thunkAPI) => {
    try {
      const { status, data } = await AuthService.resetPassword(payload);
      if (status === StatusCodes.OK) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
