import { PaletteOptions } from '@material-ui/core/styles/createPalette';

export const palette: PaletteOptions = {
  primary: {
    main: '#000666'
  },
  secondary: {
    main: '#FFFFFF'
  },
  error: {
    main: '#B92E3E'
  },
  success: {
    main: '#23D59B'
  },
  info: {
    main: '#F99600'
  },
  grey: {
    500: '#868686'
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#1C3D8A',
    disabled: 'rgba(0, 0, 0, 0.26)'
  },
  background: {
    default: '#000666',
    paper: 'rgba(73,140,207,0.6)'
  }
};
