import { connect } from 'react-redux';

import AuthGuard from '../components/AuthGuard';
import { RootState } from '../redux/rootState';

const withAuth = (WrappedComponent: () => JSX.Element) => {
  const connector = connect((state: RootState) => ({
    userData: state.userSlice.data,
    WrappedComponent
  }));

  return connector(AuthGuard);
};

export default withAuth;
