import { PayloadAction } from '@reduxjs/toolkit';
import { UserGroup } from 'enum/userGroup';
import { getUser, loginUser, resetPassword } from 'redux/action/user';
import { UpdateTokensPayload, UserStateData, IUser } from '../../interfaces/user';
import createAsyncReducer from '../createAsyncReducer';
import { AsyncState, createAsyncSlice } from '../createAsyncSlice';
import { RootState } from '../rootState';

const reducers = {
  logoutUser: (state: AsyncState<UserStateData>) => ({
    ...state,
    data: { user: undefined }
  }),
  updateTokens: (
    state: AsyncState<UserStateData>,
    { payload }: PayloadAction<UpdateTokensPayload>
  ) => {
    state.data.accessToken = payload.accessToken;
    state.data.refreshToken = payload.refreshToken;
    return state;
  },
};

export const userSlice = createAsyncSlice<UserStateData, typeof reducers>({
  name: 'user',
  initialData: {
    user: undefined
  },
  reducers,
  extraReducers: (builder) => {
    createAsyncReducer(
      builder,
      loginUser,
      (state, { payload }: PayloadAction<UserStateData>) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data = payload;
      }
    );

    createAsyncReducer(
      builder,
      resetPassword,
      (state) => {
        state.isFetching = false;
        state.isSuccess = true;
      }
    );

    createAsyncReducer(
      builder,
      getUser,
      (state, { payload }: PayloadAction<IUser>) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.user = payload;
      }
    );
  }
});

export const userSelector = (state: RootState) => state.userSlice;
export const userDataSelector = (state: RootState) => state.userSlice.data.user;
export const isClientUserSelector = (state: RootState) => state.userSlice.data.user?.groups?.includes(UserGroup.Client);

export const { clearState, logoutUser } = userSlice.actions;
