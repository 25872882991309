import { GET_ALL_LINE_SYSTEMS_URL } from 'constants/apiEndpoints';
import httpClient from 'services/apiClient';

const LineSystemService = {
  getAllLineSystems: async () => {
    try {
      return await httpClient.get(GET_ALL_LINE_SYSTEMS_URL);
    } catch (error: any) {
      return error.response;
    }
  }
};

export default LineSystemService;
