import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import { IExportCreateBody, IExportQuery, IExportUpdateBody } from 'interfaces/export';
import ExportService from 'services/exportService';

export const getAllExports = createAsyncThunk(
  'exports/all',
  async (query: IExportQuery, thunkAPI) => {
    try {
      const { status, data } = await ExportService.getAll(query);
      if (status === StatusCodes.OK) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteExport = createAsyncThunk(
  'exports/delete',
  async (uuid: string, thunkAPI) => {
    try {
      const { status, data } = await ExportService.delete(uuid);
      if (status === StatusCodes.OK) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const changeStatusExport = createAsyncThunk(
  'exports/update',
  async ({ uuid, ...body }: IExportUpdateBody, thunkAPI) => {
    try {
      const { status, data } = await ExportService.put(uuid, { exportStatus: body.exportStatus});
      if (status === StatusCodes.OK) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const createExport = createAsyncThunk(
  'exports/create',
  async (body: IExportCreateBody, thunkAPI) => {
    try {
      const { status, data } = await ExportService.post(body);
      if (status === StatusCodes.CREATED) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
