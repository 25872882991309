import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { damageDefinitionSlice } from './slice/damageDefinitionSlice';
import { damageSlice } from './slice/damageSlice';
import { exportSlice } from './slice/exportSlice';
import { flyHistorySlice } from './slice/flyHistoryService';
import { lineSlice } from './slice/lineSlice';
import { lineSystemSlice } from './slice/lineSystemSlice';
import { mastSlice } from './slice/mastSlice';
import { modalSlice } from './slice/modalSlice';
import { notificationSlice } from './slice/notificationSlice';
import { operatorRegionalOfficeSlice } from './slice/operatorRegionalOfficesSlice';
import { orderSlice } from './slice/orderSlice';
import { orderYearSlice } from './slice/orderYearSlice';
import { tableSlice } from './slice/tableSlice';
import { userSlice, logoutUser } from './slice/userSlice';
import { voltageRangeSlice } from './slice/voltageRangeSlice';

export const appReducer = combineReducers({
  userSlice: userSlice.reducer,
  tableSlice: tableSlice.reducer,
  modalSlice: modalSlice.reducer,
  orderSlice: orderSlice.reducer,
  operatorRegionalOfficeSlice: operatorRegionalOfficeSlice.reducer,
  voltageRangeSlice: voltageRangeSlice.reducer,
  orderYearSlice: orderYearSlice.reducer,
  damageDefinitionSlice: damageDefinitionSlice.reducer,
  damageSlice: damageSlice.reducer,
  flyHistorySlice: flyHistorySlice.reducer,
  lineSlice: lineSlice.reducer,
  mastSlice: mastSlice.reducer,
  lineSystemSlice: lineSystemSlice.reducer,
  exportSlice: exportSlice.reducer,
  notificationSlice: notificationSlice.reducer,
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === logoutUser.type) {
    storage.removeItem('persist:root');
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export const isLoadingSelector = (state: RootState) =>
  state.userSlice.isFetching ||
  state.mastSlice.isFetching ||
  state.orderSlice.isFetching ||
  state.operatorRegionalOfficeSlice.isFetching ||
  state.voltageRangeSlice.isFetching ||
  state.orderYearSlice.isFetching ||
  state.damageDefinitionSlice.isFetching ||
  state.flyHistorySlice.isFetching ||
  state.lineSlice.isFetching ||
  state.lineSystemSlice.isFetching ||
  state.exportSlice.isFetching ||
  state.damageSlice.isFetching;

export type RootState = ReturnType<typeof rootReducer>;
