import {
  ActionReducerMapBuilder,
  createSlice,
  SliceCaseReducers,
  ValidateSliceCaseReducers
} from '@reduxjs/toolkit';
import { NoInfer } from '@reduxjs/toolkit/dist/tsHelpers';

export interface ErrorState {
  errorMessage: string;
  errorCode: number;
}

export interface AsyncState<T> extends ErrorState {
  data: T;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
}

type AsyncCaseReducers<T> = SliceCaseReducers<AsyncState<T>>;

interface AsyncSliceProps<T, R extends AsyncCaseReducers<T> = AsyncCaseReducers<T>> {
  name: string;
  initialData: T;
  reducers: ValidateSliceCaseReducers<AsyncState<T>, R>;
  extraReducers?: (builder: ActionReducerMapBuilder<NoInfer<AsyncState<T>>>) => void;
}

export const createAsyncSlice = <T, R extends AsyncCaseReducers<T> = AsyncCaseReducers<T>>({
  name = '',
  initialData,
  reducers,
  extraReducers
}: AsyncSliceProps<T, R>) => {
  const initialState: AsyncState<T> = {
    data: initialData,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    errorCode: 0
  };

  return createSlice({
    name,
    extraReducers,
    initialState,
    reducers: {
      ...reducers,
      clearState: (state: AsyncState<T>) => {
        state.data = initialData;
        state.isError = false;
        state.isSuccess = false;
        state.isFetching = false;

        return state;
      }
    }
  });
};
