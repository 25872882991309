import { ActionReducerMapBuilder, AsyncThunk, CaseReducer } from '@reduxjs/toolkit';
import { NoInfer } from '@reduxjs/toolkit/dist/tsHelpers';
import { AsyncState } from './createAsyncSlice';

const createAsyncReducer = <T>(
  builder: ActionReducerMapBuilder<NoInfer<AsyncState<T>>>,
  action: AsyncThunk<{}, any, {}>,
  reducerFulfilled: CaseReducer<AsyncState<T>, ReturnType<any>>
) =>
  builder
    .addCase(action.fulfilled, reducerFulfilled)
    .addCase(action.rejected, (state, { payload, error }: any) => {
      state.isFetching = false;
      state.isError = error;
      state.errorMessage = payload;
    })
    .addCase(action.pending, (state) => {
      state.isFetching = true;
    });

export default createAsyncReducer;
