import { GET_ALL_LINES_URL } from 'constants/apiEndpoints';
import httpClient from 'services/apiClient';

const LineService = {
  getAllLines: async (lines?: string[]) => {
    try {
      return await httpClient.get(GET_ALL_LINES_URL);
    } catch (error: any) {
      return error.response;
    }
  }
};

export default LineService;
