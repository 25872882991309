import { PayloadAction } from '@reduxjs/toolkit';
import { IExport } from 'interfaces/export';
import { PageResult } from 'interfaces/pageResult';
import { changeStatusExport, createExport, deleteExport, getAllExports } from 'redux/action/export';

import { StateData } from '../../interfaces/stateData';
import createAsyncReducer from '../createAsyncReducer';
import { createAsyncSlice } from '../createAsyncSlice';
import { RootState } from '../rootState';

export const exportSlice = createAsyncSlice<StateData<IExport>>({
  name: 'export',
  initialData: {
    list: [],
    totalCount: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    createAsyncReducer(
      builder,
      getAllExports,
      (state, { payload }: PayloadAction<PageResult<IExport>>) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.list = payload.content;
        state.data.totalCount = payload.totalElements;
      }
    );

    createAsyncReducer(
      builder,
      deleteExport,
      (state) => {
        state.isFetching = false;
        state.isSuccess = true;
      }
    );

    createAsyncReducer(
      builder,
      changeStatusExport,
      (state, { payload }: PayloadAction<IExport>) => {
        const itemIndex = state.data.list.findIndex((item: IExport) => item.uuid === payload.uuid);
        state.isFetching = false;
        state.isSuccess = true;
        state.data.list[itemIndex] = payload;
      }
    );

    createAsyncReducer(
      builder,
      createExport,
      (state) => {
        state.isFetching = false;
        state.isSuccess = true;
      }
    );
  }
});

export const exportSelector = (state: RootState) => state.exportSlice;
export const exportTotalCountSelector = (state: RootState) => state.exportSlice.data.totalCount;
export const exportsDataSelector = (state: RootState) => state.exportSlice.data.list;
export const exportDataSelector = (state: RootState) => state.exportSlice.data.entry;

export const { clearState } = exportSlice.actions;
