import { PayloadAction } from '@reduxjs/toolkit';
import { ILineSystem } from 'interfaces/lineSystem';
import { PageResult } from 'interfaces/pageResult';
import { getAllLineSystems } from 'redux/action/lineSystem';

import { StateData } from '../../interfaces/stateData';
import createAsyncReducer from '../createAsyncReducer';
import { createAsyncSlice } from '../createAsyncSlice';
import { RootState } from '../rootState';

export const lineSystemSlice = createAsyncSlice<StateData<ILineSystem>>({
  name: 'lineSystem',
  initialData: {
    list: [],
    totalCount: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    createAsyncReducer(
      builder,
      getAllLineSystems,
      (state, { payload }: PayloadAction<PageResult<ILineSystem>>) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.list = payload.content;
        state.data.totalCount = payload.totalElements;
      }
    );
  }
});

export const lineSystemSelector = (state: RootState) => state.lineSystemSlice;
export const lineSystemTotalCountSelector = (state: RootState) => state.lineSystemSlice.data.totalCount;
export const lineSystemsDataSelector = (state: RootState) => state.lineSystemSlice.data.list;
export const lineSystemDataSelector = (state: RootState) => state.lineSystemSlice.data.entry;

export const { clearState } = lineSystemSlice.actions;
