import { Box, Button, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import BaseModal, { SimpleModalProps } from './BaseModal';
import { damageDataSelector } from 'redux/slice/damageSlice';
import CustomScrollbars from 'components/CustomScrollbars';
import Image from 'material-ui-image';
import { theme } from 'theme';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '30px auto 0 auto',
    padding: '0 25px',
    [theme.breakpoints.down('sm')]: {
      padding: '0'
    }
  },
  paperClassName: {
    background: '#112c65'
  },
  closeButtonClassName: {
    color: 'white',
    opacity: 0.6
  },
  bold: {
    fontWeight: 'bold'
  },
  details: {
    textTransform: 'uppercase'
  },
  downloadLink: {
    transition: '.5s ease',
    opacity: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
  },
  image: {
    opacity: 1
  },
  imageContainer: {
    position: 'relative',
    '&:hover $downloadLink': {
      opacity: 1
    },
    '&:hover $image': {
      opacity: 0.4,
      transition: '.5s ease'
    }
  },
  returnButton: {
    float: 'right',
    marginLeft: theme.spacing(2)
  }
}));

export const DamageDownloadPhotoModal = ({ open, onClose }: SimpleModalProps) => {
  const classes = useStyles();
  const currentDamage = useSelector(damageDataSelector);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const renderImages = () =>
    currentDamage?.medias?.map((mediaUuid: string, index: number) => (
      <Grid item xs={3} className={classes.imageContainer} key={mediaUuid}>
        <Box className={classes.image}>
          <Image
            src={`${apiBaseUrl}media/${mediaUuid}`}
            color="none"
            style={{ cursor: 'pointer', marginBottom: theme.spacing(2) }}
          />
        </Box>
        <Box className={classes.downloadLink}>
          <IconButton
            color="secondary"
            component="a"
            download
            href={`${apiBaseUrl}media/${mediaUuid}`}
          >
            <GetAppIcon />
          </IconButton>
        </Box>
      </Grid>
    ));

  const handleClickDownloadAll = () => {
    currentDamage?.medias?.map(
      (mediaUuid: string) => window.open(`${apiBaseUrl}media/${mediaUuid}`, '_blank')
    );
  };

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      width="60vw"
      paperClassName={classes.paperClassName}
      closeButtonClassName={classes.closeButtonClassName}
    >
      <CustomScrollbars height="calc(100vh - 360px)" color="white">
        <Grid container spacing={2} className={classes.root}>
          {currentDamage?.medias?.length ? (
            renderImages()
          ) : (
            <Typography
              color="textPrimary"
              className={clsx(classes.bold, classes.details)}
              variant="body1"
            >
              <FormattedMessage id="damageDetails.noPhotos" />
            </Typography>
          )}
        </Grid>
      </CustomScrollbars>
      <Box>
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          type="button"
          className={classes.returnButton}
          disabled={currentDamage?.medias?.length === 0}
          onClick={handleClickDownloadAll}
        >
          <FormattedMessage id="button.downloadAll" />
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          type="button"
          className={classes.returnButton}
          onClick={onClose}
        >
          <FormattedMessage id="button.return" />
        </Button>
      </Box>
    </BaseModal>
  );
};

export default DamageDownloadPhotoModal;
