import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import { IMastQuery } from 'interfaces/mast';
import MastService from 'services/mastService';

export const getAllMasts = createAsyncThunk(
  'masts/getAll',
  async ({ lines }: IMastQuery, thunkAPI) => {
    try {
      const { status, data } = await MastService.getAllMasts(lines);
      if (status === StatusCodes.OK) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
