import { GET_ALL_MASTS_URL } from 'constants/apiEndpoints';
import httpClient from 'services/apiClient';

const MastService = {
  getAllMasts: async (lines?: string[]) => {
    try {
      return await httpClient.get(GET_ALL_MASTS_URL, { params: { lines } });
    } catch (error: any) {
      return error.response;
    }
  }
};

export default MastService;
