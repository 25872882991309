import { BORDER_RADIUS_BUTTON } from '../constants';
import { palette } from '../palette';

const primaryColor = palette.primary && 'main' in palette.primary ? palette.primary.main : 'none';
const secondaryColor =
  palette.secondary && 'main' in palette.secondary ? palette.secondary.main : 'none';

const MuiOutlinedInput = {
  root: {
    color: palette.text?.secondary,

    '& fieldset': {
      borderRadius: BORDER_RADIUS_BUTTON,
      borderColor: primaryColor
    },
    '& $notchedOutline': {
      borderColor: primaryColor,
      color: primaryColor
    },
    '& $notchedOutline > span': {
      borderColor: primaryColor,
      color: primaryColor
    },
    '&:hover $notchedOutline': {
      borderColor: primaryColor,
      color: primaryColor
    },
    '&$focused $notchedOutline': {
      borderColor: primaryColor,
      color: primaryColor
    }
  },
  colorSecondary: {
    color: palette.text?.primary,
    '& fieldset': {
      borderColor: secondaryColor
    },
    '& $notchedOutline': {
      borderColor: secondaryColor,
      color: secondaryColor
    },
    '&:hover $notchedOutline': {
      borderColor: secondaryColor,
      color: secondaryColor
    },
    '&$focused $notchedOutline': {
      borderColor: secondaryColor,
      color: secondaryColor
    }
  }
};

export default MuiOutlinedInput;
