import { Box, Button, Grid, IconButton, makeStyles, Theme } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import clsx from 'clsx';
import React, { ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    borderRadius: '30px',
    padding: '2px 10px',
    margin: '0 4px',
    minWidth: 'auto'
  },
  currentPage: {
    border: `1px solid ${theme.palette.secondary.main}`
  }
}));
interface ListFooterProps {
  page: number;
  count: number;
  rowsPerPage: number;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  startComponent?: ReactNode;
  endComponent?: ReactNode;
}

export const ListFooter = (props: ListFooterProps) => {
  const classes = useStyles();
  const { count, page, rowsPerPage, onChangePage, startComponent, endComponent } = props;

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement> | null) =>
    onChangePage(event, page - 1);
  const handleForwardButtonClick = (event: React.MouseEvent<HTMLButtonElement> | null) =>
    onChangePage(event, page + 1);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) =>
    onChangePage(event, newPage);

  return (
    <Box mt={5}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          {startComponent}
        </Grid>
        <Grid item xs={12} sm={4}>
          {Array(Math.ceil(count / rowsPerPage)).length > 1 && (
            <Box width="100%" display="flex" justifyContent="center" alignItems="center">
              <Box>
                <IconButton color="secondary" onClick={handleBackButtonClick} disabled={page === 0}>
                  <KeyboardArrowLeft />
                </IconButton>
              </Box>
              <Box>
              {}
                {Array.from(Array(Math.ceil(count / rowsPerPage)).keys()).map(
                  (pageNumber: number) => (
                    <Button
                      key={pageNumber}
                      color="secondary"
                      className={clsx({
                        [classes.page]: true,
                        [classes.currentPage]: page === pageNumber
                      })}
                      onClick={(e) => handleChangePage(e, pageNumber)}
                    >
                      {pageNumber + 1}
                    </Button>
                  )
                )}
              </Box>
              <Box>
                <IconButton
                  color="secondary"
                  onClick={handleForwardButtonClick}
                  disabled={count !== -1 ? page >= Math.ceil(count / rowsPerPage) - 1 : false}
                >
                  <KeyboardArrowRight />
                </IconButton>
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {endComponent}
        </Grid>
      </Grid>
    </Box>
  );
};
