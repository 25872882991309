const MuiAutocomplete = {
  paper: {
    padding: 0,
    background: 'rgba(73,140,207,0.95)'
  },
  inputRoot: {
    '& $popupIndicator': {
      color: 'white'
    },
    '& $clearIndicator': {
      color: 'white'
    }
  }
};

export default MuiAutocomplete;
