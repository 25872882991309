import { makeStyles } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars-2';

const useStyle = makeStyles(() => ({
  vertical: {
    backgroundColor: ({ color }: Partial<CustomScrollbarsProps>) =>
      color ? color : 'rgb(73, 140, 207)',
    borderRadius: '30px'
  },
  hotizontal: {
    display: 'none'
  }
}));

interface CustomScrollbarsProps {
  height: string;
  children: JSX.Element | JSX.Element[];
  color?: string;
}

const CustomScrollbars = ({ children, height, color }: CustomScrollbarsProps) => {
  const classes = useStyle({ color });

  return (
    <Scrollbars
      style={{ height }}
      renderThumbVertical={(props) => <div {...props} className={classes.vertical} />}
      renderThumbHorizontal={(props) => <div {...props} className={classes.hotizontal} />}
    >
      {children}
    </Scrollbars>
  );
};

export default CustomScrollbars;
