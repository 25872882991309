import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import OrderService from 'services/orderService';

export const getOrderYears = createAsyncThunk(
  'orders/getOrderYears',
  async (_, thunkAPI) => {
    try {
      const { status, data } = await OrderService.getOrderYears();
      if (status === StatusCodes.OK) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
