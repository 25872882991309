import { PayloadAction } from '@reduxjs/toolkit';
import { PageResult } from 'interfaces/pageResult';
import { getAllVoltageRanges } from 'redux/action/voltageRange';

import { StateData } from '../../interfaces/stateData';
import createAsyncReducer from '../createAsyncReducer';
import { createAsyncSlice } from '../createAsyncSlice';
import { RootState } from '../rootState';

export const voltageRangeSlice = createAsyncSlice<StateData<string>>({
  name: 'voltageRange',
  initialData: {
    list: [],
    totalCount: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    createAsyncReducer(
      builder,
      getAllVoltageRanges,
      (state, { payload }: PayloadAction<PageResult<string>>) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.list = payload.content;
        state.data.totalCount = payload.totalElements;
      }
    );
  }
});

export const voltageRangeSelector = (state: RootState) => state.voltageRangeSlice;
export const voltageRangeTotalCountSelector = (state: RootState) =>
  state.voltageRangeSlice.data.totalCount;
export const voltageRangesDataSelector = (state: RootState) => state.voltageRangeSlice.data.list;
export const voltageRangeDataSelector = (state: RootState) => state.voltageRangeSlice.data.entry;

export const { clearState } = voltageRangeSlice.actions;
