import { Box, Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Modal, modalParamsSelector } from 'redux/slice/modalSlice';

import BaseModal, { SimpleModalProps } from './BaseModal';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    margin: '30px auto 0 auto',
    flexDirection: 'column',
    padding: '0 50px',
    [theme.breakpoints.down('sm')]: {
      padding: '0'
    }
  },
  confirmText: {
    fontWeight: 500,
    fontSize: '12px',
    textAlign: 'center'
  },
  button: {
    marginTop: '30px',
    marginBottom: '15px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
}));

export const InfoModal = ({ open, onClose }: SimpleModalProps) => {
  const classes = useStyles();
  const params = useSelector(modalParamsSelector)[Modal.INFO];

  return (
    <BaseModal open={open} onClose={onClose}>
      <Box className={classes.root}>
        <Typography className={classes.confirmText}>{params.message}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <Button
              color="primary"
              size="medium"
              type="submit"
              fullWidth
              variant="contained"
              onClick={onClose}
              className={classes.button}
            >
              <FormattedMessage id="button.ok" />
            </Button>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Box>
    </BaseModal>
  );
};

export default InfoModal;
