import MuiPaper from './MuiPaper';
import MuiInputBase from './MuiInputBase';
import MuiButton from './MuiButton';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiInputLabel from './MuiInputLabel';
import MuiSelect from './MuiSelect';
import MuiTablePagination from './MuiTablePagination';
import MuiTableSortLabel from './MuiTableSortLabel';
import MuiTableContainer from './MuiTableContainer';
import MuiAutocomplete from './MuiAutocomplete';
import MuiFormLabel from './MuiFormLabel';
import MuiPickersBasePicker from './MuiPickersBasePicker';

const overrides = {
  MuiPaper,
  MuiInputBase,
  MuiButton,
  MuiOutlinedInput,
  MuiInputLabel,
  MuiSelect,
  MuiTablePagination,
  MuiTableSortLabel,
  MuiTableContainer,
  MuiAutocomplete,
  MuiPickersBasePicker,
  MuiFormLabel
};

export default overrides;
