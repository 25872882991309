import { useEffect, useState } from 'react';
import { Box, Button, Container, Link, TextField, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { userDataSelector } from 'redux/slice/userSlice';
import { PATHS } from 'constants/paths';
import { loginUser } from 'redux/action/user';
import { Modal, showModal } from 'redux/slice/modalSlice';

const useStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  loginTitle: {
    fontWeight: 'bold'
  }
}));

const Login = () => {
  const classes = useStyle();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector(userDataSelector);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (username.length < 1) {
      enqueueSnackbar(formatMessage({ id: 'login.enterUsername' }), {
        variant: 'warning'
      });
      return;
    }

    if (password.length < 1) {
      enqueueSnackbar(formatMessage({ id: 'login.enterPassword' }), {
        variant: 'warning'
      });
      return;
    }

    const result: any = await dispatch(loginUser({ username, password }));
    if (result?.payload?.user) {
      history.push(PATHS.start);
    }
    if (result?.error) {
      enqueueSnackbar(formatMessage({ id: 'login.invalidCredential' }), {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    if (user) {
      history.push(PATHS.start);
    }
  }, [history, user]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickForgotPassword = () => {
    dispatch(
      showModal({
        id: Modal.FORGOT_PASSWORD
      })
    );
  };

  return (
    <Container maxWidth="sm" className={classes.root}>
      <form onSubmit={handleSubmit}>
        <Grid container direction="row" alignItems="flex-end" justifyContent="center" spacing={5}>
          <Grid item xs={12}>
            <Typography color="textPrimary" className={classes.loginTitle}>
              <FormattedMessage id="login.title" />
            </Typography>
            <Typography color="textPrimary" className="loginDesc">
              <FormattedMessage id="login.description" />
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <TextField
              variant="outlined"
              fullWidth
              color="secondary"
              onChange={(event) => setUsername(event.target.value)}
              name="username"
              placeholder={formatMessage({ id: 'login.username' })}
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={11}>
            <TextField
              variant="outlined"
              fullWidth
              color="secondary"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              placeholder={formatMessage({ id: 'login.password' })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ color: 'white' }}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Box mt={2}>
              <Button
                className="submit-btn"
                variant="contained"
                color="secondary"
                fullWidth
                type="submit"
              >
                <FormattedMessage id="button.ok" />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Link component="button" color="textPrimary" onClick={handleClickForgotPassword}>
              <FormattedMessage id="login.forgotPassword" />
            </Link>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default Login;
