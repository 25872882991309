import { PayloadAction } from '@reduxjs/toolkit';
import { IFlyHistory } from 'interfaces/flyHistory';
import { PageResult } from 'interfaces/pageResult';
import { getAllFlyHistories } from 'redux/action/flyHistory';
import { StateData } from '../../interfaces/stateData';
import createAsyncReducer from '../createAsyncReducer';
import { createAsyncSlice } from '../createAsyncSlice';
import { RootState } from '../rootState';

export const flyHistorySlice = createAsyncSlice<StateData<IFlyHistory>>({
  name: 'flyHistory',
  initialData: {
    list: [],
    totalCount: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    createAsyncReducer(
      builder,
      getAllFlyHistories,
      (state, { payload }: PayloadAction<PageResult<IFlyHistory>>) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.list = payload.content;
      }
    );
  }
});

export const flyHistorySelector = (state: RootState) => state.flyHistorySlice;
export const flyHistorysDataSelector = (state: RootState) => state.flyHistorySlice.data.list;

export const { clearState } = flyHistorySlice.actions;
