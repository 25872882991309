export const PATHS = {
  main: '/',
  login: '/login',
  start: '/start',
  mainSelection: '/mainSelection',
  advancedSearch: '/advancedSearch',
  editing: '/editing',
  export: '/export',
  imprint: '/imprint',
  dataPrivacy: '/data-privacy'
};
