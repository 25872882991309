import { IDamageQuery, IUpdateDamageBody } from 'interfaces/damage';
import httpClient from 'services/apiClient';

export const GET_ALL_DAMAGE_URL = 'damage/all';
export const GET_DAMAGE_URL = 'damage/';
export const UPDATE_DAMAGE_URL = 'damage/';

const DamageService = {
  getAllDamages: async (query: IDamageQuery) => {
    try {
      return await httpClient.get(GET_ALL_DAMAGE_URL, { params: query });
    } catch (error: any) {
      return error.response;
    }
  },
  getDamage: async (uuid: string) => {
    try {
      return await httpClient.get(GET_DAMAGE_URL + uuid);
    } catch (error: any) {
      return error.response;
    }
  },
  requestDamage: async (uuid: string) => {
    try {
      return await httpClient.post(GET_DAMAGE_URL + uuid + '/request');
    } catch (error: any) {
      return error.response;
    }
  },
  updateDamage: async (uuid: string, body: Partial<IUpdateDamageBody>) => {
    try {
      return await httpClient.put(UPDATE_DAMAGE_URL + uuid, body);
    } catch (error: any) {
      return error.response;
    }
  }
};

export default DamageService;
