import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import DamageDefinitionService from 'services/damageDefinitionService';

export const getAllDamageDefinitions = createAsyncThunk(
  'damageDefinitions/getAll',
  async (_, thunkAPI) => {
    try {
      const { status, data } = await DamageDefinitionService.getAllDamageDefinitions();
      if (status === StatusCodes.OK) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
