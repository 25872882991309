import { Box, Button, Container, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import clsx from 'clsx';
import CustomScrollbars from 'components/CustomScrollbars';
import ListView from 'components/ListView';
import { HeadCell } from 'components/ListView/ListHeader';
import { PATHS } from 'constants/paths';
import dayjs from 'dayjs';
import { IDamageDefinition } from 'interfaces/damageDefinition';
import { IFlyHistory } from 'interfaces/flyHistory';
import { IOrder } from 'interfaces/order';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Marker, Popup } from 'react-leaflet';
import { useQuery } from 'utils/useQuery';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getAllDamages } from 'redux/action/damage';
import { getAllDamageDefinitions } from 'redux/action/damageDefinition';
import { createExport } from 'redux/action/export';
import { getAllFlyHistories } from 'redux/action/flyHistory';
import { getAllMasts } from 'redux/action/mast';
import { getOrdersNotOpened } from 'redux/action/order';
import { damageDefinitionsDataSelector } from 'redux/slice/damageDefinitionSlice';
import { damagesDataSelector } from 'redux/slice/damageSlice';
import { flyHistorysDataSelector } from 'redux/slice/flyHistoryService';
import { clearState, mastsDataSelector } from 'redux/slice/mastSlice';
import { Modal, modalSlice } from 'redux/slice/modalSlice';
import { orderSelector } from 'redux/slice/orderSlice';
import { tableSelector } from 'redux/slice/tableSlice';
import withAuth from 'services/withAuth';
import { mapDamages, mapMasts } from 'utils/mapMarker';

import Map from '../components/Map';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  state: {
    borderRadius: '30px',
    textAlign: 'center',
    color: theme.palette.text.primary,
    display: 'inline-block',
    padding: '3px 10px',
    width: '100%',
    minHeight: '18px',
    textTransform: 'uppercase'
  },
  notOpened: {
    backgroundColor: theme.palette.error.main
  },
  opened: {
    backgroundColor: theme.palette.info.main
  },
  lineOptions: {
    textTransform: 'uppercase'
  },
  multiple: {
    opacity: '0.5'
  },
  damageDefinitionBtn: {
    marginBottom: theme.spacing(2)
  },
  damageDefinitionTypeBtn: {
    padding: '0',
    minWidth: 'auto',
    backgroundColor: 'yellow',
    width: '30px',
    height: '30px',
    opacity: '0.5',
    '&.active': {
      opacity: '1'
    }
  },
  damageDefinitionTypeRedBtn: {
    backgroundColor: 'red'
  },
  damageDefinitionTypeGreenBtn: {
    backgroundColor: 'green'
  },
  datePickerPaper: {
    padding: 0
  },
  map: {
    height: '20vh'
  }
}));

const AdvancedSearch = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();

  const [year, setYear] = useState(dayjs().get('year'));
  const [month, setMonth] = useState(dayjs().get('month') + 1);
  const [showMasts, setShowMasts] = useState(true);
  const [showDamages, setShowDamages] = useState(true);
  const [selectedRow, setSelectedRow] = useState<IOrder[]>([]);
  const [selectedInspectionDate, setSelectedInspectionDate] = useState<MaterialUiPickersDate>(null);
  const [selectedDamageDefinitions, setSelectedDamageDefinitions] = useState<IDamageDefinition[]>(
    []
  );

  const [selectedDamageTypes, setSelectedDamageTypes] = useState<string[]>([]);
  const [selectedDamagePriorities, setSelectedDamagePriorities] = useState<number[]>([]);

  const masts = useSelector(mastsDataSelector);
  const flyHistories = useSelector(flyHistorysDataSelector);
  const damageDefinitions = useSelector(damageDefinitionsDataSelector);
  const damages = useSelector(damagesDataSelector);

  const {
    data: { list, totalCount },
    isFetching
  } = useSelector(orderSelector);

  const { order, orderBy, page } = useSelector(tableSelector);

  const headCells: HeadCell[] = [
    {
      key: 'lineName',
      label: formatMessage({ id: 'advancedSearch.line' })
    },
    { key: 'mastarea', label: formatMessage({ id: 'advancedSearch.mastarea' }) },
    {
      key: 'span',
      label: formatMessage({ id: 'advancedSearch.span' })
    },
    {
      key: 'inspectionDate',
      label: formatMessage({ id: 'advancedSearch.inspectionDate' })
    }
  ];

  const handleClickEdit = () => {
    const order = selectedRow.filter(
      (selectedOrder: IOrder) => selectedOrder.countMastarea || selectedOrder.countSpan
    );

    history.push({
      pathname: PATHS.editing,
      search: '?lineId=' + order[0]?.line.uuid
    });
  };

  const handleClickExport = () => {
    dispatch(
      createExport({
        damageIds: selectedRow
          .filter((selectedOrder: IOrder) => selectedOrder.countMastarea || selectedOrder.countSpan)
          .map((selectedOrder: IOrder) => selectedOrder.uuid)
      })
    );
    enqueueSnackbar(formatMessage({ id: 'createExportSuccess' }), {
      variant: 'success'
    });
  };

  const handleClickDamageDetails = () => {
    dispatch(
      modalSlice.actions.showModal({
        id: Modal.DAMAGE_DETAILS,
        params: { selectedRow }
      })
    );
  };

  const handleOnSelectedRows = async (selected: string[]) => {
    const orders = list.filter((o: IOrder) => selected.includes(o.uuid));
    if (selected.length) {
      await Promise.all([
        dispatch(getAllMasts({ lines: orders.map((order: IOrder) => order.line.uuid) })),
        dispatch(
          getAllDamages({
            inspectionDate: selectedInspectionDate?.toISOString(),
            damageDefinitions: selectedDamageDefinitions.map(
              (damageDefinition: IDamageDefinition) => damageDefinition.uuid
            ),
            damageTypes: selectedDamageTypes,
            damagePriorities: selectedDamagePriorities,
            lineIds: orders.map((order: IOrder) => order.line.uuid)
          })
        )
      ]);
    } else {
      dispatch(clearState());
    }
    setSelectedRow(orders);
  };

  const mapOrder = (order: IOrder) => ({
    key: order.uuid,
    cells: [
      order.line?.name,
      order.countMastarea,
      order.countSpan,
      order.histories && order.histories.length > 0 ?dayjs(parseInt(order.histories[0].timestamp) * 1000).format('DD.MM.YYYY') : formatMessage({ id: 'advancedSearch.notAvailable' })
    ]
  });

  const handleOnClickDamageDefinition = (damageDefinition: IDamageDefinition) => {
    if (selectedDamageDefinitions.includes(damageDefinition)) {
      setSelectedDamageDefinitions(
        selectedDamageDefinitions.filter((d: IDamageDefinition) => d.uuid !== damageDefinition.uuid)
      );
    } else {
      setSelectedDamageDefinitions([...selectedDamageDefinitions, damageDefinition]);
    }
  };

  const handleOnClickDamageDefinitionType = (damageType: string) => {
    if (selectedDamageTypes.includes(damageType)) {
      setSelectedDamageTypes(selectedDamageTypes.filter((d: string) => d !== damageType));
    } else {
      setSelectedDamageTypes([...selectedDamageTypes, damageType]);
    }
  };

  const handleOnClickDamageDefinitionPriority = (damagePriority: number) => {
    if (selectedDamagePriorities.includes(damagePriority)) {
      setSelectedDamagePriorities(
        selectedDamagePriorities.filter((d: number) => d !== damagePriority)
      );
    } else {
      setSelectedDamagePriorities([...selectedDamagePriorities, damagePriority]);
    }
  };

  useEffect(() => {
    dispatch(getAllDamageDefinitions());
  }, [query, dispatch]);

  useEffect(() => {
    dispatch(getAllFlyHistories({ year, month }));
  }, [dispatch, year, month, query]);

  const damageDefinitionTypes = useMemo(
    () => _.sortBy(_.uniq(damageDefinitions.map((d: IDamageDefinition) => d.type))),
    [damageDefinitions]
  );

  const damageDefinitionPriorities = useMemo(
    () =>
      _.sortBy(
        _.uniq(_.union(...damageDefinitions.map((d: IDamageDefinition) => d.damageClasses)))
      ),
    [damageDefinitions]
  );

  useEffect(() => {
    const lineName = query.get('lineName');
    dispatch(
      getOrdersNotOpened({
        pageSize: 10,
        inspectionDate: selectedInspectionDate?.toISOString(),
        damageTypes: selectedDamageTypes,
        damagePriorities: selectedDamagePriorities,
        damageDefinitions: selectedDamageDefinitions.map((d: IDamageDefinition) => d.uuid),
        sort: orderBy,
        sortDirection: order,
        page,
        query: lineName || ''
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    selectedInspectionDate,
    selectedDamageTypes,
    selectedDamagePriorities,
    selectedDamageDefinitions,
    orderBy,
    order,
    page,
    query
  ]);

  const renderDamageDefinitions = () => {
    return damageDefinitions.map((damageDefinition: IDamageDefinition) => (
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        className={classes.damageDefinitionBtn}
        onClick={() => handleOnClickDamageDefinition(damageDefinition)}
        key={damageDefinition.uuid}
        startIcon={
          selectedDamageDefinitions.map((item) => item.uuid).includes(damageDefinition.uuid) && (
            <CheckIcon />
          )
        }
      >
        {damageDefinition.definition}
      </Button>
    ));
  };

  const renderDamageDefinitionTypes = () => {
    return damageDefinitionTypes.map((damageDefinitionType: string) => (
      <Grid item xs={6} key={damageDefinitionType}>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          className={classes.damageDefinitionBtn}
          onClick={() => handleOnClickDamageDefinitionType(damageDefinitionType)}
          startIcon={selectedDamageTypes.includes(damageDefinitionType) && <CheckIcon />}
        >
          {damageDefinitionType}
        </Button>
      </Grid>
    ));
  };

  const renderDamageDefinitionPriorities = () => {
    return damageDefinitionPriorities.map((damagePriority: number, index: number) => (
      <Box display="flex" key={damagePriority}>
        <Button
          variant="contained"
          color="secondary"
          className={clsx({
            [classes.damageDefinitionTypeBtn]: true,
            [classes.damageDefinitionTypeRedBtn]: index === 0,
            [classes.damageDefinitionTypeGreenBtn]: index + 1 === damageDefinitionPriorities.length,
            active: selectedDamagePriorities.includes(damagePriority)
          })}
          onClick={() => handleOnClickDamageDefinitionPriority(damagePriority)}
        >
          {damagePriority}
        </Button>
      </Box>
    ));
  };

  const shouldDisableDate = (day: MaterialUiPickersDate) => {
    // if (day) {
    //   return (
    //     flyHistories.filter(
    //       (flyHistory: IFlyHistory) =>
    //         dayjs(flyHistory.timestamp).format('YYYY-MM-DD') === day?.format('YYYY-MM-DD')
    //     ).length === 0
    //   );
    // }
    return false;
  };

  return (
    <Container disableGutters maxWidth={false} className={classes.root}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        height="100%"
        width="100%"
      >
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Typography color="textPrimary" variant="subtitle1">
              <FormattedMessage id="advancedSearch.description" />
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid container>
              <Grid item xs={6}>
                <Typography color="textPrimary" variant="subtitle1" className={classes.lineOptions}>
                  <FormattedMessage id="advancedSearch.lineOptions" />
                </Typography>
                <Typography color="textPrimary" variant="subtitle1" className={classes.multiple}>
                  *<FormattedMessage id="advancedSearch.multipleChoice" />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  {renderDamageDefinitionTypes()}
                </Grid>
                <Box mt={2} display="flex" flexDirection="row" justifyContent="space-between">
                  {renderDamageDefinitionPriorities()}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <DatePicker
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              color="secondary"
              fullWidth
              autoOk
              label={formatMessage({ id: 'advancedSearch.inspectionDate' })}
              value={selectedInspectionDate}
              format="DD.MM.YYYY"
              PopoverProps={{
                PaperProps: {
                  className: classes.datePickerPaper
                }
              }}
              onMonthChange={(date: MaterialUiPickersDate) => {
                if (date) {
                  setMonth(date.get('month') + 1);
                  setYear(date.get('year'));
                }
              }}
              onYearChange={(date: MaterialUiPickersDate) => date && setYear(date.get('year'))}
              shouldDisableDate={shouldDisableDate}
              onChange={setSelectedInspectionDate}
            />
          </Grid>
          <Grid item xs={7}>
            <CustomScrollbars height="calc(100vh - 360px)">
              <ListView
                totalCount={totalCount}
                headCells={headCells}
                rows={list.map(mapOrder)}
                loading={isFetching}
                multiple
                selected={selectedRow.map((order: IOrder) => order.uuid)}
                onSelectRow={handleOnSelectedRows}
              />
            </CustomScrollbars>
          </Grid>
          <Grid item xs={2}>
            <CustomScrollbars height="calc(100vh - 360px)">
              {renderDamageDefinitions()}
            </CustomScrollbars>
          </Grid>
          <Grid item xs={3}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  className={classes.damageDefinitionBtn}
                  onClick={() => setShowMasts(!showMasts)}
                  startIcon={showMasts && <CheckIcon />}
                >
                  <FormattedMessage id="advancedSearch.masts" />
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  className={classes.damageDefinitionBtn}
                  onClick={() => setShowDamages(!showDamages)}
                  startIcon={showDamages && <CheckIcon />}
                >
                  <FormattedMessage id="advancedSearch.lines" />
                </Button>
              </Grid>
              {masts.length > 0 && (
                <Grid item xs={12} className={classes.map}>
                  <Map centerPosition={masts[0].position || [0, 0]}>
                    <>
                      {showMasts &&
                        masts.map(mapMasts).map(({ name, position, icon }) => (
                          <Marker key={name} position={position} icon={icon}>
                            <Popup>{name}</Popup>
                          </Marker>
                        ))}

                      {showDamages &&
                        damages.map(mapDamages).map(({ uuid, name, icon, position }) => (
                          <Marker key={name} position={position} icon={icon}>
                            <Popup>{name}</Popup>
                          </Marker>
                        ))}
                    </>
                  </Map>
                </Grid>
              )}
              {masts.length > 0 && (
                <Grid item xs={12}>
                  <Button
                    className="submit-btn"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={handleClickDamageDetails}
                  >
                    <FormattedMessage id="button.mapView" />
                  </Button>
                </Grid>
              )}
              <Grid item xs={6}>
                <Button
                  className="submit-btn"
                  variant="contained"
                  color="secondary"
                  disabled={
                    selectedRow.length !== 1 ||
                    !selectedRow?.some(
                      (selectedOrder: IOrder) =>
                        selectedOrder.countSpan || selectedOrder.countMastarea
                    )
                  }
                  fullWidth
                  onClick={handleClickEdit}
                >
                  <FormattedMessage id="button.edit" />
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  className="submit-btn"
                  variant="contained"
                  color="secondary"
                  disabled={
                    selectedRow.length < 2 ||
                    !selectedRow?.some(
                      (selectedOrder: IOrder) =>
                        selectedOrder.countSpan || selectedOrder.countMastarea
                    )
                  }
                  fullWidth
                  onClick={handleClickExport}
                >
                  <FormattedMessage id="button.export" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default withAuth(AdvancedSearch);
