import L from 'leaflet';
import mastIcon from '../assets/images/mast-icon.png';
import mastIconRed from '../assets/images/mast-icon-red.png';
import mastIconYellow from '../assets/images/mast-icon-yellow.png';
import mastIconGreen from '../assets/images/mast-icon-green.png';

import damageIcon from '../assets/images/damage-icon.png';
import damageIconRed from '../assets/images/damage-icon-red.png';
import damageIconYellow from '../assets/images/damage-icon-yellow.png';
import damageIconGreen from '../assets/images/damage-icon-green.png';


import { MarkerColor } from 'enum/markerColor';
import { MarkerType } from 'enum/markerType';

const Icon = (iconUrl: string) =>
  new L.Icon({
    iconUrl: iconUrl,
    iconRetinaUrl: iconUrl,
    iconSize: new L.Point(20, 20)
  });

const MapIcon = (type: MarkerType, color?: MarkerColor) => {
  if (type === MarkerType.Circle) {
    if (color === MarkerColor.Green) {
      return Icon(mastIconGreen);
    }
    if (color === MarkerColor.Yellow) {
      return Icon(mastIconYellow);
    }
    if (color === MarkerColor.Red) {
      return Icon(mastIconRed);
    }
    return Icon(mastIcon);
  }

  if (type === MarkerType.Triangle) {
    if (color === MarkerColor.Green) {
      return Icon(damageIconGreen);
    }
    if (color === MarkerColor.Yellow) {
      return Icon(damageIconYellow);
    }
    if (color === MarkerColor.Red) {
      return Icon(damageIconRed);
    }
    return Icon(damageIcon);
  }
}

export default MapIcon;
