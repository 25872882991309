import { palette } from '../palette';

const secondaryColor =
  palette.secondary && 'main' in palette.secondary ? palette.secondary.main : 'none';

const MuiFormLabel = {
  root: {},
  colorSecondary: {
    color: secondaryColor
  }
};

export default MuiFormLabel;
