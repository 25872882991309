import React, { Suspense } from 'react';
import { Container, Grid, LinearProgress, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import start_bg from '../../assets/images/bg/start_bg.jpg';
import Footer from 'components/Footer';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    }
  },
  content: {
    flexGrow: 1,
    maxWidth: '100%',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56
    },
    display: 'flex',
    flexDirection: 'column',
    alginItems: 'center',
    justifyContent: 'center'
  },
  backgrpundImage: {
    backgroundImage: `url(${start_bg})`,
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column'
  }
}));

const StartLayout = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();

  return (
    <Container maxWidth={false} className={classes.backgrpundImage}>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Container className={classes.container}>
            <div className={classes.content}>
              <Suspense fallback={<LinearProgress />}>{children}</Suspense>
            </div>
          </Container>
          <Footer />
        </Grid>
      </Grid>
    </Container>
  );
};

export default StartLayout;
