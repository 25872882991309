
import { GET_ALL_OPERATOR_REGIONAL_OFFICE_URL } from 'constants/apiEndpoints';
import httpClient from 'services/apiClient';

const OperatorRegionalOfficeService = {
  getAllOperatorRegionalOffice: async () => {
    try {
      return await httpClient.get(GET_ALL_OPERATOR_REGIONAL_OFFICE_URL);
    } catch (error: any) {
      return error.response;
    }
  },
};

export default OperatorRegionalOfficeService;
