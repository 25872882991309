import { GET_ALL_EXPORT_URL } from 'constants/apiEndpoints';
import { IExportCreateBody, IExportQuery, IExportUpdateBody } from 'interfaces/export';
import httpClient from 'services/apiClient';

export const EXPORT_URL = 'export/';

const ExportService = {
  getAll: async (query: IExportQuery) => {
    try {
      return await httpClient.get(GET_ALL_EXPORT_URL, { params: query });
    } catch (error: any) {
      return error.response;
    }
  },
  delete: async (uuid: string) => {
    try {
      return await httpClient.delete(EXPORT_URL + uuid);
    } catch (error: any) {
      return error.response;
    }
  },
  put: async (uuid: string, body: Omit<IExportUpdateBody, 'uuid'>) => {
    try {
      return await httpClient.put(EXPORT_URL + uuid, body);
    } catch (error: any) {
      return error.response;
    }
  },
  post: async (body: IExportCreateBody) => {
    try {
      return await httpClient.post(EXPORT_URL, body);
    } catch (error: any) {
      return error.response;
    }
  },
  get: async (uuid: string) => {
    try {
      return await httpClient.get(EXPORT_URL + uuid, { responseType: 'blob' });
    } catch (error: any) {
      return error.response;
    }
  }
};

export default ExportService;
